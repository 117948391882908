import { memo, useEffect, useState } from 'react'

import { useFlowBuilderSelector } from '../../reducer/hooks'
import { BotActionNodeProps } from '../../types'
import { Icon, TextBodySmall, TextSmallBold } from '../base'
import { Handles, NodeHeader } from './node-components'
import { MessageNode, StyledList } from './node-styles'

const BotActionNode = ({ data, selected }: BotActionNodeProps) => {
  const statePayloads = useFlowBuilderSelector(
    ctx => ctx.state.nonMessageContents.payloads
  )
  const [payload, setPayload] = useState<string | undefined>(undefined)

  useEffect(() => setPayload(getPayload(data.payloadId)), [data.payloadId])

  const getPayload = (payloadId?: string): string | undefined => {
    return statePayloads.find(statePayload => statePayload.id === payloadId)
      ?.name
  }

  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader data={data} title='Bot action' icon={Icon.CODE} />
      {data.payloadId && (
        <StyledList $hasMarginTop>
          <div>
            <TextSmallBold>Payload</TextSmallBold>
            <TextBodySmall $wordBreak='break-word'>{payload}</TextBodySmall>
          </div>
        </StyledList>
      )}
    </MessageNode>
  )
}

export default memo(BotActionNode)
