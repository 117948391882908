import { Locale } from '../../../../domain/models/locales/locale'
import { State } from '../../../types'

export abstract class LocaleAction {
  static addLocales = (state: State, locales: Locale[]): void => {
    state.locales = [...state.locales, ...locales].sort(this.compareLocaleCodes)
  }

  static removeLocales = (state: State, localesToRemove: Locale[]): void => {
    state.locales = state.locales.filter(
      locale =>
        this.isCurrentLocale(state, locale) ||
        !this.hasLocale(localesToRemove, locale)
    )
  }

  static setCurrentLocale = (state: State, locale: Locale): void => {
    state.currentLocale = locale
  }

  private static isCurrentLocale = (state: State, locale: Locale): boolean => {
    return state.currentLocale.id === locale.id
  }

  private static hasLocale = (locales: Locale[], locale: Locale): boolean => {
    return locales.some(l => l.id === locale.id)
  }

  private static compareLocaleCodes = (a: Locale, b: Locale): number => {
    return a.code.localeCompare(b.code)
  }
}
