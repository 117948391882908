import styled from 'styled-components'

import { COLORS } from '../base'
import { bold, textSmall } from '../base/typography'

export const NotificationContainer = styled.div`
  ${textSmall};
  ${bold};
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 16px;
  left: 0px;
  right: 0px;
  width: fit-content;
  margin: 0px auto;
  padding: 8px 16px;
  gap: 24px;
  border-radius: 4px;
  background-color: ${COLORS.N900};
  color: ${COLORS.WHITE};
  z-index: 10;
`
