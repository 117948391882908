import { memo, useState } from 'react'
import { EdgeProps, getBezierPath } from 'reactflow'
import { intersect, Point2D, shape } from 'svg-intersections'

import { TrackEventName, useAnalytics } from '../../analytics'
import {
  EDGE_BUTTON_SIZE,
  EDGE_CURVATURE,
  FALLBACK_NODE_ID,
  MIN_EDGE_BUTTONS_LENGTH,
  MIN_EDGE_NAV_BUTTON_LENGTH,
} from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ViewportAnimator } from '../../utils/viewport-animator'
import { COLORS } from '../base'
import {
  EdgeBackground,
  EdgePath,
  RemoveButton,
  ToSource,
  ToTarget,
} from './edge-styles'

const BezierEdge = (edgeProps: EdgeProps): JSX.Element => {
  const { id, selected, source, target, sourceX, sourceY, targetX, targetY } =
    edgeProps
  const analytics = useAnalytics()
  const currentNodeId = useFlowBuilderSelector(ctx => ctx.state.currentNode?.id)
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const removeEdgesById = useFlowBuilderSelector(ctx => ctx.removeEdgesById)
  const selectNode = useFlowBuilderSelector(ctx => ctx.selectNode)
  const [hover, setHover] = useState(false)

  const selectAndCenterNode = (evt: React.MouseEvent, id: string): void => {
    evt.stopPropagation()
    ViewportAnimator.centerNode(state, id, selectNode)
  }

  const getEdgePath = (): string => {
    let curvature = EDGE_CURVATURE
    const distance = getDistance()
    if (sourceX > targetX) curvature = 20 / Math.sqrt(distance * 2)
    const hx1 = sourceX + Math.abs(targetX - sourceX) * curvature
    const hx2 = targetX - Math.abs(targetX - sourceX) * curvature
    return `M${sourceX},${sourceY} C${hx1},${sourceY} ${hx2},${targetY}, ${targetX},${targetY}`
  }

  const hasNavigationButton = (id: string) => {
    if (state.isReadOnly) return false
    return (
      (selected && getDistance() > MIN_EDGE_BUTTONS_LENGTH) ||
      (currentNodeId === id && getDistance() > MIN_EDGE_NAV_BUTTON_LENGTH)
    )
  }

  const getNavigationButtonProps = (center: number[]) => {
    const point = getNavigationButtonPosition(center)
    if (!point) return getButtonProps(center)
    return getButtonProps([point.x, point.y])
  }

  const getButtonProps = (point: number[]) => {
    return {
      height: EDGE_BUTTON_SIZE,
      width: EDGE_BUTTON_SIZE,
      x: point[0] - EDGE_BUTTON_SIZE / 2,
      y: point[1] - EDGE_BUTTON_SIZE / 2,
    }
  }

  const getCenterProps = () => {
    const [, labelX, labelY] = getBezierPath(edgeProps)
    return getButtonProps([labelX, labelY])
  }

  const getDistance = () => {
    return Math.sqrt(
      Math.pow(sourceX - targetX, 2) + Math.pow(sourceY - targetY, 2)
    )
  }

  const getNavigationButtonPosition = (
    center: number[]
  ): Point2D | undefined => {
    const intersections = intersect(
      shape('circle', { cx: center[0], cy: center[1], r: 40 }),
      shape('path', { d: getEdgePath() })
    )
    if (!intersections.points) return undefined
    return getNearestYPoint(center[1], intersections.points)
  }

  const getNearestYPoint = (py: number, points: Point2D[]) => {
    let nearestYPoint = points[0]
    if (points.length === 1) return nearestYPoint
    let minYDistance = 999
    points.forEach(p => {
      const distance = Math.abs(p.y - py)
      if (distance < minYDistance) {
        nearestYPoint = p
        minYDistance = distance
      }
    })
    return nearestYPoint
  }

  const removeEdge = () => {
    if (edgeProps.sourceHandleId) {
      analytics.trackEvent(TrackEventName.CLICK_DELETE_EDGE)
      removeEdgesById([edgeProps.id])
    }
  }

  return (
    <>
      <EdgeBackground
        d={getEdgePath()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      <EdgePath
        $isSelected={selected}
        $hasHover={hover}
        id={id}
        d={getEdgePath()}
      />
      {hasNavigationButton(source) && (
        <ToSource
          {...getNavigationButtonProps([sourceX, sourceY])}
          onClick={evt => selectAndCenterNode(evt, target)}
        />
      )}
      {hasNavigationButton(target) && (
        <ToTarget
          {...getNavigationButtonProps([targetX, targetY])}
          onClick={evt => selectAndCenterNode(evt, source)}
        />
      )}
      {selected && !state.isReadOnly && target !== FALLBACK_NODE_ID && (
        <RemoveButton
          {...getCenterProps()}
          fill={COLORS.WHITE}
          color={COLORS.N500}
          onClick={evt => {
            evt.stopPropagation()
            removeEdge()
          }}
        />
      )}
    </>
  )
}

export default memo(BezierEdge)
