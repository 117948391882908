import { useEffect, useRef } from 'react'

export function usePanelVisibility(
  onClickOutside = () => {
    return
  },
  onEnterKey = () => {
    return
  }
): React.MutableRefObject<any> {
  const ref = useRef<any>(null)
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside()
    }
  }
  const handleEnterKey = (event: globalThis.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onEnterKey()
    } else if (event.key === 'Escape') {
      onClickOutside()
    }
  }
  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    window.addEventListener('keydown', handleEnterKey)
    return () => {
      window.removeEventListener('click', handleClickOutside)
      window.removeEventListener('keydown', handleEnterKey)
    }
  })
  return ref
}
