import { NodeTypes, State, Webview } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { WebviewAction } from './webview-action'

export interface AddWebviewInterface {
  type: ActionType.ADD_WEBVIEW
  newWebview: Webview
}

export interface AddWebviewHistoryChange {
  type: ActionType.ADD_WEBVIEW
  newWebview: Webview
  currentWebviewId?: string
  currentNode?: NodeTypes
}

export class AddWebviewAction extends ReversibleAction {
  static apply = (state: State, { newWebview }: AddWebviewInterface): void => {
    WebviewAction.addWebview(state, newWebview)
    this.trackHistoryChange(state, newWebview, state.currentWebviewId)
  }

  static undo = (state: State, change: AddWebviewHistoryChange) => {
    WebviewAction.removeWebview(state, change.newWebview)
  }

  static redo = (state: State, change: AddWebviewHistoryChange) => {
    WebviewAction.addWebview(state, change.newWebview)
  }

  private static trackHistoryChange = (
    state: State,
    newWebview: Webview,
    currentWebviewId?: string
  ) => {
    const newChange: AddWebviewHistoryChange = {
      type: ActionType.ADD_WEBVIEW,
      newWebview,
      currentWebviewId,
      currentNode: state.currentNode,
    }
    this.updateChangesHistory(state, newChange)
  }
}
