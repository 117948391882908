import { HtFlow } from '../../../domain/models/cms/hubtype/flow'
import { FALLBACK_FLOW, KNOWLEDGE_BASE_FLOW } from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { HtIcon, Icon } from '../base'
import { StyledFlowItemWithArrow } from './flow-selector-styles'

interface DefaultFlowItemProps {
  flow: HtFlow
}

export const DefaultFlowItem = ({ flow }: DefaultFlowItemProps) => {
  const isKnowledgeBaseActive = useFlowBuilderSelector(
    ctx => ctx.state.isKnowledgeBaseActive
  )

  const hasDivision =
    (flow.id === KNOWLEDGE_BASE_FLOW.id && isKnowledgeBaseActive) ||
    (flow.id === FALLBACK_FLOW.id && !isKnowledgeBaseActive)

  return (
    <StyledFlowItemWithArrow
      $hasDivision={hasDivision}
      $hasBigMargin={flow.id === FALLBACK_FLOW.id && isKnowledgeBaseActive}
    >
      {[FALLBACK_FLOW.id, KNOWLEDGE_BASE_FLOW.id].includes(flow.id) && (
        <HtIcon icon={Icon.ARROW_TURN_DOWN_RIGHT} />
      )}
      {flow.name}
    </StyledFlowItemWithArrow>
  )
}
