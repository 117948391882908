import { useEffect, useState } from 'react'

import { HubtypeService } from '../../../../../repository/hubtype/hubtype-service'
import { MembersInfo } from '../../../../custom-hooks/use-ably'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { TextSmall, TextSmallBold } from '../../../base'
import { StyledActiveUserInfoContainer } from './session-styles'

interface ActiveUserInfoProps {
  membersInfo?: MembersInfo
  color: string
}

export const ActiveUserInfo = ({ membersInfo, color }: ActiveUserInfoProps) => {
  const authToken = useFlowBuilderSelector(ctx => ctx.state.authToken)
  const [userInfoText, setUserInfoText] = useState<string>('')

  const getUserInfoText = async (userId: string) => {
    const hubtypeUser = await HubtypeService.getUserData(authToken, userId)
    if (!hubtypeUser) return ''
    const hasCompleteName = hubtypeUser.firstName && hubtypeUser.lastName
    if (hasCompleteName) {
      return `${hubtypeUser.firstName} ${hubtypeUser.lastName}`
    }
    return hubtypeUser.email
  }

  useEffect(() => {
    if (!membersInfo) {
      return
    }

    if (membersInfo.activeUsers.length === 0) {
      getUserInfoText('').then(text => setUserInfoText(text))
      return
    }

    const anotherUserOnline = membersInfo.activeUsers.find(
      u => u.connectionId !== membersInfo.me
    )
    const currentActiveUserId = anotherUserOnline
      ? anotherUserOnline.clientId
      : membersInfo.me

    getUserInfoText(currentActiveUserId).then(text => setUserInfoText(text))
  }, [membersInfo])

  return (
    <StyledActiveUserInfoContainer color={color}>
      <TextSmallBold>Currently active user</TextSmallBold>
      {userInfoText && <TextSmall>{userInfoText}</TextSmall>}
    </StyledActiveUserInfoContainer>
  )
}
