import { useEffect, useState } from 'react'

import {
  ButtonFields,
  ContentType,
  UrlFields,
} from '../../../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { NodeTypes, PopupType } from '../../../types'
import { BoldSpan, TextBodySmall } from '../../base'
import { Popup } from '../popup'

interface DeleteUrlPopupProps {
  urlToRemove: UrlFields
}

export const DeleteUrlPopup = ({
  urlToRemove,
}: DeleteUrlPopupProps): JSX.Element => {
  const { setPopupContent, removeUrl } = useFlowBuilderSelector(ctx => ctx)
  const { currentNode, nodes, currentLocale } = useFlowBuilderSelector(
    ctx => ctx.state
  )
  const [nodesWithUrl, setNodesWithUrl] = useState<NodeTypes[]>([])

  useEffect(() => {
    const linkedNodes = findLinkedNodes()
    if (shouldUrlBeAutoDeleted(linkedNodes)) {
      deleteUrl()
    } else {
      setNodesWithUrl(linkedNodes)
    }
  }, [])

  const buttonHasUrl = (button: ButtonFields): boolean => {
    return button.hasUrl(urlToRemove.id, currentLocale.code)
  }

  const isNodeLinkedToUrl = (node: NodeTypes): boolean => {
    return (
      (node.type === ContentType.TEXT &&
        node.data.buttons.some(button => buttonHasUrl(button))) ||
      (node.type === ContentType.WHATSAPP_CTA_URL_BUTTON &&
        buttonHasUrl(node.data.button))
    )
  }

  const findLinkedNodes = (): NodeTypes[] => {
    return nodes.filter(node => isNodeLinkedToUrl(node))
  }

  const shouldUrlBeAutoDeleted = (linkedNodes: NodeTypes[]): boolean => {
    return (
      (linkedNodes.length === 1 && linkedNodes[0].id === currentNode?.id) ||
      linkedNodes.length === 0
    )
  }

  const deleteUrl = (): void => {
    setPopupContent()
    removeUrl(urlToRemove)
  }

  if (nodesWithUrl.length === 0) return <></>

  return (
    <Popup
      title={PopupType.DELETE_URL}
      confirmButton={{ text: 'Delete', onClick: deleteUrl }}
      message={
        'This URL is being used in one or more other buttons in this conversational app. If it is deleted, it will be also removed from all the other button components.'
      }
    >
      <TextBodySmall>
        URL to delete: <BoldSpan>{urlToRemove.name}</BoldSpan>
      </TextBodySmall>
      <TextBodySmall>
        Contents using it:{' '}
        <BoldSpan>
          {nodesWithUrl.map(node => node.data.code).join('; ')}
        </BoldSpan>
      </TextBodySmall>
    </Popup>
  )
}
