import { PlacementAxis } from 'react-aria'
import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { textSmall } from '../typography'

export enum TooltipPlacement {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

const bottom = css`
  top: calc(100% + 7px) !important;
  left: 0px !important;
  right: unset !important;
  bottom: unset !important;
`

const left = css`
  top: 0px !important;
  right: calc(100% + 8px) !important;
  left: unset !important;
  bottom: unset !important;
`

const right = css`
  top: 0px !important;
  left: calc(100% + 8px) !important;
  right: unset !important;
  bottom: unset !important;
`

const top = css`
  bottom: calc(100% + 7px) !important;
  left: 0px !important;
  right: unset !important;
  top: unset !important;
`

export const StyledTooltip = styled.span<{ $placement?: PlacementAxis }>`
  ${textSmall};
  background: ${COLORS.N700};
  color: ${COLORS.WHITE};
  position: absolute;
  height: max-content;
  width: max-content;
  max-width: 180px;
  padding: 4px 8px;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 100;

  ${props => props.$placement === TooltipPlacement.BOTTOM && bottom};
  ${props => props.$placement === TooltipPlacement.LEFT && left};
  ${props => props.$placement === TooltipPlacement.RIGHT && right};
  ${props => props.$placement === TooltipPlacement.TOP && top};
`
