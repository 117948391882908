const postMessageToParent = (event: string, data: any = null) => {
  // eslint-disable-next-line no-restricted-globals
  parent.postMessage(JSON.stringify({ event, data }), '*')
}

export const postCloseFromFlowBuilderMessage = (
  closeData: string | null
): void => {
  postMessageToParent('closeFromFlowBuilder', closeData)
}

export const postCloseableStatusMessage = (isSaved: boolean): void => {
  const event = isSaved ? 'closeable' : 'notCloseable'
  postMessageToParent(event)
}

export const postFlowBuilderInitializedMessage = (): void => {
  postMessageToParent('flowBuilderInitialized')
}

export const getCloseFromHubtypeData = (
  event: MessageEvent
): string | undefined => {
  try {
    const message = JSON.parse(event.data)
    return message.event === 'closeFromHubtype' ? message.data : undefined
  } catch (error) {
    return undefined
  }
}

export const getFlowBuilderSettings = (
  event: MessageEvent
): any | undefined => {
  try {
    const message = JSON.parse(event.data)
    return message.event === 'flowBuilderSettings' ? message.data : undefined
  } catch (error) {
    return undefined
  }
}
