import { HtIcon } from '../ht-icon/ht-icons'
import { Icon } from '../ht-icon/icons-list'
import { TextBodySmall } from '../typography'
import { Content, StyledBanner, Title } from './banner-styles'

export interface BannerProps {
  title?: string

  description: string

  intent?: 'primary' | 'info' | 'error' | 'warning' | 'neutral'
}

export const Banner = ({
  title,
  description,
  intent = 'primary',
}: BannerProps) => {
  return (
    <StyledBanner $intent={intent} role='banner'>
      <Content $hasTitle={!!title}>
        <Title>
          <HtIcon icon={Icon.CIRCLE_INFO} prefix='fas' />
          {title && <span>{title}</span>}
        </Title>
        <TextBodySmall>{description}</TextBodySmall>
      </Content>
    </StyledBanner>
  )
}
