import { useEffect } from 'react'

export const useConnectionStatus = (
  onOnline: () => void,
  onOffline: () => void
) => {
  useEffect(() => {
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    return () => {
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [onOnline, onOffline])
}
