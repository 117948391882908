import { memo, useEffect, useState } from 'react'

import {
  CONDITION_VALUE,
  HelperText,
} from '../../../UI/components/node-editor-panel/constants'
import { EditorFields } from '../../../UI/components/node-editor-panel/editor-styles'
import { ContentTextField } from '../../../UI/components/node-editor-panel/editor-widgets/text-input-widgets'
import { EditorProps } from '../../../UI/components/node-editor-panel/node-editor-panel'
import { VariableFormat } from '../model'
import { ConditionValueFields } from './model'

export interface ConditionValueEditorProps extends EditorProps {
  conditionValue: ConditionValueFields
  isSelected: boolean
  variableFormat?: VariableFormat
}

export const ConditionValueEditor = memo(
  ({
    conditionValue,
    variableFormat,
    isSelected,
    ...props
  }: ConditionValueEditorProps): JSX.Element => {
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
      setHasError(
        variableFormat === VariableFormat.NUMBER &&
          isNaN(Number(conditionValue.name))
      )
    }, [conditionValue, variableFormat])

    if (!isSelected) return <></>
    return (
      <EditorFields>
        <ContentTextField
          {...props}
          id={conditionValue.id}
          field={CONDITION_VALUE}
          value={conditionValue.name.toString()}
          isLabelHidden
          changeProps={{ conditionValueId: conditionValue.id }}
          error={hasError}
          helperText={
            variableFormat === VariableFormat.NUMBER
              ? HelperText.NUMBERS_ONLY
              : undefined
          }
        />
      </EditorFields>
    )
  }
)
