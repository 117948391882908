/* eslint-disable @typescript-eslint/naming-convention */
import { LocaleCode } from './code'
import { Country } from './country'
import { Language } from './language'
import { Locale } from './locale'

export const SUPPORTED_LOCALES = {
  afrikaans_south_africa: new Locale(
    LocaleCode.AF_ZA,
    Language.AFRIKAANS,
    Country.ZA
  ),
  arabic_saudi_arabia: new Locale(
    LocaleCode.AR_SA,
    Language.ARABIC,
    Country.SA
  ),
  bengali_india: new Locale(LocaleCode.BN_IN, Language.BENGALI, Country.IN),
  bulgarian_bulgaria: new Locale(
    LocaleCode.BG_BG,
    Language.BULGARIAN,
    Country.BG
  ),
  catalan: new Locale(LocaleCode.CA, Language.CATALAN),
  catalan_spain: new Locale(LocaleCode.CA_ES, Language.CATALAN, Country.ES),
  chinese_china: new Locale(LocaleCode.ZH_CN, Language.CHINESE, Country.CN),
  croatian: new Locale(LocaleCode.HR, Language.CROATIAN),
  croatian_croatia: new Locale(LocaleCode.HR_HR, Language.CROATIAN, Country.HR),
  czech: new Locale(LocaleCode.CS, Language.CZECH),
  czech_czech_republic: new Locale(
    LocaleCode.CS_CZ,
    Language.CZECH,
    Country.CZ
  ),
  danish_denmark: new Locale(LocaleCode.DA_DK, Language.DANISH, Country.DK),
  dutch: new Locale(LocaleCode.NL, Language.DUTCH),
  dutch_netherlands: new Locale(LocaleCode.NL_NL, Language.DUTCH, Country.NL),
  english: new Locale(LocaleCode.EN, Language.ENGLISH),
  english_austria: new Locale(LocaleCode.EN_AT, Language.ENGLISH, Country.AT),
  english_canada: new Locale(LocaleCode.EN_CA, Language.ENGLISH, Country.CA),
  english_czechia: new Locale(LocaleCode.EN_CZ, Language.ENGLISH, Country.CZ),
  english_denmark: new Locale(LocaleCode.EN_DK, Language.ENGLISH, Country.DK),
  english_estonia: new Locale(LocaleCode.EN_EE, Language.ENGLISH, Country.EE),
  english_finland: new Locale(LocaleCode.EN_FI, Language.ENGLISH, Country.FI),
  english_ireland: new Locale(LocaleCode.EN_IE, Language.ENGLISH, Country.IE),
  english_latvia: new Locale(LocaleCode.EN_LV, Language.ENGLISH, Country.LV),
  english_lithuania: new Locale(LocaleCode.EN_LT, Language.ENGLISH, Country.LT),
  english_malta: new Locale(LocaleCode.EN_MT, Language.ENGLISH, Country.MT),
  english_netherlands: new Locale(
    LocaleCode.EN_NL,
    Language.ENGLISH,
    Country.NL
  ),
  english_norway: new Locale(LocaleCode.EN_NO, Language.ENGLISH, Country.NO),
  english_russia: new Locale(LocaleCode.EN_RU, Language.ENGLISH, Country.RU),
  english_slovakia: new Locale(LocaleCode.EN_SK, Language.ENGLISH, Country.SK),
  english_sweden: new Locale(LocaleCode.EN_SE, Language.ENGLISH, Country.SE),
  english_switzerland: new Locale(
    LocaleCode.EN_CH,
    Language.ENGLISH,
    Country.CH
  ),
  english_united_kingdom: new Locale(
    LocaleCode.EN_GB,
    Language.ENGLISH,
    Country.GB
  ),
  english_united_states: new Locale(
    LocaleCode.EN_US,
    Language.ENGLISH,
    Country.US
  ),
  estonian_estonia: new Locale(LocaleCode.ET_EE, Language.ESTONIAN, Country.EE),
  finnish_finland: new Locale(LocaleCode.FI_FI, Language.FINNISH, Country.FI),
  french: new Locale(LocaleCode.FR, Language.FRENCH),
  french_canada: new Locale(LocaleCode.FR_CA, Language.FRENCH, Country.CA),
  french_france: new Locale(LocaleCode.FR_FR, Language.FRENCH, Country.FR),
  french_luxembourg: new Locale(LocaleCode.FR_LU, Language.FRENCH, Country.LU),
  french_switzerland: new Locale(LocaleCode.FR_CH, Language.FRENCH, Country.CH),
  georgian_georgia: new Locale(LocaleCode.KA_GE, Language.GEORGIAN, Country.GE),
  german: new Locale(LocaleCode.DE, Language.GERMAN),
  german_austria: new Locale(LocaleCode.DE_AT, Language.GERMAN, Country.AT),
  german_germany: new Locale(LocaleCode.DE_DE, Language.GERMAN, Country.DE),
  german_switzerland: new Locale(LocaleCode.DE_CH, Language.GERMAN, Country.CH),
  greek: new Locale(LocaleCode.EL, Language.GREEK),
  greek_greece: new Locale(LocaleCode.EL_GR, Language.GREEK, Country.GR),
  hindi_india: new Locale(LocaleCode.HI_IN, Language.HINDI, Country.IN),
  hungarian_hungary: new Locale(
    LocaleCode.HU_HU,
    Language.HUNGARIAN,
    Country.HU
  ),
  icelandic_iceland: new Locale(
    LocaleCode.IS_IS,
    Language.ICELANDIC,
    Country.IS
  ),
  indonesian_indonesia: new Locale(
    LocaleCode.ID_ID,
    Language.INDONESIAN,
    Country.ID
  ),
  italian: new Locale(LocaleCode.IT, Language.ITALIAN),
  italian_italy: new Locale(LocaleCode.IT_IT, Language.ITALIAN, Country.IT),
  italian_switzerland: new Locale(
    LocaleCode.IT_CH,
    Language.ITALIAN,
    Country.CH
  ),
  japanese_japan: new Locale(LocaleCode.JA_JP, Language.JAPANESE, Country.JP),
  korean_korea: new Locale(LocaleCode.KO_KR, Language.KOREAN, Country.KR),
  lithuanian_lithuania: new Locale(
    LocaleCode.LT_LT,
    Language.LITHUANIAN,
    Country.LT
  ),
  malay_malaysia: new Locale(LocaleCode.MS_MY, Language.MALAY, Country.MY),
  norwegian_nynorsk_norway: new Locale(
    LocaleCode.NN_NO,
    Language.NORWEGIAN_NYNORSK,
    Country.NO
  ),
  polish: new Locale(LocaleCode.PL, Language.POLISH),
  polish_poland: new Locale(LocaleCode.PL_PL, Language.POLISH, Country.PL),
  portuguese: new Locale(LocaleCode.PT, Language.PORTUGUESE),
  portuguese_portugal: new Locale(
    LocaleCode.PT_PT,
    Language.PORTUGUESE,
    Country.PT
  ),
  romanian: new Locale(LocaleCode.RO, Language.ROMANIAN),
  romanian_romania: new Locale(LocaleCode.RO_RO, Language.ROMANIAN, Country.RO),
  russian: new Locale(LocaleCode.RU, Language.RUSSIAN),
  russian_estonia: new Locale(LocaleCode.RU_EE, Language.RUSSIAN, Country.EE),
  russian_latvia: new Locale(LocaleCode.RU_LV, Language.RUSSIAN, Country.LV),
  russian_lithuania: new Locale(LocaleCode.RU_LT, Language.RUSSIAN, Country.LT),
  russian_russia: new Locale(LocaleCode.RU_RU, Language.RUSSIAN, Country.RU),
  serbian_cyrillic_serbia: new Locale(
    LocaleCode.SR_CYRL_RS,
    Language.SERBIAN,
    Country.RS
  ),
  sinhala_sri_lanka: new Locale(LocaleCode.SI_LK, Language.SINHALA, Country.LK),
  slovak: new Locale(LocaleCode.SK, Language.SLOVAK),
  slovak_slovakia: new Locale(LocaleCode.SK_SK, Language.SLOVAK, Country.SK),
  slovenian_slovenia: new Locale(
    LocaleCode.SL_SI,
    Language.SLOVENIAN,
    Country.SI
  ),
  spanish: new Locale(LocaleCode.ES, Language.SPANISH),
  spanish_colombia: new Locale(LocaleCode.ES_CO, Language.SPANISH, Country.CO),
  spanish_mexico: new Locale(LocaleCode.ES_MX, Language.SPANISH, Country.MX),
  spanish_spain: new Locale(LocaleCode.ES_ES, Language.SPANISH, Country.ES),
  swedish_sweden: new Locale(LocaleCode.SV_SE, Language.SWEDISH, Country.SE),
  thai_thailand: new Locale(LocaleCode.TH_TH, Language.THAI, Country.TH),
  turkish: new Locale(LocaleCode.TR, Language.TURKISH),
  turkish_turkey: new Locale(LocaleCode.TR_TR, Language.TURKISH, Country.TR),
  ukrainian: new Locale(LocaleCode.UK, Language.UKRAINIAN),
  ukrainian_ukraine: new Locale(
    LocaleCode.UK_UA,
    Language.UKRAINIAN,
    Country.UA
  ),
  vietnamese_vietnam: new Locale(
    LocaleCode.VI_VN,
    Language.VIETNAMESE,
    Country.VN
  ),
}
