export enum HtNodeWithContentType {
  CAROUSEL = 'carousel',
  HANDOFF = 'handoff',
  IMAGE = 'image',
  TEXT = 'text',
  KEYWORD = 'keyword',
  INTENT = 'intent',
  FUNCTION = 'function',
  FALLBACK = 'fallback',
  VIDEO = 'video',
  WHATSAPP_BUTTON_LIST = 'whatsapp-button-list',
  WHATSAPP_CTA_URL_BUTTON = 'whatsapp-cta-url-button',
  GO_TO_FLOW = 'go-to-flow',
  BOT_ACTION = 'bot-action',
  SMART_INTENT = 'smart-intent',
  WEBVIEW_TEXT = 'webview-text',
  WEBVIEW_IMAGE = 'webview-image',
  KNOWLEDGE_BASE = 'knowledge-base',
}

export enum HtNodeWithoutContentType {
  URL = 'url',
  PAYLOAD = 'payload',
}

export enum HtButtonStyle {
  BUTTON = 'button',
  QUICK_REPLY = 'quick-reply',
}

export const HtContentType = {
  ...HtNodeWithContentType,
  ...HtNodeWithoutContentType,
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HtContentType = HtNodeWithContentType | HtNodeWithoutContentType
