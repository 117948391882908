import { OrganizationAiModelWithIntents } from '../../../../domain/models/organization-models'
import { NodeTypes, PopupType, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'

export interface SelectAiModelInterface {
  type: ActionType.SELECT_AI_MODEL
  aiModel?: OrganizationAiModelWithIntents
}

export interface SelectAiModelHistoryChange {
  type: ActionType.SELECT_AI_MODEL
  newAiModel?: OrganizationAiModelWithIntents
  oldAiModel?: OrganizationAiModelWithIntents
  currentNode?: NodeTypes
  currentFlowId: string
}

export class SelectAiModelAction extends ReversibleAction {
  static apply = (state: State, { aiModel }: SelectAiModelInterface): void => {
    if (state.popupContent?.type === PopupType.INTENT_MODELS) {
      this.trackHistoryChange(state, aiModel)
      state.popupContent = undefined
    }
    state.currentAiModel = aiModel
  }

  static undo = (state: State, change: SelectAiModelHistoryChange) => {
    state.currentAiModel = change.oldAiModel
  }

  static redo = (state: State, change: SelectAiModelHistoryChange) => {
    state.currentAiModel = change.newAiModel
  }

  private static trackHistoryChange = (
    state: State,
    aiModel?: OrganizationAiModelWithIntents
  ) => {
    const newChange: SelectAiModelHistoryChange = {
      type: ActionType.SELECT_AI_MODEL,
      newAiModel: aiModel,
      oldAiModel: state.currentAiModel,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
