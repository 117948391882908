import {
  ButtonFields,
  UrlFields,
} from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { NodeAction } from '../node-actions/node-action'
import { ReversibleAction } from '../reversible-action'
import { NonMessageAction } from './non-message-action'

export interface RemoveUrlsInterface {
  type: ActionType.REMOVE_URL
  urlToRemove: UrlFields
}

export interface RemoveUrlsHistoryChange {
  type: ActionType.REMOVE_URL
  urlToRemove: UrlFields
  oldNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class RemoveUrlsAction extends ReversibleAction {
  static apply = (state: State, urlToRemove: UrlFields): void => {
    this.trackHistoryChange(state, urlToRemove)
    NonMessageAction.removeUrl(state, urlToRemove)
    this.removeUrlFromNodes(state, urlToRemove)
  }

  static undo = (state: State, change: RemoveUrlsHistoryChange): void => {
    NonMessageAction.addUrl(state, change.urlToRemove)
    state.nodes = change.oldNodes
  }

  static redo = (state: State, change: RemoveUrlsHistoryChange): void => {
    NonMessageAction.removeUrl(state, change.urlToRemove)
    this.removeUrlFromNodes(state, change.urlToRemove)
  }

  private static trackHistoryChange = (
    state: State,
    urlToRemove: UrlFields
  ) => {
    const newChange: RemoveUrlsHistoryChange = {
      type: ActionType.REMOVE_URL,
      urlToRemove,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
      oldNodes: state.nodes,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static removeUrlFromNodes = (
    state: State,
    urlToRemove: UrlFields
  ) => {
    NodeAction.updateButtonsInNodes(state, button => {
      this.removeUrlIfMatch(button, urlToRemove)
    })
  }

  private static removeUrlIfMatch = (
    button: ButtonFields,
    urlToRemove: UrlFields
  ): void => {
    if (button.url?.id === urlToRemove.id) {
      button.url = undefined
    }
    button.urlLocales = button.urlLocales.filter(
      urlLocale => urlLocale.id !== urlToRemove.id
    )
  }
}
