import { Connection, Edge } from 'reactflow'

import { FALLBACK_FLOW, FALLBACK_NODE_ID } from '../../../constants'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { EdgeAction } from './edge-action'

export interface ConnectNodesInterface {
  type: ActionType.CONNECT_NODES
  connection: Connection
}

export interface ConnectNodesHistoryChange {
  type: ActionType.CONNECT_NODES
  newEdge: Edge
  oldEdge: Edge | null
  currentNode?: NodeTypes
  currentFlowId: string
}

export class ConnectNodesAction extends ReversibleAction {
  static apply = (state: State, connection: Connection): void => {
    if (!this.isValidConnection(connection)) return
    const oldEdge = this.removeDuplicatedEdge(state, connection)
    const newEdge = EdgeAction.connectionToEdge(connection)
    if (newEdge) {
      this.trackHistoryChange(state, newEdge, oldEdge)
      EdgeAction.addEdge(state, newEdge)
    }
  }

  static undo = (state: State, change: ConnectNodesHistoryChange) => {
    EdgeAction.removeEdge(state, change.newEdge)
    if (change.oldEdge) {
      EdgeAction.addEdge(state, change.oldEdge)
    }
  }

  static redo = (state: State, change: ConnectNodesHistoryChange) => {
    EdgeAction.addEdge(state, change.newEdge)
    if (change.oldEdge) {
      EdgeAction.removeEdge(state, change.oldEdge)
    }
  }

  private static trackHistoryChange = (
    state: State,
    newEdge: Edge,
    oldEdge: Edge | null
  ) => {
    const newChange: ConnectNodesHistoryChange = {
      type: ActionType.CONNECT_NODES,
      newEdge,
      oldEdge,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static removeDuplicatedEdge = (
    state: State,
    connection: Connection
  ): Edge | null => {
    if (!connection.sourceHandle || !connection.source) return null
    const duplicatedEdge = EdgeAction.getEdgeBySourceHandle(state, connection)
    if (duplicatedEdge) {
      EdgeAction.removeEdge(state, duplicatedEdge)
    }
    return duplicatedEdge || null
  }

  private static isValidConnection(connection: Connection) {
    return (
      connection.source !== connection.target &&
      connection.target !== FALLBACK_NODE_ID &&
      !connection.sourceHandle?.includes(`start|${FALLBACK_FLOW.id}`)
    )
  }
}
