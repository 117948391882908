export enum Icon {
  APPLE = 'apple',
  ARROW_PROGRESS = 'arrow-progress',
  ARROW_TURN_DOWN_RIGHT = 'arrow-turn-down-right',
  BRAIN = 'brain',
  CARET_DOWN = 'caret-down',
  CARET_UP = 'caret-up',
  CHART_SIMPLE = 'chart-simple',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CIRCLE_CHECK = 'circle-check',
  CIRCLE_EXCLAMATION = 'circle-exclamation',
  CIRCLE_INFO = 'circle-info',
  CIRCLE_QUESTION = 'circle-question',
  CIRCLE_XMARK = 'circle-xmark',
  CLOUD_CHECK = 'cloud-check',
  CLOUD_SLASH = 'cloud-slash',
  CODE = 'code',
  EDIT = 'edit',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  EXPAND = 'expand',
  FACE_SMILE = 'face-smile',
  FACEBOOK_MESSENGER = 'facebook-messenger',
  FILE = 'file',
  FILE_CSV = 'file-csv',
  FILE_EXCEL = 'file-excel',
  FILE_PDF = 'file-pdf',
  FILE_WORD = 'file-word',
  FILM = 'film',
  FONT = 'font',
  GRIP_DOTS = 'grip-dots',
  HEADSET = 'headset',
  HOUSE = 'house',
  IMAGE = 'image',
  INSTAGRAM = 'instagram',
  LIFE_RING = 'life-ring',
  LINK = 'link',
  LIST = 'list-ul',
  MESSAGES = 'messages',
  MINUS = 'minus',
  NUMBER = '2',
  PLUS = 'plus',
  QUOTE_RIGHT = 'quote-right',
  RECTANGLE_VERTICAL_HISTORY = 'rectangle-vertical-history',
  ROTATE_LEFT = 'rotate-left',
  ROTATE_RIGHT = 'rotate-right',
  SEARCH = 'search',
  SPLIT = 'split',
  TELEGRAM = 'telegram',
  TEXT = 'text',
  THUMBS_DOWN = 'thumbs-down',
  THUMBS_UP = 'thumbs-up',
  TOGGLE_ON = 'toggle-on',
  TRASH_CAN = 'trash-can',
  TWITTER = 'twitter',
  WAND_MAGIC_SPARKLES = 'wand-magic-sparkles',
  WEBCHAT = 'webchat',
  WHATSAPP = 'whatsapp',
  XMARK = 'xmark',
}

export const BRAND_ICONS = [
  Icon.APPLE,
  Icon.FACEBOOK_MESSENGER,
  Icon.INSTAGRAM,
  Icon.TELEGRAM,
  Icon.TWITTER,
  Icon.WHATSAPP,
]

export const REGULAR_ICONS = [
  Icon.CIRCLE_CHECK,
  Icon.CIRCLE_INFO,
  Icon.CIRCLE_XMARK,
  Icon.CLOUD_CHECK,
  Icon.CLOUD_SLASH,
  Icon.FACE_SMILE,
  Icon.FILE_CSV,
  Icon.FILE_EXCEL,
  Icon.FILE_PDF,
  Icon.FILE_WORD,
  Icon.FILE,
  Icon.IMAGE,
  Icon.LIFE_RING,
  Icon.THUMBS_DOWN,
  Icon.THUMBS_UP,
]

export const DUOTONE_ICONS = [Icon.EXCLAMATION_TRIANGLE]
