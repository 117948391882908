import { Controller, UseFormReturn } from 'react-hook-form'

import { ConversationalApp } from '../../../../../../domain/models/organization-models'
import { HtSelectDropdown } from '../../../../base'
import { Banner } from '../../../../base/ht-banner/banner'
import { Popup } from '../../../../popup/popup'
import { TransferDetails } from '../transfer-flow-modal'

interface SelectConversationalAppStepProps {
  form: UseFormReturn<TransferDetails, any, undefined>
  conversationalApps: ConversationalApp[]
  cancel: () => void
  goNext: () => void
}

export const SelectConversationalAppStep = ({
  form,
  conversationalApps,
  cancel,
  goNext,
}: SelectConversationalAppStepProps): JSX.Element => {
  return (
    <Popup
      title='Transfer flows'
      confirmButton={{
        text: 'Next',
        disabled: !form.formState.isValid,
        onClick: goNext,
      }}
      discardButton={{ text: 'Cancel', onClick: cancel }}
      canCloseOnClickOutside={false}
    >
      <form id='conversational-app-form'>
        <Controller
          name='conversationalApp'
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => (
            <HtSelectDropdown
              {...field}
              label='Transfer to'
              placeholder='Select a bot'
              data={conversationalApps}
              selectedKey={field.value?.id}
              listProps={{ listWidth: '468px', maxListHeight: '250px' }}
            >
              {item => <div>{item.name}</div>}
            </HtSelectDropdown>
          )}
        />
      </form>
      <Banner
        intent='info'
        title='About transfer flow'
        description='The published flow is the one that will be transferred; any unpublished updates will not be included. Once transferred, the flow must be published within the destination bot to replace its current published version.'
      />
    </Popup>
  )
}
