import styled from 'styled-components'

import { FeedbackType } from '../../../../domain/feedback'
import { COLORS } from '../colors'
import { Icon } from '../ht-icon/icons-list'

export const SnackbarContainer = styled.div<{ $type: FeedbackType }>`
  position: absolute;
  top: 66px;
  left: calc(50% - 285px);
  width: 570px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 20px;
  border-radius: 4px;
  color: ${COLORS.BLACK};
  border-radius: 4px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  background: ${props =>
    props.$type === FeedbackType.SUCCESS ? COLORS.G50 : COLORS.R50};

  > button:last-child {
    margin-left: auto;
  }

  [data-icon=${Icon.CLOUD_SLASH}] {
    color: ${COLORS.R300} !important;
  }

  [data-icon=${Icon.CLOUD_CHECK}] {
    color: ${COLORS.G300} !important;
  }
`
