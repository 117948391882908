import styled from 'styled-components'

import { COLORS } from '../../base'

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledInlineTextLink = styled.button`
  all: unset;
  cursor: pointer;
  color: ${COLORS.L500};
`
