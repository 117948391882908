import { Item } from 'react-stately'

import { DropdownData } from '../common/dropdown/types'
import { Select, SelectProps } from './widgets/select'

export interface HtSelectDropdownProps<T extends DropdownData>
  extends Omit<SelectProps<T>, 'children'> {
  data: T[]
  label: string
  children?: (item: T) => JSX.Element | string
  onChange: (value?: T) => void
}

export const HtSelectDropdown = <T extends DropdownData>(
  props: HtSelectDropdownProps<T>
): JSX.Element => {
  const onSelectionChange = (id: React.Key) => {
    const selectedValue = props.data.find(item => item.id === id)
    props.onChange(selectedValue)
  }

  return (
    <Select
      {...props}
      placeholder={props.placeholder || props.label}
      items={props.data}
      onSelectionChange={onSelectionChange}
      selectedKey={props.selectedKey || null}
    >
      {item => (
        <Item textValue={item.name || item.id}>
          {props.children?.(item) || item.name || item.id}
        </Item>
      )}
    </Select>
  )
}
