import styled, { css } from 'styled-components'

export const textBody = css`
  font-style: normal;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin: 0px;
  word-break: normal;
`

export const textBodyBig = css`
  ${textBody};
  font-size: 18px;
`

export const textBodySmall = css`
  ${textBody};
  font-size: 14px;
`

export const textSmall = css`
  ${textBody};
  font-size: 12px;
`

export const textSmallExtra = css`
  ${textBody};
  font-size: 10px;
`

export const textMini = css`
  ${textBody};
  font-size: 8px;
`

export const bold = css`
  font-weight: 600;
`

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Text = styled.div<{
  $withEllipsis?: boolean
  $wordBreak?: 'break-word' | 'break-all'
}>`
  ${props => props.$withEllipsis && ellipsis};
  word-break: ${props => props.$wordBreak && props.$wordBreak} !important;
`

export const TextBody = styled(Text)`
  ${textBody};
`

export const TextBodyBig = styled(Text)`
  ${textBodyBig};
`

export const TextBodySmall = styled(Text)`
  ${textBodySmall};
`

export const TextSmall = styled(Text)`
  ${textSmall};
`

export const TextSmallExtra = styled(Text)`
  ${textSmallExtra};
`

export const TextMini = styled(Text)`
  ${textMini};
`

export const TextBodyBold = styled(Text)`
  ${bold};
`

export const TextBodyBigBold = styled(TextBodyBig)`
  ${bold};
`

export const TextBodySmallBold = styled(TextBodySmall)`
  ${bold};
`

export const TextSmallBold = styled(TextSmall)`
  ${bold};
`

export const TextSmallExtraBold = styled(TextSmallExtra)`
  ${bold};
`

export const TextMiniBold = styled(TextMini)`
  ${bold};
`

export const BoldSpan = styled.span`
  ${bold};
`
