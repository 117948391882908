import styled from 'styled-components'

import { COLORS } from '../colors'

export const StyledBadge = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 4px;
  background: ${COLORS.N100};

  svg {
    color: ${COLORS.N500};
  }
`
