import { useEffect, useState } from 'react'

import { normalizeValue } from '../../../../utils/string-utils'
import { SEARCH_DEBOUNCE_DELAY } from '../../../constants'
import { useDebounce } from '../../../custom-hooks'
import { ROLES } from '../../../roles'
import { HtIcon } from '../ht-icon/ht-icons'
import { Icon } from '../ht-icon/icons-list'
import { InputContainer } from '../ht-input/ht-input-styles'
import { Size } from '../variants'

interface HtSearchBarProps {
  placeholder?: string
  size?: Size
  width?: string
  onFilterChange: (normalizedValue: string) => void
}

export const HtSearchBar = ({
  placeholder,
  size = Size.MEDIUM,
  width,
  onFilterChange,
}: HtSearchBarProps): JSX.Element => {
  const [hasRemoveIcon, setHasRemoveIcon] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce<string>(
    searchValue,
    SEARCH_DEBOUNCE_DELAY
  )

  useEffect(() => filter(debouncedSearchValue), [debouncedSearchValue])

  const filter = (value: string) => {
    setHasRemoveIcon(value !== '')
    onFilterChange(normalizeValue(value))
  }

  return (
    <InputContainer $size={size || Size.MEDIUM} $width={width}>
      <HtIcon icon={Icon.SEARCH} size={Size.TINY} />
      <input
        role={ROLES.SEARCH_BOX}
        value={searchValue}
        placeholder={placeholder || 'Search'}
        onChange={({ target }) => setSearchValue(target.value)}
      />
      {hasRemoveIcon && (
        <HtIcon icon={Icon.XMARK} onClick={() => setSearchValue('')} />
      )}
    </InputContainer>
  )
}
