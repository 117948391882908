import styled, { css } from 'styled-components'

import { Size } from './variants'

const sizes = {
  [Size.MICRO]: css`
    gap: 2px;
  `,
  [Size.TINY]: css`
    gap: 4px;
  `,
  [Size.SMALL]: css`
    gap: 8px;
  `,
  [Size.MEDIUM]: css`
    gap: 16px;
  `,
  [Size.LARGE]: css`
    gap: 32px;
  `,
}

const alignments = {
  center: css`
    align-items: center;
  `,
  start: css`
    align-items: flex-start;
  `,
  end: css`
    align-items: flex-end;
  `,
  baseline: css`
    align-items: baseline;
  `,
}

const justifications = {
  center: css`
    justify-content: center;
  `,
  start: css`
    justify-content: flex-start;
  `,
  end: css`
    justify-content: flex-end;
  `,
  between: css`
    justify-content: space-between;
  `,
}

const createContainer = (direction: 'row' | 'column') => {
  const Container = styled.div<{
    $size?: Size
    $align?: keyof typeof alignments
    $justify?: keyof typeof justifications
  }>`
    display: flex;
    flex-direction: ${direction};
    ${({ $size }) => $size && sizes[$size]};
    ${({ $align }) => $align && alignments[$align]};
    ${({ $justify }) => $justify && justifications[$justify]};
  `
  return Container
}

export const HorizontalContainer = createContainer('row')
export const VerticalContainer = createContainer('column')
