import { memo } from 'react'

import {
  Checkbox,
  CheckboxHelperText,
  CheckboxLabel,
  Checkmark,
  CheckmarkContainer,
} from './ht-checkbox-styles'

interface HtCheckboxProps {
  checked?: boolean
  disabled?: boolean
  helperText?: string
  label?: string
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void
}

export const HtCheckbox = memo(
  ({ helperText, label, onChange, ...props }: HtCheckboxProps): JSX.Element => {
    return (
      <div>
        <Checkbox role='checkbox' {...props}>
          <input type='checkbox' {...props} onChange={onChange} />
          <CheckmarkContainer>
            <Checkmark />
          </CheckmarkContainer>
          <CheckboxLabel>{label}</CheckboxLabel>
        </Checkbox>
        {helperText && <CheckboxHelperText>{helperText}</CheckboxHelperText>}
      </div>
    )
  }
)
