import { TrackEventName, useAnalytics } from '../../../../analytics'
import { ADDONS_DETAIL_URL_PATH } from '../../../../constants'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { hasIntentModelsFeature } from '../../../../utils/feature-flags'
import { Popup } from '../../../popup/popup'

export const IntentModelsAddonsPopup = (): JSX.Element => {
  const analytics = useAnalytics()
  const featureFlags = useFlowBuilderSelector(
    ctx => ctx.state.organizationContents.featureFlags
  )
  const isSaved = useFlowBuilderSelector(ctx => ctx.state.isFlowSaved)

  const goToHubtypeIntentModels = (): void => {
    const closeData = '/ai/projects'
    goToHubtypeSection(closeData)
  }

  const goToHubtypeIntentModelsAddonModal = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.ADD_ONS, {
      origin: 'Intent model',
    })
    const closeData = `${ADDONS_DETAIL_URL_PATH}/intent-models`
    goToHubtypeSection(closeData)
  }

  const goToHubtypeSection = (closeData: string): void => {
    if (isSaved) {
      postCloseFromFlowBuilderMessage(closeData)
      return
    }
  }

  const getContent = () => {
    if (!hasIntentModelsFeature(featureFlags)) {
      return {
        confirmButton: {
          text: 'More info',
          onClick: goToHubtypeIntentModelsAddonModal,
        },
        message:
          'Identify and serve responses with NLU. Create a new model by including all the intents you desire to detect, train it, connect it to your chatbot and start answering user queries.',
      }
    }

    return {
      confirmButton: {
        text: 'Go to Intent models',
        onClick: goToHubtypeIntentModels,
      },
      message:
        'There are no available Intent models in your organization. You can set Intent models in the AI area of the organization dashboard.',
    }
  }

  return <Popup title={PopupType.INTENT_MODELS} {...getContent()}></Popup>
}
