import { FeedbackType } from '../domain/feedback'
import { getDateTime } from '../utils/date-utils'
import { useFeedbackMessage } from './custom-hooks/use-feedback-message'

// TODO: temporal hook split in a future refactor
export function useFlowBuilderFeedback() {
  const { reportError, reportSuccess, reportConnectionIssue, reportInform } =
    useFeedbackMessage()

  const reportFlowSaved = (
    savedStatus: FeedbackType,
    restoredVersionDate?: Date
  ): void => {
    const feedbackActionsMap: { [key in FeedbackType]?: () => void } = {
      [FeedbackType.SUCCESS]: () => saved(restoredVersionDate),
      [FeedbackType.CONNECTION_ISSUE]: () => saveConnectionIssue(),
      [FeedbackType.ERROR]: () => saveError(),
    }
    const action = feedbackActionsMap[savedStatus]
    action?.()
  }

  const reportFlowPublished = (publishedStatus: FeedbackType): void => {
    const feedbackActionsMap: { [key in FeedbackType]?: () => void } = {
      [FeedbackType.SUCCESS]: () => published(),
      [FeedbackType.CONNECTION_ISSUE]: () => publishConnectionIssue(),
      [FeedbackType.ERROR]: () => publishError(),
    }
    const action = feedbackActionsMap[publishedStatus]
    action?.()
  }

  const saved = (restoredVersionDate?: Date): void => {
    const successMessage = restoredVersionDate
      ? `Version published on ${getDateTime(restoredVersionDate)} restored`
      : 'Saved'
    reportSuccess(successMessage)
  }

  const saveError = () => {
    reportError(
      'Failed to save flow.\nPlease try again later or contact support@hubtype.com'
    )
  }

  const saveConnectionIssue = () => {
    reportConnectionIssue(
      'Failed to save flow.\nPlease check your internet connection, and try to save again'
    )
  }

  const reportVersionRestored = (
    isSaved: boolean,
    restoredVersionDate: Date
  ): void => {
    if (isSaved) {
      reportSuccess(
        `Version published on ${getDateTime(restoredVersionDate)} restored`
      )
    } else {
      reportError(
        'Failed to restore version.\nPlease try again later or contact support@hubtype.com'
      )
    }
  }

  const published = () => {
    reportSuccess('Published')
  }

  const publishError = () => {
    reportError(
      'Failed to publish flow.\nPlease try again later or contact support@hubtype.com'
    )
  }

  const publishConnectionIssue = () => {
    reportConnectionIssue(
      'Failed to publish flow.\nPlease check your internet connection, and try to save again'
    )
  }

  const reportVersionNotLoaded = () => {
    reportError('Failed to load version')
  }

  return {
    reportError,
    reportInform,
    reportFlowPublished,
    reportFlowSaved,
    reportVersionRestored,
    reportVersionNotLoaded,
  }
}
