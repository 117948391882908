import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { Flow, PopupType } from '../../../../types'
import { TextBodySmall, TextBodySmallBold } from '../../../base'
import { Popup } from '../../popup'

interface DeleteFlowPopupProps {
  flowToRemove: Flow
  linkedFlows: Flow[]
}

export const DeleteFlowPopup = ({
  flowToRemove,
  linkedFlows,
}: DeleteFlowPopupProps): JSX.Element => {
  const { setPopupContent, removeFlow } = useFlowBuilderSelector(ctx => ctx)

  const onClickAccept = async (): Promise<void> => {
    setPopupContent()
  }

  const onDeleteFlow = (): void => {
    removeFlow(flowToRemove)
    setPopupContent()
  }

  if (linkedFlows.length)
    return (
      <Popup
        title={PopupType.DELETE_FLOW}
        confirmButton={{ text: 'Accept', onClick: onClickAccept }}
        discardButton={{ isHidden: true }}
      >
        <TextBodySmall>
          This flow can’t be deleted as it is currently connected to other
          flows. Please remove those connections before deleting it.
        </TextBodySmall>
        <TextBodySmall>
          {`'${flowToRemove.name}' flow is currently being used in:`}
        </TextBodySmall>
        {linkedFlows.length && (
          <div>
            {linkedFlows.map(flow => (
              <TextBodySmallBold key={flow.id}>{flow.name}</TextBodySmallBold>
            ))}
          </div>
        )}
      </Popup>
    )

  return (
    <Popup
      title={PopupType.DELETE_FLOW}
      confirmButton={{ text: 'Delete', onClick: onDeleteFlow }}
      message={`Deleting '${flowToRemove.name}' flow will also delete all conversational elements in it. This action can be undone by using cmd+z/ctrl+z.`}
    />
  )
}
