import {
  FeedbackDetails,
  FeedbackMessage,
  FeedbackType,
} from '../../domain/feedback'
import { FEEDBACK_TIMEOUT } from '../constants'
import { useFlowBuilderSelector } from '../reducer/hooks'

export function useFeedbackMessage() {
  const { addFeedbackMessage, removeFeedbackMessages } = useFlowBuilderSelector(
    ctx => ctx
  )
  const report = (
    feedbackType: FeedbackType,
    shouldBeRemovedByDefault = false
  ) => {
    return (
      message: string,
      options?: FeedbackDetails,
      shouldBeRemoved = shouldBeRemovedByDefault
    ) => {
      const feedbackMessage = new FeedbackMessage(
        message,
        feedbackType,
        options
      )
      addFeedbackMessage(feedbackMessage)
      if (shouldBeRemoved) {
        setTimeout(
          () => removeFeedbackMessages([feedbackMessage]),
          FEEDBACK_TIMEOUT
        )
      }
    }
  }

  const reportError = report(FeedbackType.ERROR)
  const reportConnectionIssue = report(FeedbackType.CONNECTION_ISSUE)
  const reportInform = report(FeedbackType.INFORM, true)
  const reportSuccess = report(FeedbackType.SUCCESS, true)
  const reportCustomFeedback = report(FeedbackType.CUSTOM)

  return {
    reportError,
    reportConnectionIssue,
    reportInform,
    reportSuccess,
    reportCustomFeedback,
  }
}
