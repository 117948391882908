import { memo } from 'react'
import { Position } from 'reactflow'

import { FALLBACK_MESSAGE_1, FALLBACK_MESSAGE_2 } from '../../constants'
import { FallbackNodeProps } from '../../types'
import { Icon } from '../base'
import { NodeHandleType } from './constants'
import { HandleType } from './handle-styles'
import { Condition, NodeHeader, StyledHandle } from './node-components'
import { Border, MessageNode, StyledList } from './node-styles'

const FallbackNode = ({
  data,
  selected,
  id,
}: FallbackNodeProps): JSX.Element => {
  return (
    <MessageNode $isSelected={selected}>
      <NodeHeader
        data={data}
        title={'Fallback messages'}
        icon={Icon.LIFE_RING}
      ></NodeHeader>
      <StyledHandle
        id={id}
        handleType={HandleType.TARGET}
        type={NodeHandleType.Target}
        position={Position.Left}
        isConnectable={false}
        isConnectableWithBotAction={false}
      />

      <StyledList $hasMarginTop>
        <Condition
          id={`${id}-${FALLBACK_MESSAGE_1}`}
          handleType={HandleType.FALLBACK1}
          border={null}
        >
          1st message
        </Condition>
        <Condition
          id={`${id}-${FALLBACK_MESSAGE_2}`}
          handleType={HandleType.SMALL_CONDITION}
          border={Border.TOP}
        >
          2nd message
        </Condition>
      </StyledList>
    </MessageNode>
  )
}

export default memo(FallbackNode)
