import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import {
  FALLBACK_FLOW,
  FALLBACK_MESSAGE_1,
  FALLBACK_MESSAGE_2,
  FALLBACK_NODE_ID,
} from '../../../UI/constants'
import { HtFallbackNode } from '../cms/hubtype/fallback'
import { HtContentType } from '../cms/hubtype/node-types'
import { ContentType, TopContentFieldsBase } from '.'
import { ConditionalContentType } from './content-types'

export class FallbackFields extends TopContentFieldsBase {
  constructor() {
    super()
    this.id = FALLBACK_NODE_ID
    this.code = 'Fallback'
    this.flowId = FALLBACK_FLOW.id
  }

  contentType(): ConditionalContentType {
    return ContentType.FALLBACK
  }

  stringContentType(): string {
    return 'fallback'
  }

  static fromHubtypeCMS(component: HtFallbackNode): FallbackFields {
    const newFallback = new FallbackFields()
    newFallback.id = FALLBACK_NODE_ID
    newFallback.code = component.code
    newFallback.flowId = FALLBACK_FLOW.id
    return newFallback
  }

  toHubtypeCMS(position: XYPosition): HtFallbackNode {
    const node: HtFallbackNode = {
      ...this.getHubtypeCmsCommonData(position),
      flow_id: FALLBACK_FLOW.id,
      type: HtContentType.FALLBACK,
      content: {
        first_message: undefined,
        second_message: undefined,
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('content ID')
    if (
      !this.edges.some(edge => edge.sourceHandle?.includes(FALLBACK_MESSAGE_1))
    ) {
      requiredFields.push('1st message')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return this.fieldsIncludeString([this.code], value)
  }

  getConnections(edges: Edge[]): NodeEdges {
    const firstMessage = edges.find(
      e => e.sourceHandle === `${this.id}-${FALLBACK_MESSAGE_1}`
    )
    const secondMessage = edges.find(
      e => e.sourceHandle === `${this.id}-${FALLBACK_MESSAGE_2}`
    )
    return {
      buttons: [firstMessage, secondMessage],
      followUp: undefined,
    }
  }
}
