import cloneDeep from 'lodash.clonedeep'

import {
  ContentType,
  PayloadFields,
} from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NonMessageAction } from './non-message-action'

export interface RemovePayloadsInterface {
  type: ActionType.REMOVE_PAYLOAD
  payloadToRemove: PayloadFields
}

export interface RemovePayloadsHistoryChange {
  type: ActionType.REMOVE_PAYLOAD
  payloadToRemove: PayloadFields
  oldNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class RemovePayloadsAction extends ReversibleAction {
  static apply = (state: State, payloadToRemove: PayloadFields): void => {
    this.trackHistoryChange(state, payloadToRemove)
    NonMessageAction.removePayload(state, payloadToRemove)
    this.removePayloadFormNodes(state, payloadToRemove)
  }

  static undo = (state: State, change: RemovePayloadsHistoryChange): void => {
    NonMessageAction.addPayload(state, change.payloadToRemove)
    state.nodes = change.oldNodes
  }

  static redo = (state: State, change: RemovePayloadsHistoryChange): void => {
    NonMessageAction.removePayload(state, change.payloadToRemove)
    this.removePayloadFormNodes(state, change.payloadToRemove)
  }

  private static trackHistoryChange = (
    state: State,
    payloadToRemove: PayloadFields
  ) => {
    const newChange: RemovePayloadsHistoryChange = {
      type: ActionType.REMOVE_PAYLOAD,
      payloadToRemove,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
      oldNodes: state.nodes,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static removePayloadFormNodes = (
    state: State,
    payloadToRemove: PayloadFields
  ) => {
    state.nodes = cloneDeep(state.nodes).map(node => {
      if (
        node.type === ContentType.BOT_ACTION &&
        node.data.payloadId === payloadToRemove.id
      ) {
        node.data.payloadId = undefined
      }
      return node
    })
  }
}
