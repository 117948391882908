import { Item } from 'react-stately'

import { DropdownData } from '../common/dropdown/types'
import {
  MultiSelectMenu,
  MultiSelectMenuProps,
} from './widgets/multiselect-menu'

interface HtMultiSelectDropdownProps<T extends DropdownData>
  extends Omit<MultiSelectMenuProps<T>, 'children'> {
  data: T[]
}

export const HtMultiSelectDropdown = <T extends DropdownData>(
  props: HtMultiSelectDropdownProps<T>
): JSX.Element => {
  return (
    <MultiSelectMenu
      {...props}
      items={props.data}
      selectionMode='multiple'
      aria-label={props.label}
    >
      {item => <Item textValue={item.name || item.id}>{item.name}</Item>}
    </MultiSelectMenu>
  )
}
