import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { useMenuItem } from 'react-aria'
import { TreeState } from 'react-stately'

import { StyledListItem } from '../../common/dropdown/styles'

interface MenuItemProps<T extends object> {
  state: TreeState<T>
  item: Node<T>
}

export const MenuItem = <T extends object>({
  state,
  item,
}: MenuItemProps<T>): JSX.Element => {
  const ref = useRef(null)
  const { menuItemProps, isSelected } = useMenuItem(
    { key: item.key },
    state,
    ref
  )

  return (
    <StyledListItem {...menuItemProps} ref={ref} aria-selected={isSelected}>
      {item.rendered}
      {isSelected}
    </StyledListItem>
  )
}
