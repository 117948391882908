import { FeedbackMessage, FeedbackType } from '../../../domain/feedback'
import { Locale } from '../../../domain/models/locales/locale'
import { TrackEventName, useAnalytics } from '../../analytics'
import { MAIN_FLOW, START_NODE_ID } from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ViewportAnimator } from '../../utils/viewport-animator'
import { HtNotification } from '../base'
import { AiNotificationsPanel } from './ai-notifications-panel'
import {
  FeedbackContainer,
  FeedbackMessages,
  FeedbackText,
  RemoveFeedbackButton,
  UnderlinedFeedbackText,
} from './feedback-panel-styles'

export const FeedbackPanel = (): JSX.Element => {
  const analytics = useAnalytics()
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const { removeFeedbackMessages, selectNode, selectLocale, selectFlow } =
    useFlowBuilderSelector(ctx => ctx)

  const selectAndCenterNode = (id?: string): void => {
    if (!id) return
    if (id === START_NODE_ID) {
      selectFlow(MAIN_FLOW.id)
    }
    analytics.trackEvent(TrackEventName.CLICK_LOCAL_ERROR_CTA)
    ViewportAnimator.centerNode(state, id, selectNode)
  }

  const goToLocale = (locale?: Locale): void => {
    if (!locale) return
    removeFeedbackMessages()
    selectLocale(locale)
  }

  const getMessage = (feedbackMessage: FeedbackMessage) => {
    const isNodeError = state.nodes.some(
      node => node.id === feedbackMessage.details?.nodeId
    )
    const localeError = state.locales.find(
      locale => locale.code === feedbackMessage.details?.localeCode
    )
    return (
      <FeedbackText>
        {feedbackMessage.message}{' '}
        {isNodeError && (
          <UnderlinedFeedbackText
            onClick={() => selectAndCenterNode(feedbackMessage.details?.nodeId)}
          >
            Locate error
          </UnderlinedFeedbackText>
        )}
        {localeError && (
          <UnderlinedFeedbackText onClick={() => goToLocale(localeError)}>
            Go to language
          </UnderlinedFeedbackText>
        )}
      </FeedbackText>
    )
  }

  return (
    <FeedbackContainer $isEditorOpen={!!state.currentNode}>
      <AiNotificationsPanel />
      <FeedbackMessages>
        {state.feedback
          .filter(feedback => feedback.type !== FeedbackType.CUSTOM)
          .map(feedback => (
            <HtNotification
              key={feedback.id}
              type={feedback.type}
              isAi={feedback.details?.isAiNotification}
              onClose={() => removeFeedbackMessages([feedback])}
            >
              {getMessage(feedback)}
            </HtNotification>
          ))}
      </FeedbackMessages>
      {state.feedback.length > 1 && (
        <RemoveFeedbackButton onClick={() => removeFeedbackMessages()}>
          Hide all
        </RemoveFeedbackButton>
      )}
    </FeedbackContainer>
  )
}
