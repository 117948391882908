import { Locale } from '../../../domain/models/locales/locale'
import {
  BOTONIC_STATE_STORAGE_KEY,
  FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY,
} from '../../constants'
import { FlowBuilderPreviewState } from '../../types'

export const parsePreviewStateForBotId = ({
  previewStateStorageValue,
  botId,
}: {
  previewStateStorageValue: string
  botId?: string
}): FlowBuilderPreviewState | undefined => {
  if (!botId) return undefined
  const parsedStoragePreviewState = JSON.parse(previewStateStorageValue)
  return parsedStoragePreviewState[botId]
}
/**
 * When settings flow builder's previewState from Flow Builder App, we save the information into window.localStorage (parent window)
 */
export const setFBAppStoragePreviewState = (
  botId: string | undefined,
  {
    hasFlowErrors,
    current,
    locales,
  }: { hasFlowErrors: boolean; current: Locale; locales: Locale[] }
): void => {
  if (botId) {
    window.localStorage.setItem(
      FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY,
      JSON.stringify({
        [botId]: {
          hasFlowErrors: hasFlowErrors,
          current: current.code,
          locales: locales.map(locale => locale.code),
        },
      })
    )
  }
}

/**
 * When reading flow builder's previewState from Test Webchat App, as it is executed in a new window, we have to access parent's localStorage through window.opener
 * Ref.: https://developer.mozilla.org/en-US/docs/Web/API/Window/opener
 */
export const getStoragePreviewStateFromFBApp = (): string | null =>
  window.opener.localStorage.getItem(FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY)

export const cleanLocalStoragePreservingPreviewState = () => {
  const storagePreviewState = getStoragePreviewStateFromFBApp()
  window.opener.localStorage.removeItem(FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY)
  window.opener.localStorage.removeItem(BOTONIC_STATE_STORAGE_KEY)
  if (storagePreviewState) {
    window.localStorage.setItem(
      FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY,
      storagePreviewState
    )
  }
}

export const updateLocalStoragePreviewStateForBotId = (
  botId: string,
  args: Partial<FlowBuilderPreviewState>
) => {
  const storagePreviewState = getStoragePreviewStateFromFBApp()
  const parsedPreviewState =
    storagePreviewState && JSON.parse(storagePreviewState)
  const parsedPreviewStateForBotId = parsedPreviewState?.[botId]
  parsedPreviewStateForBotId &&
    window.opener.localStorage.setItem(
      FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY,
      JSON.stringify({
        [botId]: {
          ...parsedPreviewStateForBotId,
          ...args,
        },
      })
    )
}
