import { StartFields } from '../../../../domain/models/content-fields'
import { MAIN_FLOW } from '../../../constants'
import { HtIcon, Icon, Size, TextBodySmallBold } from '../../base'
import { NodeHeaderCode } from '../node-components'
import { StyledStartNodeHeader } from './start-node-styles'

export interface StartNodeHeaderProps {
  currentFlowId: string
  data: StartFields
}
export const StartNodeHeader = ({
  data,
  currentFlowId,
}: StartNodeHeaderProps) => {
  return (
    <StyledStartNodeHeader>
      <NodeHeaderCode data={data} hideCode={true} />

      {currentFlowId === MAIN_FLOW.id && (
        <HtIcon size={Size.LARGE} icon={Icon.HOUSE} />
      )}
      <TextBodySmallBold>
        {currentFlowId === MAIN_FLOW.id ? 'Conversation start' : 'Flow start'}
      </TextBodySmallBold>
    </StyledStartNodeHeader>
  )
}
