import styled from 'styled-components'

import { COLORS, TextBodySmall, textSmall } from '../base'

export const FeedbackContainer = styled.div<{ $isEditorOpen: boolean }>`
  position: absolute;
  top: 62px;
  right: ${props => (props.$isEditorOpen ? '304px' : '16px')};
  width: 303px;
  max-height: 450px;
  z-index: 25;
  display: flex;
  flex-direction: column;
  gap: 8px;
  user-select: none;
  -webkit-user-select: none;
`

export const FeedbackMessages = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const FeedbackText = styled(TextBodySmall)`
  width: 100%;
  max-height: 87px;
  overflow-y: auto;
`

export const UnderlinedFeedbackText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
`

export const RemoveFeedbackButton = styled.button`
  position: absolute;
  bottom: -42px;
  ${textSmall};
  background-color: ${COLORS.N900};
  color: ${COLORS.WHITE};
  align-self: flex-end;
  padding: 8px 12px;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  &:hover {
    background-color: ${COLORS.N800};
  }
`
