import { CountryInfo } from '../../../domain/models/content-fields'
import { LanguageInfo } from '../../../domain/models/locales/language'
import { TrackEventName, useAnalytics } from '../../analytics'
import { HtBtnType, HtButton, HtIcon, Icon } from '../../components/base'
import { PreviewUrlParams } from '../../types'
import { useRefreshCount } from '../hooks'
import { cleanLocalStoragePreservingPreviewState } from '../utils'

interface StartOverButtonProps {
  isShared: boolean
  selectedLanguage: LanguageInfo
  selectedCountry: CountryInfo
}

export const StartOverButton = ({
  isShared,
  selectedLanguage,
  selectedCountry,
}: StartOverButtonProps) => {
  const analytics = useAnalytics()
  const { refreshCount, urlWithRefreshCount } = useRefreshCount()

  const handleStartOver = () => {
    !isShared && cleanLocalStoragePreservingPreviewState()
    analytics.trackEvent(TrackEventName.PREVIEW_WINDOW_REFRESH, {
      number_of_refresh: refreshCount,
    })
    if (!isShared) {
      window.location.href = urlWithRefreshCount
    } else {
      const parsedUrlWithUpdatedParams = new URL(urlWithRefreshCount)

      parsedUrlWithUpdatedParams.searchParams.set(
        PreviewUrlParams.CURRENT_LOCALE,
        selectedLanguage.id
      )
      parsedUrlWithUpdatedParams.searchParams.set(
        PreviewUrlParams.CURRENT_COUNTRY,
        selectedCountry.id
      )

      window.location.href = parsedUrlWithUpdatedParams.toString()
    }
  }

  return (
    <HtButton type={HtBtnType.TERTIARY} onClick={handleStartOver}>
      <HtIcon icon={Icon.ROTATE_RIGHT} />
    </HtButton>
  )
}
