import { ContentType } from '../../domain/models/content-fields'
import { HtIcon, Icon, Size } from '../../UI/components/base'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { Node } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'

export const WebviewImageBuildNode = () => {
  return (
    <Node
      onDragStart={event => onDragStart(event, ContentType.IMAGE)}
      draggable
    >
      <BuildComponentTooltip
        title='Image'
        tooltipText='Webview image (jpg, png or gif)'
      />
      <HtIcon icon={Icon.IMAGE} size={Size.LARGE} />
    </Node>
  )
}
