import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { TextBodyBold } from '../typography'

export const StyledBanner = styled.div<{
  $intent: 'primary' | 'info' | 'warning' | 'error' | 'neutral'
}>`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
  color: ${COLORS.N900};
  ${props => props.$intent === 'primary' && primaryStyles};
  ${props => props.$intent === 'info' && infoStyles};
  ${props => props.$intent === 'warning' && warningStyles};
  ${props => props.$intent === 'error' && errorStyles};
  ${props => props.$intent === 'neutral' && neutralStyles};
`

export const Title = styled(TextBodyBold)`
  svg {
    width: 16px;
  }
`

const primaryStyles = css`
  background-color: ${COLORS.L50};
  border: 1px solid ${COLORS.L300};

  svg {
    color: ${COLORS.L500} !important;
  }
`

const infoStyles = css`
  background-color: ${COLORS.A50};
  border: 1px solid ${COLORS.A300};

  svg {
    color: ${COLORS.A600} !important;
  }
`

const warningStyles = css`
  background-color: ${COLORS.O50};
  border: 1px solid ${COLORS.O300};

  svg {
    color: ${COLORS.O500} !important;
  }
`

const errorStyles = css`
  background-color: ${COLORS.R50};
  border: 1px solid ${COLORS.R300};

  svg {
    color: ${COLORS.R500} !important;
  }
`

const neutralStyles = css`
  background-color: ${COLORS.N50};
  border: 1px solid ${COLORS.N200};

  svg {
    color: ${COLORS.N300} !important;
  }
`

const withTitle = css`
  flex-direction: column;
  gap: 4px;
  svg {
    margin-right: 4px;
  }
`

export const Content = styled.div<{ $hasTitle: boolean }>`
  display: flex;
  align-items: start;
  gap: 8px;
  ${props => props.$hasTitle && withTitle};
`
