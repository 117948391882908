import { TrackEventName, useAnalytics } from '../../../../analytics'
import { ADDONS_DETAIL_URL_PATH } from '../../../../constants'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { Popup } from '../../../popup/popup'

export const KnowledgeBaseAddonsPopup = (): JSX.Element => {
  const analytics = useAnalytics()

  const isSaved = useFlowBuilderSelector(ctx => ctx.state.isFlowSaved)

  const goToHubtypeKnowledgeBaseAddonModal = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.ADD_ONS, {
      origin: 'Knowledge base',
    })
    const closeData = `${ADDONS_DETAIL_URL_PATH}/knowledge-bases`
    if (isSaved) {
      postCloseFromFlowBuilderMessage(closeData)
      return
    }
  }

  return (
    <Popup
      title={PopupType.KNOWLEDGE_BASE}
      message='Leverage on GenAI to generate more human responses to your user inquiries with the information you already have about your company.'
      confirmButton={{
        text: 'More info',
        onClick: goToHubtypeKnowledgeBaseAddonModal,
      }}
    />
  )
}
