import { NodeTypes, NonMessageContents, State } from '../types'
import { Action, ActionType } from './action-types'
import { ConnectNodesAction } from './actions/edge-actions'
import {
  AddFlowAction,
  RemoveFlowAction,
  SelectFlowAction,
  SetFlowsAction,
} from './actions/flow-actions'
import {
  CopyAction,
  CutAction,
  DropNodeAction,
  NodeDragStartAction,
  PasteAction,
  RemoveNodesAction,
  SelectNodeAction,
  SetSelectedNodesAction,
  UpdateAllNodesAction,
  UpdateNodeAction,
  UpdateNodesPositionAction,
} from './actions/node-actions'
import { NodeAction } from './actions/node-actions/node-action'
import { RedoAction } from './actions/redo'
import { UndoAction } from './actions/undo'

// eslint-disable-next-line complexity
export function nodeReducer(draftState: State, action: Action): void {
  switch (action.type) {
    case ActionType.CONNECT_NODES:
      ConnectNodesAction.apply(draftState, action.connection)
      break

    case ActionType.NODE_DRAG_START:
      NodeDragStartAction.apply(draftState, action)
      break

    case ActionType.NODE_DRAG_STOP:
      UpdateNodesPositionAction.apply(draftState, action.nodesToReposition)
      break

    case ActionType.NODE_DROP:
      DropNodeAction.apply(draftState, action.event)
      break

    case ActionType.REMOVE_NODES: {
      RemoveNodesAction.apply(draftState, action.nodesToRemove)
      break
    }

    case ActionType.SELECT_FLOW: {
      SelectFlowAction.apply(draftState, action)
      break
    }

    case ActionType.SET_FLOWS: {
      SetFlowsAction.apply(draftState, action)
      break
    }

    case ActionType.ADD_FLOW:
      AddFlowAction.apply(draftState, action)
      break

    case ActionType.REMOVE_FLOW: {
      RemoveFlowAction.apply(draftState, action)
      break
    }

    case ActionType.SELECT_NODE:
      SelectNodeAction.apply(draftState, action)
      break

    case ActionType.SET_POPUP_CONTENT:
      draftState.popupContent = action.content
      break

    case ActionType.SET_SELECTED_NODES:
      SetSelectedNodesAction.apply(draftState, action)
      break

    case ActionType.UPDATE_ALL_CONTENTS:
      draftState = updateAllContents(
        draftState,
        action.nodes,
        action.nonMessageContents
      )
      break

    case ActionType.UPDATE_NODE:
      UpdateNodeAction.apply(draftState, action.data)
      break

    case ActionType.COPY_ELEMENTS:
      CopyAction.apply(draftState, action)
      break

    case ActionType.CUT_ELEMENTS:
      CutAction.apply(draftState, action)
      break

    case ActionType.PASTE_ELEMENTS:
      PasteAction.apply(draftState, action)
      break

    case ActionType.UNDO:
      UndoAction.apply(draftState)
      break

    case ActionType.REDO:
      RedoAction.apply(draftState)
      break

    case ActionType.UPDATE_ALL_NODES:
      UpdateAllNodesAction.apply(draftState, action)
      break
  }
}

const updateAllContents = (
  state: State,
  nodes?: NodeTypes[],
  nonMessageContents?: NonMessageContents
): State => {
  if (nodes) {
    state.nodes = nodes
    state.currentNode = nodes?.find(n => n.id === state.currentNode?.id)
    NodeAction.reportRepeatedContentIds(state)
  }
  if (nonMessageContents) state.nonMessageContents = nonMessageContents
  return state
}
