import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'

export interface NodeDragStartInterface {
  type: ActionType.NODE_DRAG_START
  nodes: NodeTypes[]
}

export class NodeDragStartAction {
  static apply = (state: State, { nodes }: NodeDragStartInterface): void => {
    if (nodes.length === 1) {
      state.currentNode = nodes[0]
    }
  }
}
