import styled from 'styled-components'

import { COLORS } from '../base'
import { textBodySmall, textSmall } from '../base/typography'

export const SearchBarContainer = styled.div`
  box-sizing: border-box;
  height: 45px;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${COLORS.N500};
  display: flex;
  align-items: center;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.N900};
`

export const ResultContainer = styled.div`
  ${textSmall}
  color: ${COLORS.WHITE};
  width: 110px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SearchBarInput = styled.input`
  ${textBodySmall}
  width: 172px;
  height: 15px;
  background-color: ${COLORS.N900};
  color: ${COLORS.WHITE};
  border: none;
  outline: none;
  ::placeholder {
    color: ${COLORS.N300};
  }
`
