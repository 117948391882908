import { Key, memo } from 'react'

import { OrganizationAiIntent } from '../../../../../domain/models/organization-models'
import { HtChip, HtMultiSelectDropdown } from '../../../base'
import { InputAction } from '../../constants'
import { FullWidthContainer, KeywordsContainer } from '../../editor-styles'

interface IntentsDropdownProps {
  field: InputAction
  values: string[]
  intents: OrganizationAiIntent[]
  readOnly: boolean
  onChange: (action: any) => void
}

export const IntentsDropdownField = memo(
  (props: IntentsDropdownProps): JSX.Element => {
    const onApply = (keys: Key[]) => {
      props.onChange({
        type: props.field.actionType,
        fieldKey: props.field.key,
        value: keys.map(key => props.intents.find(i => i.id === key)?.name),
      })
    }

    const handleDelete = (item: string) => {
      props.onChange({
        type: props.field.actionType,
        fieldKey: props.field.key,
        value: props.values.filter(v => v !== item),
      })
    }

    return (
      <FullWidthContainer>
        <HtMultiSelectDropdown
          data={props.intents}
          label={props.field.label!}
          readOnly={props.readOnly}
          defaultSelectedKeys={props.values.reduce((acc: string[], key) => {
            const orgIntent = props.intents.find(i => i.name === key)
            if (orgIntent) acc.push(orgIntent.id)
            return acc
          }, [])}
          onApply={onApply}
          applyButtonText='Add intents'
          placeholder={props.field.placeholder}
        />
        <KeywordsContainer>
          {props.values.map((item, index) => (
            <HtChip
              key={index}
              label={item}
              disabled={props.readOnly}
              onDelete={() => handleDelete(item)}
            />
          ))}
        </KeywordsContainer>
      </FullWidthContainer>
    )
  }
)
