import { CountryInfo } from '../content-fields/country'
import { Country } from './country'
import { CountryCode } from './country-code'

export function countryCodesToCountryInfo(
  codes: typeof CountryCode
): CountryInfo[] {
  return Object.values(codes).map(code => ({
    id: code,
    name: Country[code as keyof typeof CountryCode],
  }))
}
