import { Size } from '../variants'
import { StyledCircularLoader } from './ht-styled-loader'

export interface HtLoaderProps {
  size: Size
}

export const HtLoader = ({
  size = Size.MEDIUM,
}: Partial<HtLoaderProps>): JSX.Element => {
  return (
    <StyledCircularLoader $size={size}>
      <svg viewBox='25 25 50 50'>
        <circle cx='50' cy='50' r='20'></circle>
      </svg>
    </StyledCircularLoader>
  )
}
