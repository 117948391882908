import deepClone from 'lodash.clonedeep'
import { XYPosition } from 'reactflow'

import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NodeAction } from './node-action'

export interface UpdateNodesPositionInterface {
  type: ActionType.NODE_DRAG_STOP
  nodesToReposition: NodeTypes[]
}

export interface UpdateNodesPositionHistoryChange {
  type: ActionType.NODE_DRAG_STOP
  newNodes: NodeTypes[]
  oldNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class UpdateNodesPositionAction extends ReversibleAction {
  static apply = (state: State, nodesToReposition: NodeTypes[]): void => {
    if (this.havePositionsChanged(state, nodesToReposition)) {
      const oldNodes = this.updateNodesPosition(state, nodesToReposition)
      this.trackHistoryChange(state, nodesToReposition, oldNodes)
    }
  }

  static undo = (state: State, change: UpdateNodesPositionHistoryChange) => {
    this.updateNodesPosition(state, change.oldNodes)
  }

  static redo = (state: State, change: UpdateNodesPositionHistoryChange) => {
    this.updateNodesPosition(state, change.newNodes)
  }

  private static trackHistoryChange = (
    state: State,
    newNodes: NodeTypes[],
    oldNodes: NodeTypes[]
  ) => {
    const newChange: UpdateNodesPositionHistoryChange = {
      type: ActionType.NODE_DRAG_STOP,
      newNodes,
      oldNodes,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static updateNodesPosition = (
    state: State,
    nodesToReposition: NodeTypes[]
  ): NodeTypes[] => {
    const oldNodes: NodeTypes[] = []
    state.nodes = state.nodes.map(node => {
      const newPosition = this.getNewPosition(nodesToReposition, node)
      if (newPosition) {
        oldNodes.push(deepClone(node))
        node.position = newPosition
        node.selected = true
      } else {
        node.selected = false
      }
      return node
    })
    return oldNodes
  }

  private static getNewPosition = (
    nodesToReposition: NodeTypes[],
    node: NodeTypes
  ): XYPosition | undefined => {
    return nodesToReposition.find(
      nodeToReposition => nodeToReposition.id === node.id
    )?.position
  }

  private static havePositionsChanged(
    state: State,
    nodesToReposition: NodeTypes[]
  ): boolean {
    return nodesToReposition.some(nodeToReposition => {
      const originalNode = NodeAction.getNodeById(state, nodeToReposition.id)
      return (
        originalNode?.position.x !== nodeToReposition.position.x ||
        originalNode?.position.y !== nodeToReposition.position.y
      )
    })
  }
}
