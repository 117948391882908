import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { Icon } from '../ht-icon/icons-list'
import { Size } from '../variants'

export const StyledIcon = styled(FontAwesomeIcon)<{ $size: Size }>`
  cursor: ${props => !!props.onClick && 'pointer'};

  ${props => props.$size === Size.LARGE && iconLargeSize};
  ${props => props.$size === Size.MEDIUM && iconMediumSize};
  ${props => props.$size === Size.SMALL && iconSmallSize};
  ${props => props.$size === Size.TINY && iconTinySize};
  ${props => props.$size === Size.MICRO && iconMicroSize};

  &[data-icon=${Icon.CIRCLE_XMARK}] {
    color: ${COLORS.R400};
  }
  &[data-icon=${Icon.CIRCLE_INFO}] {
    color: ${COLORS.WHITE};
  }
  &[data-icon=${Icon.CIRCLE_CHECK}] {
    color: ${COLORS.G400};
  }
`

export const iconLargeSize = css`
  font-size: 18px;
`

export const iconMediumSize = css`
  font-size: 16px;
`

export const iconSmallSize = css`
  font-size: 14px;
`

export const iconTinySize = css`
  font-size: 12px;
`

export const iconMicroSize = css`
  font-size: 10px;
`
