import { CountryInfo } from '../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../analytics'
import {
  HtBtnType,
  HtSelectDropdown,
  TextBodySmall,
} from '../../components/base'

interface SelectCountryDropdownProps {
  selectedCountry: CountryInfo
  countries: CountryInfo[]
  onSelect: (country: CountryInfo) => void
}
export const SelectCountryDropdown = ({
  selectedCountry,
  countries,
  onSelect,
}: SelectCountryDropdownProps) => {
  const analytics = useAnalytics()
  const formatCountry = (country: CountryInfo) => {
    return `${country.name} (${country.id})`
  }

  const onCountryChange = (country: CountryInfo | undefined) => {
    if (country) {
      onSelect(country)
      analytics.trackEvent(TrackEventName.PREVIEW_WINDOW_COUNTRY, {
        country: `${country.id} - ${country.name}`,
      })
    }
  }

  return (
    <HtSelectDropdown
      btnProps={{ type: HtBtnType.TERTIARY, width: '100%' }}
      label={formatCountry(selectedCountry)}
      hideLabel={true}
      data={countries}
      onChange={country => country && onCountryChange(country)}
    >
      {country => <TextBodySmall>{formatCountry(country)}</TextBodySmall>}
    </HtSelectDropdown>
  )
}
