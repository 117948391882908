import { Locale } from '../../../domain/models/locales/locale'
import { useFeedbackMessage } from '../../custom-hooks/use-feedback-message'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupType } from '../../types'
import { Popup } from '../popup/popup'

interface DeleteLocalesPopupProps {
  localesToRemove: Locale[]
}
export const DeleteLocalesPopup = ({
  localesToRemove,
}: DeleteLocalesPopupProps): JSX.Element => {
  const { reportSuccess } = useFeedbackMessage()
  const { setPopupContent, removeLocales } = useFlowBuilderSelector(ctx => ctx)

  const deleteLocales = (): void => {
    setPopupContent()
    removeLocales(localesToRemove)
    reportSuccess('Languages removed')
  }

  return (
    <Popup
      title={PopupType.REMOVE_LOCALES}
      confirmButton={{ text: 'Remove languages', onClick: deleteLocales }}
      canCloseOnClickOutside={false}
      message={
        'You’re about to remove one or more languages. Please note that all content will be deleted permanently. This action can’t be undone.'
      }
    />
  )
}
