import { LocaleCode } from '../../../domain/models/locales/code'
import { Locale } from '../../../domain/models/locales/locale'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import {
  HtBtnType,
  HtSelectDropdown,
  Placement,
  Size,
  TextSmall,
} from '../base'
import { CopyFromContainer } from './locale-item-styles'

interface LocaleItemProps {
  currentCopyFrom?: LocaleCode
  changeCopyFrom: (copyFrom?: LocaleCode) => void
}

type None = 'none'
const NO_COPY_FROM = 'none' as None

export const CopyFromDropdown = ({
  currentCopyFrom,
  changeCopyFrom,
}: LocaleItemProps): JSX.Element => {
  const stateLocales = useFlowBuilderSelector(ctx => ctx.state.locales)

  const onChange = (value?: Locale | { id: None }) => {
    if (!value) return
    if (value.id === NO_COPY_FROM) changeCopyFrom(undefined)
    else changeCopyFrom(value.code)
  }

  const getItemText = (value: Locale | { id: None }) => {
    if (value.id === NO_COPY_FROM) return value.id
    return `${value.language} (${value.code})`
  }

  return (
    <CopyFromContainer>
      <TextSmall>Copy from</TextSmall>
      <HtSelectDropdown
        label='Copy from'
        data={[{ id: NO_COPY_FROM }, ...stateLocales]}
        selectedKey={currentCopyFrom || NO_COPY_FROM}
        btnProps={{
          type: HtBtnType.BORDERLESS,
          size: Size.TINY,
          width: 'fit-content',
        }}
        listProps={{ listWidth: '200px', listPlacement: Placement.BOTTOM_END }}
        hideLabel={true}
        onChange={onChange}
        renderButtonAsItem={true}
      >
        {getItemText}
      </HtSelectDropdown>
    </CopyFromContainer>
  )
}
