import { memo } from 'react'

import { TrackEventName, useAnalytics } from '../../analytics'
import { useUndoRedo } from '../../custom-hooks'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { HtIcon, Icon } from '../base'
import {
  StyledControlButton,
  UndoRedoControlsContainer,
} from './flow-controls-style'

export const UndoRedoControls = memo(() => {
  const analytics = useAnalytics()
  const { historyIndex, changeHistory, currentNode } = useFlowBuilderSelector(
    ctx => ctx.state
  )
  const { undo, redo } = useFlowBuilderSelector(ctx => ctx)

  const onUndo = (hasClickedButton?: boolean) => {
    if (changeHistory.length === 0 || historyIndex < 0) return
    analytics.trackEvent(TrackEventName.UNDO, {
      origin: getOrigin(hasClickedButton),
      consecutive_undo_count: changeHistory.length - historyIndex,
    })
    undo()
  }

  const onRedo = (hasClickedButton?: boolean) => {
    if (historyIndex + 1 === changeHistory.length) return
    analytics.trackEvent(TrackEventName.REDO, {
      origin: getOrigin(hasClickedButton),
    })
    redo()
  }

  const getOrigin = (hasClickedButton?: boolean) => {
    return hasClickedButton ? 'controls' : 'keyboard'
  }

  useUndoRedo(onUndo, onRedo)

  return (
    <UndoRedoControlsContainer $isEditorOpen={!!currentNode}>
      <StyledControlButton
        onClick={() => onUndo(true)}
        disabled={historyIndex < 0}
      >
        <HtIcon icon={Icon.ROTATE_LEFT} />
      </StyledControlButton>
      <StyledControlButton
        onClick={() => onRedo(true)}
        disabled={historyIndex + 1 === changeHistory.length}
      >
        <HtIcon icon={Icon.ROTATE_RIGHT} />
      </StyledControlButton>
    </UndoRedoControlsContainer>
  )
})
