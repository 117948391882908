import { HtFlow } from '../../domain/models/cms/hubtype/flow'
import { ContentType } from '../../domain/models/content-fields'
import { State } from '../../UI/types'
import { isWebview } from '../../webviews/utils'

export class HtFlowFactory {
  static getFlows(state: State): HtFlow[] {
    return state.flows.reduce((acc: HtFlow[], flow) => {
      const startNode = state.nodes.find(
        node => node.type === ContentType.START
      )
      const flowStartNodeId = startNode?.data.edges.find(edge =>
        edge.sourceHandle?.includes(`start|${flow.id}`)
      )?.target
      if (!isWebview(flow.id)) {
        acc.push({ ...flow, start_node_id: flowStartNodeId })
      }
      return acc
    }, [])
  }
}
