import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import buildVersion from './UI/assets/build-info.json'
import { FlowBuilderApp } from './UI/flow-builder-app'
import { TestWebchatApp } from './UI/test-webchat-app'

const enableSentry = process.env.REACT_APP_ENABLE_SENTRY
if (enableSentry) {
  Sentry.init({
    // the ENVIRONMENT_NAME must match with values in deploy.py
    environment: process.env.REACT_APP_ENVIRONMENT_NAME,
    release: `${process.env.REACT_APP_ENVIRONMENT_NAME}-${buildVersion.version}`,
    dsn: 'https://5e35cf5f4cfefe6d01e45e7ce6fe9172@o368614.ingest.sentry.io/4506586942734336',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'hubtype.com'],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
} else {
  console.log('Sentry is disabled by configuration')
}
const rootElement = document.getElementById('root')

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<FlowBuilderApp />} />
          <Route path='test-webchat' element={<TestWebchatApp />} />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  )
}
