import axios from 'axios'

export class HubtypeApi {
  static async get(
    authToken: string,
    endpoint: string,
    version = 'v1',
    params?: any
  ): Promise<any | undefined> {
    const hubtypeApiUrl = HubtypeApi.getHubtypeApiUrl()
    const url = `${hubtypeApiUrl}/${version}/${endpoint}`
    try {
      const response = await axios.get(url, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${authToken}`,
        },
        params,
      })
      return response.data
    } catch (error) {
      console.log('error', error)
    }
    return undefined
  }

  static getHubtypeApiUrl(): string {
    if (!process.env.REACT_APP_HUBTYPE_API_URL) {
      throw new Error('You need to set env var REACT_APP_HUBTYPE_API_URL')
    }
    return process.env.REACT_APP_HUBTYPE_API_URL
  }
}
