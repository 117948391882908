import { memo } from 'react'

import { HtIcon, HtSwitch, Icon, Size, TextSmallExtraBold } from '../../base'
import { MEANINGFUL_ELEMENT } from '../constants'
import { MeaningfulElementContainer } from '../editor-styles'

interface MeaningfulElementProps {
  isMeaningful: boolean
  readOnly?: boolean
  onChange: (action: any) => void
}

export const MeaningfulElement = memo((props: MeaningfulElementProps) => {
  const onCheckboxChange = (isSelected: boolean) => {
    props.onChange({
      type: MEANINGFUL_ELEMENT.actionType,
      fieldKey: MEANINGFUL_ELEMENT.key,
      value: isSelected,
    })
  }
  return (
    <MeaningfulElementContainer>
      <TextSmallExtraBold>
        <HtIcon icon={Icon.CHART_SIMPLE} />
        Advanced Analytics
      </TextSmallExtraBold>
      <HtSwitch
        size={Size.SMALL}
        isReadOnly={props.readOnly}
        isSelected={props.isMeaningful}
        helperText={MEANINGFUL_ELEMENT.helperText}
        onChange={onCheckboxChange}
      >
        {MEANINGFUL_ELEMENT.label}
      </HtSwitch>
    </MeaningfulElementContainer>
  )
})
