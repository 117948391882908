import { memo } from 'react'

import { ImageFields } from '../../../../../domain/models/content-fields'
import { TextBodyBold } from '../../../base'
import { IMAGE } from '../../constants'
import { ContentImageField } from '../../editor-widgets/image-loader-widget'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'

interface ImageEditorProps extends EditorProps {
  nodeContent: ImageFields
}

export const ImageEditor = memo(
  ({ nodeContent, ...props }: ImageEditorProps): JSX.Element => {
    return (
      <>
        <TextBodyBold>Image</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <ContentImageField
          {...props}
          field={IMAGE}
          image={nodeContent.image?.assetUrl}
        />
      </>
    )
  }
)
