import { memo } from 'react'

import { HtIcon } from '../ht-icon/ht-icons'
import { Icon } from '../ht-icon/icons-list'
import { TextSmallExtra } from '../typography'
import { Size } from '../variants'
import { StyledBadge } from './ht-badge-styles'

export interface HtBadgeProps {
  children: string
  icon?: Icon
}

export const HtBadge = memo(({ children, icon }: HtBadgeProps): JSX.Element => {
  return (
    <StyledBadge>
      {icon && <HtIcon icon={icon} size={Size.MICRO} />}
      <TextSmallExtra>{children}</TextSmallExtra>
    </StyledBadge>
  )
})
