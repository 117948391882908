import { ContentType } from '../../domain/models/content-fields'
import { HtIcon, Icon, Size } from '../../UI/components/base'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { ConditionalNode } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'

export const CustomConditionBuildNode = () => {
  return (
    <ConditionalNode
      onDragStart={event => onDragStart(event, ContentType.CUSTOM_CONDITION)}
      draggable
    >
      <BuildComponentTooltip
        title='Custom'
        tooltipText="Use your own personalized conditions, previously defined in the bot's code."
      />
      <HtIcon icon={Icon.SPLIT} size={Size.LARGE} />
    </ConditionalNode>
  )
}
