import { IconPrefix, library } from '@fortawesome/fontawesome-svg-core'
import {
  faApple,
  faFacebookMessenger,
  faInstagram,
  faTelegram,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'
import {
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faCloudCheck,
  faCloudSlash,
  faFaceSmile,
  faFile,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faImage,
  faLifeRing,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons'
import {
  fa2,
  faArrowProgress,
  faArrowTurnDownRight,
  faBrain,
  faCaretDown,
  faCaretUp,
  faChartSimple,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleExclamation,
  faCircleInfo as faCircleInfoSolid,
  faCircleQuestion,
  faCode,
  faEdit,
  faExpand,
  faFilm,
  faFont,
  faGripDots,
  faHeadset,
  faHouse,
  faLink,
  faListUl,
  faMessages,
  faMinus,
  faPlus,
  faQuoteRight,
  faRectangleVerticalHistory,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faSplit,
  faText,
  faToggleOn,
  faTrashCan,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'
import { CSSProperties } from 'react'

import { ROLES } from '../../../roles'
import { Size } from './../variants'
import { StyledIcon } from './ht-icon-styles'
import { BRAND_ICONS, DUOTONE_ICONS, Icon, REGULAR_ICONS } from './icons-list'

library.add(
  fa2,
  faApple,
  faArrowProgress,
  faArrowTurnDownRight,
  faBrain,
  faCaretDown,
  faCaretUp,
  faChartSimple,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleInfoSolid,
  faCircleQuestion,
  faCircleXmark,
  faCloudCheck,
  faCloudSlash,
  faCode,
  faEdit,
  faExclamationTriangle,
  faExpand,
  faFacebookMessenger,
  faFaceSmile,
  faFile,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFilm,
  faFont,
  faGripDots,
  faHeadset,
  faHouse,
  faImage,
  faInstagram,
  faLifeRing,
  faLink,
  faListUl,
  faMessages,
  faMinus,
  faPlus,
  faQuoteRight,
  faRectangleVerticalHistory,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faSplit,
  faTelegram,
  faText,
  faThumbsDown,
  faThumbsUp,
  faToggleOn,
  faTrashCan,
  faTwitter,
  faWandMagicSparkles,
  faWhatsapp,
  faXmark
)

interface HtIconProps {
  icon: Icon
  prefix?: IconPrefix
  size?: Size
  style?: CSSProperties
  onClick?: (evt: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export const HtIcon = ({
  icon,
  prefix,
  size = Size.SMALL,
  style,
  onClick,
}: HtIconProps): JSX.Element => {
  if (icon === Icon.WEBCHAT) icon = Icon.MESSAGES
  let type: IconPrefix = prefix || 'fas'
  if (!prefix) {
    if (BRAND_ICONS.includes(icon)) type = 'fab'
    if (REGULAR_ICONS.includes(icon)) type = 'far'
    if (DUOTONE_ICONS.includes(icon)) type = 'fad'
  }

  return (
    <StyledIcon
      role={ROLES.IMG}
      aria-label={icon}
      icon={[type, icon]}
      $size={size}
      style={style}
      onClick={onClick}
    />
  )
}
