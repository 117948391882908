import { createContext, FC, ReactNode, useContext } from 'react'

import { CMSEnvironment, CMSReader, CMSWriter } from '../domain/models/cms'
import { HubtypeEnvironment } from './environment/hubtype-environment'
import { HubtypeReader } from './read/hubtype-reader'
import { HubtypeWriter } from './write/hubtype-writer'

export interface Repository {
  cmsReader: CMSReader
  cmsWriter: CMSWriter
  cmsEnvironment: CMSEnvironment
}

export const RepositoryContext = createContext<Repository | undefined>(
  undefined
)

export const RepositoryProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const value = {
    cmsReader: new HubtypeReader(),
    cmsWriter: new HubtypeWriter(),
    cmsEnvironment: new HubtypeEnvironment(),
  }
  return (
    <RepositoryContext.Provider value={value}>
      {children}
    </RepositoryContext.Provider>
  )
}

// TODO: rename and rethink this hook
export const useRepository = () => {
  const result = useContext(RepositoryContext)
  if (!result) {
    throw new Error('RepositoryContext used outside of its Provider!')
  }
  return result
}
