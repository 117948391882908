import { LocaleCode } from '../../../domain/models/locales/code'
import { Locale, NewLocale } from '../../../domain/models/locales/locale'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import { HtCheckbox } from '../base'
import { CopyFromDropdown } from './copy-from-dropdown'
import { LocaleItemContainer, Message } from './locale-item-styles'

interface LocaleItemProps {
  currentLocale: Locale
  localesToAdd: NewLocale[]
  localesToRemove: Locale[]
  setLocalesToAdd: (locales: NewLocale[]) => void
  setLocalesToRemove: (locales: Locale[]) => void
}

export const LocaleItem = ({
  currentLocale,
  localesToAdd,
  localesToRemove,
  setLocalesToAdd,
  setLocalesToRemove,
}: LocaleItemProps): JSX.Element => {
  const stateLocales = useFlowBuilderSelector(ctx => ctx.state.locales)
  const stateCurrentLocale = useFlowBuilderSelector(
    ctx => ctx.state.currentLocale
  )

  const changeLocaleState = (checked: boolean): void => {
    if (checked) addNewLocale()
    else addLocaleToRemove()
  }

  const addNewLocale = (): void => {
    if (!stateLocales.includes(currentLocale)) {
      const newLocale = new NewLocale(currentLocale, stateCurrentLocale.code)
      setLocalesToAdd([...localesToAdd, newLocale])
    } else {
      setLocalesToRemove(localesToRemove.filter(l => l !== currentLocale))
    }
  }

  const addLocaleToRemove = (): void => {
    if (stateLocales.includes(currentLocale)) {
      setLocalesToRemove([...localesToRemove, currentLocale])
    } else {
      setLocalesToAdd(localesToAdd.filter(l => l.locale !== currentLocale))
    }
  }

  const changeCopyFrom = (copyFrom?: LocaleCode): void => {
    const newLocales = localesToAdd.map(newLocale => {
      if (newLocale.locale === currentLocale) {
        newLocale.copyFrom = copyFrom
      }
      return newLocale
    })
    setLocalesToAdd(newLocales)
  }

  const isLocaleChecked = (): boolean =>
    localesToAdd.some(l => l.locale === currentLocale) ||
    (stateLocales.includes(currentLocale) &&
      !localesToRemove.some(l => l === currentLocale))

  const currentNewLocale = localesToAdd.find(
    newLocale => newLocale.locale === currentLocale
  )

  return (
    <LocaleItemContainer role={ROLES.LIST_ITEM} aria-label={currentLocale.code}>
      <HtCheckbox
        checked={isLocaleChecked()}
        disabled={currentLocale === stateCurrentLocale}
        label={currentLocale.getLocaleString()}
        onChange={({ target }) => changeLocaleState(target.checked)}
      />
      {localesToRemove.includes(currentLocale) && (
        <Message $isError={true}>This flow will be deleted</Message>
      )}
      {currentLocale === stateCurrentLocale && (
        <Message>The current language cannot be deleted</Message>
      )}
      {currentNewLocale && (
        <CopyFromDropdown
          currentCopyFrom={currentNewLocale.copyFrom}
          changeCopyFrom={changeCopyFrom}
        />
      )}
    </LocaleItemContainer>
  )
}
