import { memo } from 'react'

import { HtCheckbox } from '../../base'
import { InputAction } from '../constants'

interface FieldsCheckboxProps {
  checked: boolean
  field: InputAction
  readOnly?: boolean
  onChange: (action: any) => void
}

export const FieldsCheckbox = memo((props: FieldsCheckboxProps) => {
  return (
    <HtCheckbox
      checked={props.checked}
      label={props.field.label}
      helperText={props.field.helperText}
      disabled={props.readOnly}
      onChange={({ target }) =>
        props.onChange({
          type: props.field.actionType,
          fieldKey: props.field.key,
          value: target.checked,
        })
      }
    />
  )
})
