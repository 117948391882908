import { PayloadFields } from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NonMessageAction } from './non-message-action'

export interface AddPayloadsInterface {
  type: ActionType.ADD_PAYLOAD
  newPayload: PayloadFields
}

export interface AddPayloadsHistoryChange {
  type: ActionType.ADD_PAYLOAD
  newPayload: PayloadFields
  currentNode?: NodeTypes
  currentFlowId: string
}

export class AddPayloadsAction extends ReversibleAction {
  static apply = (state: State, newPayload: PayloadFields): void => {
    this.trackHistoryChange(state, newPayload)
    NonMessageAction.addPayload(state, newPayload)
  }

  static undo = (state: State, change: AddPayloadsHistoryChange): void => {
    NonMessageAction.removePayload(state, change.newPayload)
  }

  static redo = (state: State, change: AddPayloadsHistoryChange): void => {
    NonMessageAction.addPayload(state, change.newPayload)
  }

  private static trackHistoryChange = (
    state: State,
    newPayload: PayloadFields
  ) => {
    const newChange: AddPayloadsHistoryChange = {
      type: ActionType.ADD_PAYLOAD,
      newPayload,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
