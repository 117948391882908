import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { useOption } from 'react-aria'
import { ListState } from 'react-stately'

import { StyledListItem } from '../../common/dropdown/styles'

interface OptionProps<T extends object> {
  state: ListState<T>
  item: Node<T>
}

export const Option = <T extends object>({
  state,
  item,
}: OptionProps<T>): JSX.Element => {
  const ref = useRef<HTMLLIElement>(null)
  const { optionProps } = useOption({ key: item.key }, state, ref)
  return (
    <StyledListItem {...optionProps} ref={ref}>
      {item.rendered}
    </StyledListItem>
  )
}
