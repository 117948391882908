import { SaveOrigin, TrackEventName, useAnalytics } from '../../../analytics'
import { postCloseFromFlowBuilderMessage } from '../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { LoadingMessage } from '../../../types'
import { useSave } from '../save/use-save'

export const useExit = () => {
  const analytics = useAnalytics()
  const { saveFlow } = useSave()
  const { state, setLoadingMessage } = useFlowBuilderSelector(ctx => ctx)

  const exitFlow = async (closeData?: string): Promise<void> => {
    await analytics.trackEvent(TrackEventName.EXIT, {
      origin: closeData ? 'nav_bar' : 'exit',
    })
    if (!state.isFlowSaved) {
      setLoadingMessage(LoadingMessage.SAVE)
      await saveFlow(SaveOrigin.ON_EXIT)
      setLoadingMessage(undefined)
    }
    postCloseFromFlowBuilderMessage(closeData || null)
  }

  return { exitFlow }
}
