import styled from 'styled-components'

import { COLORS } from '../base'

export const StyledFlowSelector = styled.div`
  button:first-of-type {
    font-weight: 600;
    height: 45px;
    border: 1px solid ${COLORS.N400};
  }
`

export const StyledHeader = styled.div`
  padding: 0px 8px 8px;
`

export const StyledFooter = styled.div`
  padding: 8px 16px 0px 16px;
  > button {
    width: 100%;
    justify-content: center;
    > span {
      width: initial;
    }
  }
`

export const StyledFlowItemWithArrow = styled.div<{
  $hasDivision?: boolean
  $hasBigMargin?: boolean
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: -8px -16px;
  padding: 8px 16px;
  padding-left: ${props => (props.$hasBigMargin ? '32px' : '16px')};
  border-top: ${props =>
    props.$hasDivision ? `1px solid ${COLORS.N100}` : 'none'};
`
