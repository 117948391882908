import { useState } from 'react'

import { HtBtnType, HtButton, HtInput, HtInputProps, Size } from '../../base'
import { StyledInputWithActionForm } from './ht-input-styles'

interface HtInputWithActionProps {
  buttonText: string
  errorMessage?: string
  inputProps: Omit<HtInputProps, 'value'>
  size?: Size
  type?: HtBtnType
  applyAction: (value: string) => void
  isValueValid: (value: string) => boolean
  onValueChange?: (value: string) => void
}

export const HtInputWithAction = ({
  buttonText,
  errorMessage,
  inputProps,
  size = Size.TINY,
  type = HtBtnType.PRIMARY,
  applyAction,
  isValueValid,
  onValueChange,
}: HtInputWithActionProps) => {
  const [value, setValue] = useState('')
  const [hasError, setHasError] = useState(false)

  const onInputChange = (newValue: string) => {
    setValue(newValue)
    setHasError(!isValueValid(newValue))
    onValueChange?.(newValue)
  }

  const onButtonClick = () => {
    applyAction(value)
    setValue('')
  }

  return (
    <StyledInputWithActionForm>
      <HtInput
        isLabelHidden={true}
        {...inputProps}
        size={size}
        value={value}
        error={hasError}
        helperText={hasError ? errorMessage : inputProps.helperText}
        onChange={onInputChange}
      />
      <HtButton
        size={size}
        type={type}
        disabled={hasError || !value}
        onClick={onButtonClick}
      >
        {buttonText}
      </HtButton>
    </StyledInputWithActionForm>
  )
}
