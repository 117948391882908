import {
  ButtonFields,
  CarouselFields,
  TextFields,
  TopContentFields,
} from './content-fields'

export function getButtons(content: TopContentFields): ButtonFields[] {
  if (content instanceof TextFields) {
    return content.buttons
  } else if (content instanceof CarouselFields) {
    return flatten(content.elements.map(e => e.buttons))
  }
  return []
}

export function flatten<T>(nestedArrays: T[][]): T[] {
  return ([] as T[]).concat(...nestedArrays)
}
