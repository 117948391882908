import { Handle } from 'reactflow'
import styled, { css } from 'styled-components'

export enum HandleType {
  BUTTON = 'button',
  CONDITION = 'condition',
  ELEMENT = 'element',
  FALLBACK1 = 'fallback1',
  FOLLOWUP = 'followup',
  FOLLOWUP_KNOWLEDGE_BASE = 'followup_knowledge_base',
  INPUT = 'input',
  SECTION = 'section',
  SMALL_CONDITION = 'small_condition',
  TARGET = 'target',
  START_CONVERSATION = 'start_conversation',
  START_FLOW = 'start_flow',
}

export const triangleHandle = css<{ $isHidden?: boolean }>`
  position: absolute;
  width: unset;
  height: unset;
  border: none;
  background: transparent;
  z-index: 10;
  visibility: ${props => (props.$isHidden ? 'hidden' : 'visible')};
  svg {
    margin-top: 4.5px;
    pointer-events: none;
  }
`

export const TriangleHandle = styled(Handle)<{
  $type: HandleType
  $isHidden?: boolean
}>`
  ${triangleHandle};

  ${props => props.$type === HandleType.TARGET && targetHandle};
  ${props => props.$type === HandleType.FOLLOWUP && followupHandle};
  ${props => props.$type === HandleType.INPUT && inputHandle};
  ${props => props.$type === HandleType.BUTTON && buttonHandle};
  ${props => props.$type === HandleType.CONDITION && defaultHandle};
  ${props =>
    props.$type === HandleType.SMALL_CONDITION && smallConditionHandle};
  ${props => props.$type === HandleType.SECTION && sectionHandle};
  ${props => props.$type === HandleType.ELEMENT && defaultHandle};
  ${props => props.$type === HandleType.FALLBACK1 && fallback1Handel};
  ${props =>
    props.$type === HandleType.FOLLOWUP_KNOWLEDGE_BASE &&
    followupKnowledgeBaseHandle};
  ${props =>
    props.$type === HandleType.START_CONVERSATION && startConversation};
  ${props => props.$type === HandleType.START_FLOW && startFlow};
`

const defaultHandle = css`
  right: -11px;
  top: calc(50% + 7px);
`

const startConversation = css`
  right: -11px;
  top: 50%;
`

const startFlow = css`
  right: -11px;
  top: 45px;
`

const targetHandle = css`
  left: -8px;
`

const followupHandle = css`
  top: 28.5px;
  right: -11px;
`

const inputHandle = css`
  right: -11px;
`

const buttonHandle = css`
  right: -27px;
  top: calc(50% + 2px);
`

const smallConditionHandle = css`
  right: -27px;
  top: calc(50% + 7px);
`

const sectionHandle = css`
  right: -11px;
  top: calc(100% - 11px);
`

const followupKnowledgeBaseHandle = css`
  right: -12px;
  top: calc(50% - 9px);
`

const fallback1Handel = css`
  right: -12px;
  top: 50%;
`
