import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'

export interface SelectFlowInterface {
  type: ActionType.SELECT_FLOW
  flowId: string
  isReversible?: boolean
}

export interface SelectFlowHistoryChange {
  type: ActionType.SELECT_FLOW
  newFlowId: string
  oldFlowId: string
  currentNode?: NodeTypes
  currentFlowId: string
}

export class SelectFlowAction extends ReversibleAction {
  static apply = (
    state: State,
    { flowId, isReversible = true }: SelectFlowInterface
  ): void => {
    if (isReversible) this.trackHistoryChange(state, flowId)
    state.currentFlowId = flowId
    state.currentNode = undefined
  }

  static undo = (state: State, change: SelectFlowHistoryChange) => {
    state.currentFlowId = change.oldFlowId
  }

  static redo = (state: State, change: SelectFlowHistoryChange) => {
    state.currentFlowId = change.newFlowId
  }

  private static trackHistoryChange = (state: State, flowId: string) => {
    const newChange: SelectFlowHistoryChange = {
      type: ActionType.SELECT_FLOW,
      newFlowId: flowId,
      oldFlowId: state.currentFlowId,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange, false)
  }
}
