import styled from 'styled-components'

import { BUILD_PANEL_WIDTH } from '../../constants'
import { COLORS } from '../base'
import { TextBodySmallBold } from '../base/typography'

export const BuildPanelContainer = styled.div`
  width: ${BUILD_PANEL_WIDTH}px;
  box-sizing: border-box;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${COLORS.N50};
  gap: 6px;
  border-radius: 8px;
  z-index: 10;

  > :first-child {
    margin-top: 0px;
  }
`

export const SectionTitle = styled(TextBodySmallBold)`
  margin: 18px 8px 6px;
`

export const Node = styled.div`
  box-sizing: border-box;
  padding: 12px 16px;
  height: 42px;
  display: flex;
  justify-content: space-between;
  cursor: grab;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: ${COLORS.N900};
  background-color: ${COLORS.WHITE};
  gap: 16px;

  > :first-child {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  > div:last-child {
    display: flex;
    gap: 8px;
  }

  &:hover {
    transform: rotate(1deg);
  }

  svg {
    color: ${COLORS.N500};
  }
`

export const InputNode = styled(Node)`
  background-color: ${COLORS.N900};
  color: ${COLORS.WHITE};
  svg {
    color: ${COLORS.WHITE};
  }
`

export const ConditionalNode = styled(Node)`
  background-color: ${COLORS.N500};
  color: ${COLORS.WHITE};
  svg {
    color: ${COLORS.WHITE};
  }
`
