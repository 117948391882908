import styled from 'styled-components'

import { COLORS, TextSmallBold, TextSmallExtra } from '../../base'

export const DropdownHeader = styled(TextSmallExtra)`
  color: ${COLORS.N500};
  margin: 8px 16px;
`

export const PublishedText = styled(TextSmallBold)`
  color: ${COLORS.N500};
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }
`
