import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { memo } from 'react'

import { usePanelVisibility } from '../../../custom-hooks'
import { ROLES } from '../../../roles'
import { EmojiPickerContainer } from './ht-input-styles'

interface HtEmojiPickerProps {
  inputRef?: React.RefObject<HTMLInputElement>
  value: string
  closeEmojiPicker: () => void
  onChange?: (value: string) => void
}

interface BaseEmoji {
  id: string
  name: string
  native: string
}

export const HtEmojiPicker = memo((props: HtEmojiPickerProps) => {
  const ref = usePanelVisibility(() => {
    props.closeEmojiPicker()
  })

  const onEmojiSelect = (emoji: BaseEmoji) => {
    const cursor = props.inputRef?.current?.selectionStart || 0
    const newValue =
      props.value.slice(0, cursor) + emoji.native + props.value.slice(cursor)
    props.onChange && props.onChange(newValue)
    props.closeEmojiPicker()
  }

  return (
    <EmojiPickerContainer role={ROLES.LIST_BOX} ref={ref}>
      <Picker
        data={data}
        onEmojiSelect={onEmojiSelect}
        maxFrequentRows={1}
        previewPosition='none'
        theme='light'
      />
    </EmojiPickerContainer>
  )
})
