import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { Size } from '../variants'
import { PanelType } from './ht-info-panel'

export const InfoPanelContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const computeInfoPanelBackground = (type?: PanelType) => {
  switch (type) {
    case PanelType.PRIMARY:
      return COLORS.L50
    case PanelType.WARN:
      return COLORS.O50
    default:
      return COLORS.N50
  }
}

const computeInfoPanelIconColor = (type?: PanelType) => {
  switch (type) {
    case PanelType.WARN:
      return COLORS.O500
    default:
      return 'black'
  }
}

export const StyledInfoPanel = styled.div<{ $type?: PanelType; $size: Size }>`
  background: ${props => computeInfoPanelBackground(props.$type)};
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: pre-wrap;
  ${props =>
    props.$type === PanelType.WARN ? `border: 1px solid ${COLORS.O300}` : ''};

  ${props => props.$size === Size.SMALL && smallSize}
  ${props => props.$size === Size.MEDIUM && mediumSize}

  svg {
    color: ${props => computeInfoPanelIconColor(props.$type)} !important;
  }

  > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  > :last-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ul {
    padding-left: 16px;
    margin: 0px;
  }

  img {
    width: 100%;
    margin-top: 8px;
  }
`

const smallSize = css`
  padding: 20px 16px;
  border-radius: 4px;
`

const mediumSize = css`
  padding: 16px 24px;
  border-radius: 8px;
`
