export const getTime = (date: Date): string => {
  const time = date.toLocaleTimeString('es-ES').split(':')
  return `${time[0]}:${time[1]}`
}

export const getDate = (date: Date): string => {
  const day = toStringTimeUnit(date.getDate())
  const month = toStringTimeUnit(date.getMonth() + 1)
  const year = toStringTimeUnit(date.getFullYear())
  return `${day}.${month}.${year}`
}

export const getDateTime = (date: Date): string => {
  const day = getDate(date)
  const time = getTime(date)
  return `${day} - ${time}`
}

const toStringTimeUnit = (value: number): string => {
  return value < 10 ? `0${value}` : value.toString()
}
