import { PresenceMessage, Realtime, RealtimeChannel } from 'ably'
import { useEffect, useState } from 'react'

import { FlowBuilderUser } from '../../domain/models/organization-models'
import { FlowBuilderService } from '../../repository/hubtype/flow-builder-service'
import { HubtypeApi } from '../../repository/hubtype/hubtype-api'
import { SaveOrigin } from '../analytics'
import { useSave } from '../components/header/save/use-save'

export enum SessionMode {
  Edit = 'Edit',
  Lobby = 'Lobby',
  SessionEnded = 'SessionEnded',
}

const PRESENCE_FLOW_BUILDER_CHANNEL_NAME = 'presence-flow-builder-users'

const JWT_AUTH_URL = `${HubtypeApi.getHubtypeApiUrl()}/ably/flow-builder-auth`

export interface MembersInfo {
  me: string
  activeUsers: PresenceMessage[]
}

export function useAbly(authToken: string) {
  const { saveFlow } = useSave()

  const [flowBuilderUser, setFlowBuilderUser] = useState<FlowBuilderUser>()

  // TODO: Review how initialize this state with undefined and then set it to a value when we have the flowBuilderUser
  const [channelName, setChannelName] = useState<string>('')

  const [realtimeClient, setRealtimeClient] = useState<Realtime | null>(null)

  const [myConnectionEnterTime, setMyConnectionEnterTime] = useState(Date.now())

  const [sessionMode, setSessionMode] = useState<SessionMode>()

  const [membersInfo, setMembersInfo] = useState<MembersInfo>()

  useEffect(() => {
    if (!flowBuilderUser) {
      identifyUser()
    }
  }, [])

  useEffect(() => {
    if (!flowBuilderUser) {
      return
    }
    if (!realtimeClient) {
      initializeRealtimeClient(myConnectionEnterTime)
    }
  }, [flowBuilderUser])

  useEffect(() => {
    if (!realtimeClient) {
      return
    }
    subscribeToPresenceEvents(realtimeClient)
    return () => {
      unsubcribePresenceEvents(realtimeClient)
    }
  }, [realtimeClient, sessionMode])

  const identifyUser = async (): Promise<void> => {
    const flowBuilderUser = await FlowBuilderService.getUserInfo(authToken)
    setFlowBuilderUser(flowBuilderUser)

    if (!flowBuilderUser) return
    const channelName = `${PRESENCE_FLOW_BUILDER_CHANNEL_NAME}-${flowBuilderUser.organizationId}-${flowBuilderUser.botId}`
    setChannelName(channelName)
  }

  const getPresenceChannel = (realtimeClient: Realtime) => {
    return realtimeClient.channels.get(channelName)
  }

  const getMembersInfo = async (): Promise<MembersInfo | undefined> => {
    if (!realtimeClient) return undefined
    const presenceChannel = getPresenceChannel(realtimeClient)
    return {
      activeUsers: await getPresenceUsers(presenceChannel),
      me: flowBuilderUser?.id || '',
    }
  }

  const updateMembersInfo = async () => {
    const membersInfo = await getMembersInfo()
    setMembersInfo(membersInfo)
  }

  const initializeRealtimeClient = async (enterTime: number) => {
    // TODO: Should we really need to use clientId for initializing RealtimeClient?
    const client = new Realtime({
      authUrl: JWT_AUTH_URL,
      authMethod: 'POST',
      authHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    const presenceChannel = getPresenceChannel(client)

    client.connection.on(async stateChange => {
      if (stateChange.current === 'connected') {
        const presenceUsers = await getPresenceUsers(presenceChannel)

        if (presenceUsers.length === 0) {
          await presenceChannel.presence.enter({
            enterTime,
          })
          setSessionMode(SessionMode.Edit)
        } else if (
          presenceUsers.some(u => u.connectionId !== client.connection.id)
        ) {
          await presenceChannel.presence.leave()
          setSessionMode(SessionMode.Lobby)
        }
      }
    })
    subscribeToPresenceEvents(client)
    setRealtimeClient(client)
  }

  const getPresenceUsers = async (
    channel: RealtimeChannel
  ): Promise<PresenceMessage[]> => {
    return await channel.presence.get()
  }

  const onUserEnter = async (client: Realtime, member: PresenceMessage) => {
    await updateMembersInfo()
    const presenceChannel = getPresenceChannel(client)

    if (sessionMode) {
      if (member.connectionId !== client.connection.id) {
        if (sessionMode === SessionMode.Lobby) {
          return
        }

        if (member.data.enterTime > myConnectionEnterTime) {
          setSessionMode(SessionMode.SessionEnded)
          await saveFlow(SaveOrigin.ON_SESSION_ENDED)
          await presenceChannel.presence.leave()
        }
      } else {
        setSessionMode(SessionMode.Edit)
      }
    }
  }

  const onUserLeave = async (): Promise<void> => {
    await updateMembersInfo()
  }

  const subscribeToPresenceEvents = async (client: Realtime) => {
    const presenceChannel = getPresenceChannel(client)
    await presenceChannel.presence.subscribe('enter', member =>
      onUserEnter(client, member)
    )

    await presenceChannel.presence.subscribe('leave', async () => onUserLeave())
  }

  const unsubcribePresenceEvents = async (client: Realtime) => {
    const presenceChannel = getPresenceChannel(client)
    presenceChannel.presence.unsubscribe('enter', member =>
      onUserEnter(client, member)
    )
    presenceChannel.presence.unsubscribe('leave', async () => onUserLeave())
  }

  const handleKickEditingUsers = async () => {
    if (!realtimeClient) return
    const presenceChannel = getPresenceChannel(realtimeClient)
    const newEnterTime = Date.now()
    setMyConnectionEnterTime(newEnterTime)
    await presenceChannel.presence.enter({ enterTime: newEnterTime })
  }

  const handleDisconnectMyself = async () => {
    if (!realtimeClient) return
    const presenceChannel = getPresenceChannel(realtimeClient)
    await presenceChannel.presence.leave()
  }

  return {
    sessionMode,
    membersInfo,
    flowBuilderUser,
    handleKickEditingUsers,
    handleDisconnectMyself,
  }
}
