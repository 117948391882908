export enum FeatureFlag {
  INTENT_MODELS = 'ai',
  AI_SMART_INTENTS = 'ai_smart_intents_enabled',
  KNOWLEDGE_BASE = 'knowledgebase_enabled',
}

export const hasIntentModelsFeature = (
  orgFeatureFlags: Record<string, boolean>
) => {
  return Boolean(orgFeatureFlags[FeatureFlag.INTENT_MODELS])
}

export const hasSmartIntentsFeature = (
  orgFeatureFlags: Record<string, boolean>
) => {
  return Boolean(orgFeatureFlags[FeatureFlag.AI_SMART_INTENTS])
}

export const hasKnowledgeBaseFeature = (
  orgFeatureFlags: Record<string, boolean>
) => {
  return Boolean(orgFeatureFlags[FeatureFlag.KNOWLEDGE_BASE])
}
