import { useMachine } from '@xstate/react'
import { useForm } from 'react-hook-form'
import { fromPromise } from 'xstate'

import { ConversationalApp } from '../../../../../domain/models/organization-models'
import { useFeedbackMessage } from '../../../../custom-hooks/use-feedback-message'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { SelectConversationalAppStep } from './steps/select-conversational-app-step'
import { SummaryStep } from './steps/summary-step'
import { TransferFlowMachine } from './transfer-flow-machine'

export interface TransferDetails {
  conversationalApp?: ConversationalApp
  openDestinationCAppOnTransfer?: boolean
}

export const TransferFlowModal = () => {
  const { state, setPopupContent } = useFlowBuilderSelector(ctx => ctx)
  const { reportSuccess } = useFeedbackMessage()

  const form = useForm<TransferDetails>({
    mode: 'onChange',
    defaultValues: {
      openDestinationCAppOnTransfer: true,
    },
  })

  const [machineState, send] = useMachine(
    TransferFlowMachine.provide({
      actors: {
        transferFlow: fromPromise(async () => await transferFlow()),
        closeModal: fromPromise(() => closeModal()),
      },
    })
  )

  const transferFlow = async (): Promise<boolean> => {
    const { conversationalApp, openDestinationCAppOnTransfer } =
      form.getValues()

    //TODO: call transfer flow endpoint
    reportSuccess(`Flow transferred to ${conversationalApp?.name}`)

    if (openDestinationCAppOnTransfer) {
      postCloseFromFlowBuilderMessage(
        `/bots/${conversationalApp?.id}/flowbuilder`
      )
    }
    closeModal()
    return true
  }

  const closeModal = async () => {
    setPopupContent()
  }

  const conversationalApps =
    state.organizationContents.conversationalApps.filter(
      cApps =>
        cApps.id !== state.organizationContents.currentConversationalApp.id &&
        cApps.flowBuilderSettings?.cmsType
    )

  if (machineState.matches('conversationalAppSelection'))
    return (
      <SelectConversationalAppStep
        form={form}
        conversationalApps={conversationalApps}
        cancel={() => send({ type: 'CANCEL' })}
        goNext={() => send({ type: 'NEXT' })}
      />
    )

  if (machineState.matches('summary')) {
    return (
      <SummaryStep
        form={form}
        goBack={() => send({ type: 'BACK' })}
        transfer={() =>
          send({ type: 'TRANSFER', transferDetails: form.getValues() })
        }
      />
    )
  }

  return null
}
