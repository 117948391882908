import { assertEvent, emit, PromiseActorLogic, setup } from 'xstate'

import { TransferDetails } from './transfer-flow-modal'

export const TransferFlowMachine = setup({
  types: {
    context: {} as {
      transferDetails?: TransferDetails
    },
    events: {} as
      | { type: 'TRANSFER'; transferDetails: TransferDetails }
      | { type: 'NEXT' }
      | { type: 'BACK' }
      | { type: 'CANCEL' },
    emitted: {} as any,
  },
  actors: {
    transferFlow: {} as PromiseActorLogic<boolean, TransferDetails>,
    closeModal: {} as PromiseActorLogic<void, void>,
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QBUBOBDAdrAZmVAYgDYD2A7gLLoDGAFgJaZgB01JmAbvrOgC73t0RAIIAHUQGUwRMNX7sAxADkAogA1kAbQAMAXUShRJWPXmYDIAB6IATAE4ArMzuOAHAGYAbHZsAWG9raAIwOADQgAJ62Du7MAOwBPkFxQUG+rnauvgC+2eFoWLj4xORUdIwsbJzcfAKYQmKS0rJmCgDCwkptKgAyOvpIIEYmZhbWCDauTu4OdmmOySmeYZGIvp6xy16eNkH27glxufkY2HiEpJQ0DEzMsACuALaP6KgRCsgASp0SBCqf-Qsw1MdTGiAcrhszCmSx27ncQW07nCUQmMXiAVcnl8Dm0kIOfmOIAKZ2KlzKNxYD2er3eACFhG0ANKAwbA0aDca+IKuZyeIKeOIOQXLVxClGIGZObSOdwuJFzOIbIkkooXUrXCp3J4vN7tTrdPp6IHGEHsMEIWZBZjpFyebF2OKHOIShDrTYxTzaXw+fkBIIq05qkpXcq3XhB86oRhQEMKCDsFiMDgkADWLFV5xDFK1EcKUZjIYQyZI1Fq7H6rMMpo5oHGLjizC8Srm7ixAvSrt2vKVcSy9uW2i9SMD+bJGrDLDzpNQ0cwscuCnwqBIqGYoiIfBwq8ezEz49DlOY07Vc4X5GLnFL5cwleNbJroM5iBbzB5HjsnkhX-tNldM15Xw+yFOY7DbBFPFHGds01W5qFIEx5woEgICEeNE2YEt0z3SMDxzOCEJjZDUKIS8UzLMw7wGasRifOtED2BwnCVew7DxSZMj7V11icEIvBxVwgjYlIAyJTAULgCx93VQ8KhNWjzWfBAAFoeSbGx7F8bR7DidwNKVV0ggRZgbEFLJdLiIDvW0BwoODclYMqdguFQHgzAacQpBkOQ6KGR9FPoiY7GYQU4hsx1tNCvx-yY5gZRieU5WSZU8mJXCZPwqkdVpeSzXMJTfCA5wNP5bYpiM7QXVWNFYgSPFsVxfEUlcOyswcydj3Ss8Q1y2srBfWLAiAmYwLieVfC7dxtBMxJEXsREvD2Vq8Mc1hCKQlChF63zxkRWKcWCJE8U-JUVlRXx4WcTJko2e1IUK5aMtW+DjEgbaAv6hA0i06EHB5QUIRxbk-2q9YoS0ka7AOyYEncXJciAA */
  id: 'TransferFlowMachine',
  initial: 'conversationalAppSelection',
  states: {
    conversationalAppSelection: {
      on: {
        NEXT: {
          target: 'summary',
        },
        CANCEL: {
          target: 'closingModal',
        },
      },
    },

    summary: {
      on: {
        TRANSFER: {
          target: 'transferringFlow',
        },
        BACK: {
          target: 'conversationalAppSelection',
        },
        CANCEL: {
          target: 'closingModal',
        },
      },
    },

    transferringFlow: {
      invoke: {
        src: 'transferFlow',
        input: ({ event, context }) => {
          assertEvent(event, 'TRANSFER')
          context.transferDetails = event.transferDetails
          return event.transferDetails
        },
        onDone: {
          target: 'closingModal',
          actions: [
            // This is not being used now, will be used when moving the flow builder to the hubtype product repo
            emit(({ event }) => ({
              type: 'success',
              message: `Flow transferred to ${event}`,
            })),
            emit(({ context }) => ({
              type: 'analytics',
              eventName: 'fb_flow_transferred',
              eventProps: {
                open_target:
                  context.transferDetails?.openDestinationCAppOnTransfer,
              },
            })),
          ],
        },
        onError: {
          target: 'closingModal',
          // This is not being used now, will be used when moving the flow builder to the hubtype product repo
          actions: [
            emit({
              type: 'error',
              message:
                'Failed to transfer flows. Please try again later or contact support for further assistance.',
            }),
            emit(({ event }) => ({
              type: 'analytics',
              eventName: 'fb_error',
              eventProps: { error: (event as any).error.code },
            })),
          ],
        },
      },
    },

    closingModal: {
      invoke: { src: 'closeModal', onDone: 'closed' },
    },

    closed: {
      type: 'final',
    },
  },
})
