import styled from 'styled-components'

import { COLORS } from '../../colors'
import { StyledList } from '../../common/dropdown/styles'
import { textBodySmall, textSmall } from '../../typography'

export const SectionTitle = styled.div`
  ${textSmall};
  color: ${COLORS.N500};
  padding: 0px 16px 8px;
`

export const StyledSection = styled.div`
  padding-top: 20px;
`

export const StyledSelectList = styled(StyledList)`
  ${StyledSection}:first-of-type {
    padding-top: 8px;
  }
`

export const StyledNoMatchesText = styled.div`
  ${textBodySmall};
  color: ${COLORS.N500};
  padding: 8px 16px;
`
