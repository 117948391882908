export enum Country {
  AG = 'Antigua and Barbuda',
  AR = 'Argentina',
  AT = 'Austria',
  BB = 'Barbados',
  BG = 'Bulgaria',
  BO = 'Bolivia',
  BR = 'Brazil',
  BS = 'Bahamas',
  CA = 'Canada',
  CH = 'Switzerland',
  CL = 'Chile',
  CN = 'China',
  CO = 'Colombia',
  CR = 'Costa Rica',
  CU = 'Cuba',
  CZ = 'Czech Republic',
  DE = 'Germany',
  DK = 'Denmark',
  DM = 'Dominica',
  DO = 'Dominican Republic',
  EC = 'Ecuador',
  EE = 'Estonia',
  ES = 'Spain',
  FI = 'Finland',
  FR = 'France',
  GB = 'United Kingdom',
  GE = 'Georgia',
  GR = 'Greece',
  GY = 'Guyana',
  HR = 'Croatia',
  HU = 'Hungary',
  ID = 'Indonesia',
  IE = 'Ireland',
  IN = 'India',
  IS = 'Iceland',
  IT = 'Italy',
  JM = 'Jamaica',
  JP = 'Japan',
  KR = 'Korea',
  LK = 'Sri Lanka',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  LV = 'Latvia',
  MT = 'Malta',
  MX = 'Mexico',
  MY = 'Malaysia',
  NL = 'Netherlands',
  NO = 'Norway',
  PA = 'Panama',
  PE = 'Peru',
  PL = 'Poland',
  PT = 'Portugal',
  PY = 'Paraguay',
  RO = 'Romania',
  RS = 'Serbia',
  RU = 'Russia',
  SA = 'Saudi Arabia',
  SE = 'Sweden',
  SI = 'Slovenia',
  SK = 'Slovakia',
  SR = 'Suriname',
  TH = 'Thailand',
  TR = 'Turkey',
  TT = 'Trinidad and Tobago',
  UA = 'Ukraine',
  US = 'United States',
  UY = 'Uruguay',
  VE = 'Venezuela',
  VN = 'Vietnam',
  ZA = 'South Africa',
}
