import styled from 'styled-components'

import { COLORS, TextBodySmall } from '../base'
import { ModalHeader } from '../base/ht-modal/ht-modal-styles'

export const LocaleHeader = styled(ModalHeader)`
  margin-bottom: 8px;
`

export const LocalesList = styled.div`
  height: 344px;
  overflow-y: auto;
`

export const EmptySearch = styled(TextBodySmall)`
  margin: 16px;
  color: ${COLORS.N500};
`
