import { useEffect, useRef } from 'react'

import { FeedbackType } from '../../../../domain/feedback'
import { useRepository } from '../../../../repository/repository-utils'
import { SaveOrigin, TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'

export const useSave = () => {
  const analytics = useAnalytics()
  const repository = useRepository()
  const { state, toggleFlowSaved, updateAllNodes, setHash } =
    useFlowBuilderSelector(ctx => ctx)
  const stateRef = useRef(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const shouldSave = (isRestoring?: boolean) => {
    const hasNodes = stateRef.current.nodes.length > 0
    const { isReadOnly, isFlowSaved, isOffline } = stateRef.current
    return (
      (hasNodes && !isFlowSaved && !isReadOnly && !isOffline) || isRestoring
    )
  }

  const saveFlow = async (
    origin?: SaveOrigin,
    isRestoring?: boolean
  ): Promise<boolean> => {
    if (!shouldSave(isRestoring)) {
      return true
    }

    if (origin) {
      analytics.trackEvent(TrackEventName.SAVE, { origin })
    }
    toggleFlowSaved(true)
    const writeResponse = await repository.cmsWriter.writeContent(
      stateRef.current
    )
    updateAllNodes(stateRef.current.nodes, false)

    if (writeResponse.feedbackStatus === FeedbackType.SUCCESS) {
      setHash(writeResponse.newHash)
      return true
    }
    toggleFlowSaved(false)
    return false
  }

  return { saveFlow }
}
