import { findIndex } from 'lodash'
import orderBy from 'lodash.orderby'
import { useEffect, useState } from 'react'

import { normalizeValue } from '../../../utils/string-utils'
import { TrackEventName, useAnalytics } from '../../analytics'
import { KNOWLEDGE_BASE_FLOW, SEARCH_DEBOUNCE_DELAY } from '../../constants'
import { useDebounce } from '../../custom-hooks'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import { NodeTypes } from '../../types'
import { ViewportAnimator } from '../../utils/viewport-animator'
import { COLORS, HtIcon, Icon, Size } from '../base'
import {
  ResultContainer,
  SearchBarContainer,
  SearchBarInput,
} from './search-bar-styles'

export const SearchBar = (): JSX.Element => {
  const analytics = useAnalytics()
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const selectNode = useFlowBuilderSelector(ctx => ctx.selectNode)
  const [matchingIds, setMatchingIds] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState<number | undefined>()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce<string>(
    searchValue,
    SEARCH_DEBOUNCE_DELAY
  )

  useEffect(() => {
    if (currentIndex !== undefined && currentIndex > -1) {
      selectAndCenterNode(matchingIds[currentIndex])
    }
  }, [currentIndex])

  useEffect(() => {
    if (searchValue) findNodes()
  }, [state.nodes])

  useEffect(() => search(), [debouncedSearchValue])

  const findNodes = (hasUserInitiatedSearch?: boolean): void => {
    if (!searchValue) {
      setCurrentIndex(undefined)
      return
    }
    const matchingNodesIds = getMatchingNodesIds(searchValue)
    setMatchingIds(matchingNodesIds)
    if (hasUserInitiatedSearch) {
      analytics.trackEvent(TrackEventName.SEARCH_BAR, {
        number_of_results: matchingNodesIds.length,
        search_text: searchValue,
      })
      selectFirstMatchingNode(matchingNodesIds)
    }
  }

  const selectFirstMatchingNode = (matchingNodesIds: string[]) => {
    const newIndex = matchingNodesIds.length > 0 ? 0 : -1
    setCurrentIndex(newIndex)
    if (matchingNodesIds.some((value, index) => value !== matchingIds[index])) {
      selectAndCenterNode(matchingNodesIds[newIndex])
    }
  }

  const getMatchingNodesIds = (searchValue: string): string[] => {
    const value = normalizeValue(searchValue)
    const matchingNodes = state.nodes.filter(
      node =>
        node.data.hasString(value) &&
        (state.isKnowledgeBaseActive ||
          node.data.flowId !== KNOWLEDGE_BASE_FLOW.id)
    )
    return sortNodesByFlow(matchingNodes).map(node => node.id)
  }

  const sortNodesByFlow = (nodes: NodeTypes[]): NodeTypes[] => {
    return orderBy(nodes, [
      node => findIndex(state.flows, { id: node.data.flowId }),
      'data.code',
    ])
  }

  const selectAndCenterNode = (nodeId?: string) => {
    if (!nodeId) return
    ViewportAnimator.centerNode(state, nodeId, selectNode)
  }

  const onArrowClick = (index: number) => {
    if (index < 0) index = matchingIds.length - 1
    else if (index >= matchingIds.length) index = 0
    setCurrentIndex(index)
    analytics.trackEvent(TrackEventName.SEARCH_BAR_ARROWS_CLICK)
  }

  const isArrowEnabled = (): boolean => {
    if (matchingIds.length === 0) return false
    if (matchingIds.length === 1 && currentIndex === 0) return false
    return true
  }

  const clearSearch = () => {
    setSearchValue('')
    setCurrentIndex(undefined)
  }

  const search = () => {
    findNodes(true)
  }

  return (
    <SearchBarContainer>
      <HtIcon
        icon={Icon.SEARCH}
        size={Size.TINY}
        style={{ color: searchValue ? COLORS.WHITE : COLORS.N300 }}
      />
      <SearchBarInput
        placeholder='Search in flow'
        aria-label={ROLES.SEARCH_BOX}
        value={searchValue}
        onChange={({ target }) => setSearchValue(target.value)}
      />
      {currentIndex !== undefined && (
        <ResultContainer>
          {`${currentIndex + 1}/${matchingIds.length}`}
          <HtIcon
            icon={Icon.CHEVRON_LEFT}
            style={{ color: isArrowEnabled() ? COLORS.WHITE : COLORS.N500 }}
            onClick={() => isArrowEnabled() && onArrowClick(currentIndex - 1)}
          />
          <HtIcon
            icon={Icon.CHEVRON_RIGHT}
            style={{ color: isArrowEnabled() ? COLORS.WHITE : COLORS.N500 }}
            onClick={() => isArrowEnabled() && onArrowClick(currentIndex + 1)}
          />
          <HtIcon
            icon={Icon.XMARK}
            size={Size.MEDIUM}
            onClick={() => clearSearch()}
          />
        </ResultContainer>
      )}
    </SearchBarContainer>
  )
}
