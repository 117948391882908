import { useEffect } from 'react'

import { NodeTypes } from '../types'

const isCopyingNodes = ({ target }: Event): boolean => {
  if (!(target instanceof HTMLElement)) return false
  const isInputElement = target instanceof HTMLInputElement
  const isBodyElement = target instanceof HTMLBodyElement
  const isReactFlowElement = target?.closest('.react-flow') !== null
  return !isInputElement && (isBodyElement || isReactFlowElement)
}

export function useCopyPasteCut(
  nodes: NodeTypes[],
  copy: (event: ClipboardEvent) => void,
  paste: (event: ClipboardEvent) => void,
  cut: (event: ClipboardEvent) => void
) {
  const onCopy = (evt: ClipboardEvent) => {
    if (isCopyingNodes(evt)) copy(evt)
  }
  const onPaste = (evt: ClipboardEvent) => {
    if (isCopyingNodes(evt)) paste(evt)
  }
  const onCut = (evt: ClipboardEvent) => {
    if (isCopyingNodes(evt)) cut(evt)
  }
  useEffect(() => {
    document.addEventListener('copy', onCopy)
    document.addEventListener('paste', onPaste)
    document.addEventListener('cut', onCut)
    return () => {
      document.removeEventListener('copy', onCopy)
      document.removeEventListener('paste', onPaste)
      document.removeEventListener('cut', onCut)
    }
  }, [nodes, copy, paste])
}
