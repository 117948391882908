import deepClone from 'lodash.clonedeep'

import { TopContentFields } from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NodeAction } from './node-action'

export interface UpdateNodeInterface {
  type: ActionType.UPDATE_NODE
  data: TopContentFields
}

export interface UpdateNodeHistoryChange {
  type: ActionType.UPDATE_NODE
  newData: TopContentFields
  oldData: TopContentFields
  currentNode?: NodeTypes
  currentFlowId: string
}

export class UpdateNodeAction extends ReversibleAction {
  static apply = (state: State, newData: TopContentFields): void => {
    const node = NodeAction.getNodeById(state, newData.id)
    const oldData = deepClone(node?.data)
    if (node && oldData) {
      this.setErrors(newData, oldData)
      this.trackHistoryChange(state, newData, oldData)
      node.data = newData
      state.currentNode = node
      this.checkRepeatedContentIds(state, newData, oldData)
    }
  }

  static undo = (state: State, change: UpdateNodeHistoryChange) => {
    const node = NodeAction.getNodeById(state, change.oldData.id)
    if (node) {
      node.data = change.oldData
      this.checkRepeatedContentIds(state, change.oldData, change.newData)
    }
  }

  static redo = (state: State, change: UpdateNodeHistoryChange) => {
    const node = NodeAction.getNodeById(state, change.newData.id)
    if (node) {
      node.data = change.newData
      state.currentNode = node
      this.checkRepeatedContentIds(state, change.newData, change.oldData)
    }
  }

  private static trackHistoryChange = (
    state: State,
    newData: TopContentFields,
    oldData: TopContentFields
  ) => {
    const newChange: UpdateNodeHistoryChange = {
      type: ActionType.UPDATE_NODE,
      newData,
      oldData,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static setErrors = (
    newData: TopContentFields,
    oldData: TopContentFields
  ) => {
    newData.setErrors()
    if (
      oldData.errors.fieldErrors.length === 0 &&
      !oldData.errors.hasDuplicatedCode
    ) {
      newData.errors.showErrors = false
    }
  }

  private static checkRepeatedContentIds = (
    state: State,
    newData: TopContentFields,
    oldData: TopContentFields
  ) => {
    if (newData.code === oldData.code) return
    NodeAction.reportRepeatedContentIds(state)
  }
}
