export enum Size {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  TINY = 'tiny',
  MICRO = 'micro',
}

export enum Placement {
  BOTTOM_START = 'bottom start',
  BOTTOM_END = 'bottom end',
}
