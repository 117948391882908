import styled from 'styled-components'

import { COLORS } from '../../../base'

export const StyledSessionEndedContainer = styled.div`
  display: flex;
  align-self: center;
  padding: 16px 12px 16px 12px;
`
export const StyledEndSessionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px;
  border: 1px solid ${COLORS.N100};
  border-radius: 10px;
`

export const StyledActiveUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  color: ${props => props.color || COLORS.N900};
`

export const StyledSessionEndedTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  svg {
    color: ${COLORS.N500};
  }
`
