import { v7 as uuidv7 } from 'uuid'

export enum FeedbackType {
  CONNECTION_ISSUE = 'connection-issue',
  ERROR = 'error',
  INFORM = 'inform',
  SUCCESS = 'success',
  CUSTOM = 'custom',
}

export interface FeedbackDetails {
  nodeId?: string
  localeCode?: string
  isAiNotification?: boolean
}

export class FeedbackMessage {
  public readonly id: string
  constructor(
    public message: string,
    public type: FeedbackType,
    public details?: FeedbackDetails
  ) {
    this.id = uuidv7()
  }
}
