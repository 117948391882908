import styled, { css } from 'styled-components'

import { COLORS, GRADIENT } from '../base'
import {
  ellipsis,
  TextBody,
  TextBodySmall,
  TextBodySmallBold,
  textSmall,
} from './../base/typography'

export enum Border {
  TOP = 'top',
  BOTTOM = 'bottom',
  NONE = 'none',
}

const Node = styled.div<{ $isSelected: boolean }>`
  width: 175px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 13px;
  word-break: break-word;
  box-shadow: 3px 3px 15px -2px rgba(0, 0, 0, 0.4);
  gap: 16px;
  border-radius: 8px;
  ${props => props.$isSelected && selectedStyles};

  hr {
    height: 1px;
    margin: 0px -16px 0px;
    width: 207px;
    border: none;
  }

  span {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }
`

export const StyledList = styled.div<{
  $hasMarginTop?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: -0px -16px -16px;
  padding: 0px 16px 16px;
  background-color: ${COLORS.N50};
  padding-top: ${props => props.$hasMarginTop && '16px'};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const StyledInputList = styled.div`
  background: ${COLORS.N800};
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0px -16px -16px;
  padding: 16px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const selectedStyles = css`
  color: ${COLORS.WHITE} !important;
  background-color: ${COLORS.L500} !important;
  ${StyledList}, ${StyledInputList} {
    background-color: ${COLORS.L600} !important;
  }
  hr {
    background-color: ${COLORS.L400} !important;
  }
`

export const StyledButtonList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ButtonContainer = styled(TextBodySmall)<{
  $isReply?: boolean
  $isEmpty: boolean
}>`
  position: relative;
  display: flex;
  min-height: 42px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${COLORS.N50};
  color: ${COLORS.N900};
  gap: 8px;
  border-radius: 8px;

  svg {
    color: ${COLORS.N500};
  }

  ${props =>
    props.$isReply &&
    `border-radius: 28px; 
     max-width: 168px;   
     align-self: flex-end;`};

  ${props =>
    props.$isEmpty &&
    `justify-content: center; 
    color: ${COLORS.N500};`};
`

export const ConditionContainer = styled.div<{
  $isSmallCondition?: boolean
  $border: Border | null
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px -16px;
  padding: 0px 16px;
  font-size: 14px;
  ${props => props.$border === Border.TOP && topBorderCondition};
  ${props => props.$border === Border.BOTTOM && bottomBorderCondition};
  ${props => props.$border === Border.NONE && noneBorderCondition};
  ${props => props.$isSmallCondition && smallCondition};
`

const bottomBorderCondition = css`
  border-bottom: 1px solid;
  padding: 0px 16px 16px;
`

const topBorderCondition = css`
  border-top: 1px solid;
  padding: 16px 16px 0px;
`

const noneBorderCondition = css`
  padding: 16px 16px 0px;
`

const smallCondition = css`
  padding: 16px 0px 0px;
  margin: 0px;
`

export const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${TextBody} {
    ${ellipsis};
  }
`

const imageStyles = css`
  max-width: 207px;
  max-height: 207px;
  display: flex;
  margin: 0px -16px -8px;
  pointer-events: none;
  align-self: center;
`

export const ClosedElement = styled.div<{ $hasBorder: boolean }>`
  margin: 0px -16px;
  padding: 16px 16px 0px;
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  min-height: 20px;
  border-top: ${props => props.$hasBorder && '1px solid'};
  img {
    max-width: 20px;
    max-height: 20px;
  }
`

export const OpenedElement = styled.div<{ $hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0px -16px -16px;
  padding: 16px;
  border-top: ${props => props.$hasBorder && '1px solid'};
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  img {
    ${imageStyles};
    margin: 16px -16px;
  }
  ${ButtonContainer} {
    margin-top: 4px;
  }
`

export const MessageNode = styled(Node)`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.N900};
  svg {
    color: ${props => !props.$isSelected && COLORS.N500};
  }
  hr {
    background: ${COLORS.N100};
  }
  ${ConditionContainer},${ClosedElement}, ${OpenedElement} {
    border-color: ${props => (props.$isSelected ? COLORS.L400 : COLORS.N100)};
  }
  ${OpenedElement} {
    background: ${props => (props.$isSelected ? COLORS.L500 : COLORS.WHITE)};
  }
`

export const InputNode = styled(Node)`
  background-color: ${COLORS.N900};
  color: ${COLORS.WHITE};
  hr {
    background-color: ${COLORS.N800};
  }
`

export const AiInputNode = styled(InputNode)`
  background: ${props =>
    props.$isSelected
      ? `${GRADIENT.L500} padding-box, ${GRADIENT.AI_DARK} border-box;`
      : `${GRADIENT.N900} padding-box, ${GRADIENT.AI_DARK} border-box;`};
  border: 1px solid transparent;
`

export const AiMessageNode = styled(MessageNode)`
  background: ${props =>
    props.$isSelected
      ? `${GRADIENT.L500} padding-box, ${GRADIENT.AI_DARK} border-box;`
      : `${GRADIENT.WHITE} padding-box, ${GRADIENT.AI_LIGHT} border-box;`};
  border: 1px solid transparent;
`

export const ConditionalNode = styled(Node)`
  background-color: ${COLORS.N500};
  color: ${COLORS.WHITE};
  hr {
    background-color: ${COLORS.N400};
  }
  ${ConditionContainer} {
    border-color: ${props => (props.$isSelected ? COLORS.L400 : COLORS.N400)};
  }
  ${StyledList} {
    background-color: ${COLORS.N600};
  }
`

export const RoundNode = styled.div<{ $isSelected: boolean }>`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.N900};
  width: 300px;
  box-sizing: border-box;
  padding: 12px 24px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 72px;
  box-shadow: 0px 1.034px 2.068px 0px rgba(102, 102, 102, 0.1);

  ${props => props.$isSelected && selectedStyles};

  > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  > button:last-child {
    margin-left: auto;
    word-break: keep-all;
  }
`

export const StyledGoToFlowTitle = styled.div`
  width: 153px;
  box-sizing: border-box;

  > div:last-child {
    ${ellipsis};
  }
`

export const Header = styled.div`
  min-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  > div:last-child {
    display: flex;
    gap: 8px;
  }
`

export const Code = styled.div`
  position: absolute;
  top: -20px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 8px;
  color: ${COLORS.WHITE};

  svg {
    --fa-secondary-color: ${COLORS.O500};
    --fa-primary-color: ${COLORS.WHITE};
    --fa-secondary-opacity: 1;
  }
`

export const Title = styled(TextBodySmallBold)`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Text = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  p {
    margin: 0px;
  }
`

export const Image = styled.img`
  ${imageStyles}
`

export const StyledVideo = styled(StyledList)<{ $isSelected: boolean }>`
  ${textSmall}
  align-items: center;
  justify-content: center;
  padding: 24px 24px 16px;
  margin: 0px -16px -16px;

  svg {
    color: ${props => (props.$isSelected ? COLORS.L400 : COLORS.N300)};
    font-size: 50px;
  }

  span {
    ${ellipsis};
    display: inline;
    max-width: 100%;
  }
`

export const StyledConditionWithSubsection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

export const StyledCircle = styled.div<{ $color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.$color};
`

export const StyledWhatsappCTAFields = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: -4px 0px;
`

export const StyledNote = styled.div<{ $positionRight?: string }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -150px;
  right: ${props => props.$positionRight || '300px'};
  width: 280px;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  background: ${COLORS.WHITE};
  white-space: pre-wrap;
  cursor: default;

  > :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    svg {
      color: ${COLORS.N900};
    }

    button {
      margin-left: auto;
    }
  }

  > :last-child {
    margin-top: 8px;
  }
`

export const StyledKnowledgebaseFeedback = styled.div`
  position: absolute;
  bottom: -27px;
  right: 0px;
  background: inherit;
  color: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 4px;
  border-radius: 8px;
`
