import { getDateTime } from '../../../utils/date-utils'
import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupType } from '../../types'
import { HtButton, HtIcon, Icon, Size } from '../base'
import { NotificationContainer } from './version-banner-styles'

interface VersionBannerProps {
  restoreDraftFlow: () => Promise<void>
}

export const VersionBanner = ({ restoreDraftFlow }: VersionBannerProps) => {
  const analytics = useAnalytics()
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)
  const currentVersion = useFlowBuilderSelector(ctx => ctx.state.currentVersion)

  if (!currentVersion) return null

  const getTitle = () => {
    const dateTitle = `Published on ${getDateTime(currentVersion.createdAt)}`
    if (!currentVersion.user) return dateTitle
    return `${dateTitle} (${currentVersion.user.getUserName()})`
  }

  const restoreVersion = () => {
    analytics.trackEvent(TrackEventName.VERSION_CLICK_RESTORE_CTA)
    setPopupContent({ type: PopupType.RESTORE_VERSION })
  }

  const onCancel = () => {
    analytics.trackEvent(TrackEventName.VERSION_CLICK_CLOSE)
    restoreDraftFlow()
  }

  return (
    <NotificationContainer>
      {getTitle()}
      <HtButton
        size={Size.TINY}
        onClick={evt => {
          evt.stopPropagation()
          restoreVersion()
        }}
      >
        Restore to this version
      </HtButton>
      <HtIcon icon={Icon.XMARK} size={Size.MEDIUM} onClick={onCancel} />
    </NotificationContainer>
  )
}
