import { memo, useState } from 'react'

import { GoToFlowFields } from '../../../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { FALLBACK_FLOW, KNOWLEDGE_BASE_FLOW } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { Flow, PopupType } from '../../../../types'
import {
  HtBtnType,
  HtButton,
  HtSearchBar,
  HtSelectDropdown,
  Size,
  TextBodyBold,
} from '../../../base'
import { HtInfoPanel } from '../../../base/ht-info-panel/ht-info-panel'
import { StyledHeader } from '../../../flow-selector/flow-selector-styles'
import { FLOW } from '../../constants'
import { StyledDropdownFooter } from '../../editor-styles'
import { EditorProps } from '../../node-editor-panel'

interface GoToFlowEditorProps extends EditorProps {
  nodeContent: GoToFlowFields
}

export const GoToFlowEditor = memo(
  ({ nodeContent, ...props }: GoToFlowEditorProps): JSX.Element => {
    const analytics = useAnalytics()
    const flows = useFlowBuilderSelector(ctx => ctx.state.flows)
    const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

    const [isFlowDropdownOpen, setIsFlowDropdownOpen] = useState<boolean>(false)
    const [flowFilter, setFlowFilter] = useState<string>('')

    const onSelectFlow = (flow?: Flow) => {
      if (!flow) return
      analytics.trackEvent(TrackEventName.FLOW_CONNECT, {
        flow_name: flow.name,
        is_same_flow: flow.id === nodeContent.targetFlowId,
      })
      const value = flow?.id
      //@ts-ignore
      props.onChange({ type: FLOW.actionType, fieldKey: FLOW.key, value })
    }

    const addFlow = (evt: React.MouseEvent) => {
      evt.stopPropagation()
      setIsFlowDropdownOpen(false)
      setPopupContent({ type: PopupType.ADD_FLOW })
    }

    const onFilterChange = (filterValue: string) => {
      setFlowFilter(filterValue)
      if (!filterValue) return
      const matchingFlows = flows.filter(flow =>
        flow.name.toLowerCase().includes(filterValue.toLowerCase())
      )
      analytics.trackEvent(TrackEventName.GO_TO_FLOW_SEARCH_BAR, {
        number_of_results: matchingFlows.length,
      })
    }

    return (
      <>
        <TextBodyBold>Go to flow</TextBodyBold>
        <HtSelectDropdown
          {...props}
          isOpen={isFlowDropdownOpen}
          label={FLOW.label!}
          placeholder={FLOW.placeholder}
          helperText={FLOW.helperText}
          data={flows.filter(
            flow =>
              ![FALLBACK_FLOW.id, KNOWLEDGE_BASE_FLOW.id].includes(flow.id)
          )}
          selectedKey={nodeContent.targetFlowId}
          onChange={onSelectFlow}
          onOpenChange={setIsFlowDropdownOpen}
          width={'100%'}
          listProps={{ maxListHeight: 'calc(100vh - 400px)' }}
          filterValue={flowFilter}
          header={
            <StyledHeader>
              <HtSearchBar
                placeholder='Search flows'
                size={Size.SMALL}
                onFilterChange={onFilterChange}
              />
            </StyledHeader>
          }
          footer={
            <StyledDropdownFooter>
              <HtButton
                size={Size.TINY}
                type={HtBtnType.TERTIARY}
                onClick={addFlow}
              >
                Add flow
              </HtButton>
            </StyledDropdownFooter>
          }
        />
        <HtInfoPanel
          title='About Go to flow'
          text={
            'This component is meant to help you keep your conversational flow organized and divided into smaller flows so that it is easier to use and maintain. Besides, dividing your conversational flow into smaller flows will also let you connect multiple steps to another flow, providing an overall clearer flow visualization.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
