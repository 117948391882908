export const normalizeValue = (value: string): string => {
  return value.toLowerCase().trim()
}

export const isValidJSON = (value: string) => {
  try {
    const parsedJson = JSON.parse(value)
    if (typeof parsedJson === 'object' && parsedJson !== null) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const toSnakeCase = (value: string): string => {
  return value.replace(/\s+/g, '_')
}
