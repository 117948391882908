import { FeedbackType } from '../../../../domain/feedback'
import { HtIcon } from '../ht-icon/ht-icons'
import { getIconFromFeedbackType } from '../ht-icon/icon-utils'
import { Icon } from '../ht-icon/icons-list'
import { Size } from '../variants'
import { StyledNotification } from './ht-notification-styles'

export interface HtNotificationProps {
  children: React.ReactNode
  type: FeedbackType
  isAi?: boolean
  onClose: () => void
}

export const HtNotification = ({
  children,
  type,
  isAi,
  onClose,
}: HtNotificationProps): JSX.Element => {
  return (
    <StyledNotification $isAi={isAi}>
      <HtIcon icon={getIconFromFeedbackType(type)} size={Size.LARGE} />
      {children}
      <HtIcon icon={Icon.XMARK} onClick={onClose} size={Size.LARGE} />
    </StyledNotification>
  )
}
