import styled from 'styled-components'

import { COLORS, TextBodySmall, TextSmall } from '../base'

export const LocaleItemContainer = styled.div`
  position: relative;
  padding: 0px 35px 0px 16px;
  height: 50px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.N50};
`

export const Message = styled(TextSmall)<{ $isError?: boolean }>`
  font-size: 11px;
  color: ${props => (props.$isError ? COLORS.R400 : COLORS.N400)};
`

export const CopyFromContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.N400};
  gap: 8px;
`

export const EmptyLocales = styled(TextBodySmall)`
  margin: 10px 16px;
  color: ${COLORS.N500};
`
