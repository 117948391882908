import { Node } from '@react-types/shared'
import { useRef } from 'react'
import {
  useCheckbox,
  useGridListItem,
  useGridListSelectionCheckbox,
} from 'react-aria'
import { ListState, useToggleState } from 'react-stately'

import { DropdownData } from '../../common/dropdown/types'
import { HtCheckbox } from '../../ht-checkbox/ht-checkbox'
import { StyledListItem } from './multiselect-styles'

interface ListItemProps {
  state: ListState<DropdownData>
  item: Node<DropdownData>
}

export const ListItem = ({ item, state }: ListItemProps): JSX.Element => {
  const ref = useRef(null)
  const { rowProps, gridCellProps } = useGridListItem(
    { node: item },
    state,
    ref
  )

  return (
    <StyledListItem {...rowProps} {...gridCellProps} ref={ref}>
      <ItemCheckbox item={item} state={state} />
    </StyledListItem>
  )
}

const ItemCheckbox = ({ item, state }: ListItemProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { checkboxProps } = useGridListSelectionCheckbox(
    { key: item.key },
    state
  )
  const { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef
  )

  return <HtCheckbox {...inputProps} label={item.value?.name}></HtCheckbox>
}
