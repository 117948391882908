import { Edge } from 'reactflow'

import { FALLBACK_NODE_ID } from '../../../constants'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { EdgeAction } from './edge-action'

export interface RemoveEdgesInterface {
  type: ActionType.REMOVE_EDGES_BY_ID
  ids: string[]
}

export interface RemoveEdgesHistoryChange {
  type: ActionType.REMOVE_EDGES_BY_ID
  removedEdges: Edge[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class RemoveEdgesAction extends ReversibleAction {
  static apply = (state: State, { ids }: RemoveEdgesInterface): void => {
    const edgesToRemove = ids.reduce((acc: Edge[], sourceHandle) => {
      const edge = EdgeAction.getEdgeById(state, sourceHandle)
      if (edge && edge.target !== FALLBACK_NODE_ID) acc.push(edge)
      return acc
    }, [])
    if (edgesToRemove.length) {
      EdgeAction.removeEdges(state, edgesToRemove)
      this.trackHistoryChange(state, edgesToRemove)
    }
  }

  static undo = (state: State, change: RemoveEdgesHistoryChange) => {
    EdgeAction.addEdges(state, change.removedEdges)
  }

  static redo = (state: State, change: RemoveEdgesHistoryChange) => {
    EdgeAction.removeEdges(state, change.removedEdges)
  }

  private static trackHistoryChange = (state: State, removedEdges: Edge[]) => {
    const newChange: RemoveEdgesHistoryChange = {
      type: ActionType.REMOVE_EDGES_BY_ID,
      removedEdges,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }
}
