import {
  ActionType,
  ContentKey,
  InputAction,
  Label,
  Placeholder,
} from '../../UI/components/node-editor-panel/constants'

export const MAIN_TEXT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.TEXT,
  Label.MAIN_TEXT
)

export const HEADER = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.HEADER,
  Label.HEADER,
  undefined,
  Placeholder.HEADER
)

export const FOOTER = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.FOOTER,
  Label.FOOTER,
  undefined,
  Placeholder.FOOTER
)
export const BUTTON_TEXT = new InputAction(
  ActionType.UPDATE_BUTTON_CONTENT,
  ContentKey.TEXT,
  Label.BUTTON_TEXT
)

export const BUTTON_URL = new InputAction(
  ActionType.UPDATE_BUTTON_CONTENT,
  ContentKey.URL,
  Label.BUTTON_URL,
  undefined,
  Placeholder.URL
)
