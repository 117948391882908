import { TrackEventName, useAnalytics } from '../../../../analytics'
import { MembersInfo } from '../../../../custom-hooks/use-ably'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { COLORS, HtIcon, Icon, Size } from '../../../base'
import { Popup } from '../../popup'
import { ActiveUserInfo } from './active-user-info'
import {
  StyledEndSessionContainer,
  StyledSessionEndedTitle,
} from './session-styles'

export interface SessionEndedPopupProps {
  closeData: string | null
  membersInfo?: MembersInfo
}

export const SessionEndedPopup = ({
  closeData,
  membersInfo,
}: SessionEndedPopupProps): JSX.Element => {
  const analytics = useAnalytics()
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.USER_KICKED_OUT)
    setPopupContent()
    postCloseFromFlowBuilderMessage(closeData)
  }

  const SessionEndedTitle = () => (
    <StyledSessionEndedTitle>
      <HtIcon icon={Icon.CIRCLE_EXCLAMATION} size={Size.MEDIUM} />
      {PopupType.SESSION_ENDED}
    </StyledSessionEndedTitle>
  )

  return (
    <Popup
      title={<SessionEndedTitle />}
      discardButton={{
        text: 'Close',
        onClick: discard,
      }}
      message={
        'Your session has been ended by another user. All changes have been saved.'
      }
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    >
      <StyledEndSessionContainer>
        <ActiveUserInfo membersInfo={membersInfo} color={COLORS.N900} />
      </StyledEndSessionContainer>
    </Popup>
  )
}
