import { Locale } from '../../../../domain/models/locales/locale'
import { State } from '../../../types'
import { ActionType } from '../../action-types'

export interface SetLocalesInterface {
  type: ActionType.SET_LOCALES
  locales: Locale[]
}

export class SetLocalesAction {
  static apply = (state: State, { locales }: SetLocalesInterface): void => {
    state.locales = locales
    if (!state.currentLocale) {
      state.currentLocale = locales[0]
    }
  }
}
