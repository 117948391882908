import { FeedbackMessage } from '../../../../domain/feedback'
import { State } from '../../../types'
import { ActionType } from '../../action-types'

export interface RemoveFeedbackMessagesInterface {
  type: ActionType.REMOVE_FEEDBACK_MESSAGES
  messages?: FeedbackMessage[]
}

export class RemoveFeedbackMessagesAction {
  static apply = (
    state: State,
    { messages }: RemoveFeedbackMessagesInterface
  ): void => {
    this.removeFeedbackMessages(state, messages)
  }

  private static removeFeedbackMessages = (
    state: State,
    feedbackToRemove: FeedbackMessage[] = []
  ): void => {
    if (feedbackToRemove.length === 0) {
      state.feedback = []
      return
    }

    const messageIdsToRemove = new Set(feedbackToRemove.map(f => f.id))
    state.feedback = state.feedback.filter(
      feedback => !messageIdsToRemove.has(feedback.id)
    )
  }
}
