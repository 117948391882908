import styled from 'styled-components'

import { COLORS } from '../base'

export const ControlsContainer = styled.div<{ $isEditorOpen?: boolean }>`
  position: absolute;
  z-index: 5;
  bottom: 16px;
  right: ${props => (props.$isEditorOpen ? '304px' : '16px')};
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.N50};
  border-radius: 4px;
  overflow: hidden;
  > * {
    &:last-child {
      border-bottom: none;
    }
  }
`

export const UndoRedoControlsContainer = styled(ControlsContainer)`
  bottom: 107px;
`

export const StyledControlButton = styled.button`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom: 1px solid ${COLORS.N200};
  cursor: pointer;

  svg {
    color: ${COLORS.N500};
  }

  &:disabled {
    cursor: default;
    svg {
      color: ${COLORS.N300};
    }
  }

  &:focus {
    outline: 0;
  }
`
