import { useFlowBuilderSelector } from '../../reducer/hooks'
import { LoadingMessage } from '../../types'
import { useFlowBuilderFeedback } from '../../use-flow-builder-feedback'
import { useSave } from '../header/save/use-save'

export const useRestoreVersion = () => {
  const { saveFlow } = useSave()
  const { reportVersionRestored } = useFlowBuilderFeedback()
  const { state, setCurrentVersion, setLoadingMessage, toggleInteractivity } =
    useFlowBuilderSelector(ctx => ctx)

  const restoreVersion = async (): Promise<void> => {
    if (!state.currentVersion) return
    setLoadingMessage(LoadingMessage.RESTORING_VERSION)
    const isSaved = await saveFlow(undefined, true)
    setLoadingMessage(undefined)

    if (isSaved) {
      reportVersionRestored(true, state.currentVersion.createdAt)
      setCurrentVersion(undefined)
      toggleInteractivity(true)
    }
  }

  return restoreVersion
}
