import { styled } from 'styled-components'

import { COLORS } from '../../../base'

export const StyledTransferSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${COLORS.N100};
`
