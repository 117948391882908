export const WEBVIEW_PREFIX = 'webview_'

export const isWebview = (id: string): boolean => {
  return id.includes(WEBVIEW_PREFIX)
}

export const webviewIdToFlowId = (id: string): string => {
  return id.split('_')[1]
}

export const flowIdToWebviewId = (id: string): string => {
  return `${WEBVIEW_PREFIX}${id}`
}
