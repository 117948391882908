import { animate } from 'popmotion'
import { ReactFlowInstance, Viewport } from 'reactflow'

import { ContentType } from '../../domain/models/content-fields'
import {
  BUILD_PANEL_WIDTH,
  INITIAL_VIEWPORT,
  LEFT_PANEL_MARGIN,
  NODE_WIDTH,
} from '../constants'
import { NodeTypes, State } from '../types'

export class ViewportAnimator {
  static centerNode = (
    state: State,
    nodeId: string,
    selectNode: (node: NodeTypes) => void
  ): void => {
    const nodeToCenter = state.nodes.find(node => node.id === nodeId)
    if (!nodeToCenter) return
    this.animateCenterNode(state, nodeToCenter)
    selectNode(nodeToCenter)
  }

  static centerStartNode = (state: State): void => {
    const nodeToCenter = state.nodes.find(
      node => node.type === ContentType.START
    )
    if (!nodeToCenter) return
    this.animateCenterInitialViewport(state)
  }

  protected static animateCenterNode = (state: State, node: NodeTypes) => {
    const { reactFlowInstance, reactFlowWrapper } = state
    if (!reactFlowInstance || !reactFlowWrapper?.current) return
    const initialViewport = this.getInitialViewport(
      reactFlowInstance,
      reactFlowWrapper.current
    )
    const finalViewport = this.getFinalViewport(node, state)
    animate({
      from: initialViewport,
      to: finalViewport,
      onUpdate: ({ x, y, zoom }) => reactFlowInstance.setCenter(x, y, { zoom }),
      duration: 1000,
    })
  }

  protected static animateCenterInitialViewport = (state: State) => {
    const { reactFlowInstance, reactFlowWrapper } = state
    if (!reactFlowInstance || !reactFlowWrapper?.current) return
    const initialViewport = reactFlowInstance.getViewport()
    const finalViewport = { ...INITIAL_VIEWPORT, zoom: initialViewport.zoom }
    animate({
      from: initialViewport,
      to: finalViewport,
      onUpdate: ({ x, y, zoom }) =>
        reactFlowInstance.setViewport({ x, y, zoom }),
      duration: 1000,
    })
  }

  protected static getFinalViewport = (
    node: NodeTypes,
    state: State
  ): Viewport => {
    const zoom = state.reactFlowInstance?.getZoom() || 1
    let x = node.position.x + NODE_WIDTH / 2
    x -= (BUILD_PANEL_WIDTH + LEFT_PANEL_MARGIN) / (2 * zoom)
    const y = node.position.y + 200 / zoom
    return { x, y, zoom }
  }

  protected static getInitialViewport = (
    reactFlowInstance: ReactFlowInstance,
    reactFlowElement: HTMLDivElement
  ): Viewport => {
    const { width, height } = reactFlowElement.getBoundingClientRect()
    const zoom = reactFlowInstance.getZoom()
    const position = reactFlowInstance.project({
      x: width / 2,
      y: height / 2,
    })
    return { ...position, zoom }
  }
}
