import styled from 'styled-components'

import { COLORS } from '../colors'
import { TextBodyBold, textBodySmall } from '../typography'

export const StyledModal = styled.div`
  ${textBodySmall}
  width: 516px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12);

  form {
    margin-top: 16px;
  }

  &:focus {
    outline: 0;
  }
`

export const ModalHeader = styled(TextBodyBold)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -16px;
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -8px;
  gap: 16px;
`
