import { aiActionMap } from './actions/ai-actions'
import { edgeActionMap } from './actions/edge-actions'
import { flowActionMap } from './actions/flow-actions'
import { localeActionMap } from './actions/locale-actions'
import { nodeActionMap } from './actions/node-actions'
import { nonMessageActionMap } from './actions/non-message-actions'
import { webviewActionMap } from './actions/webview-actions'

export const actionMap = {
  ...aiActionMap,
  ...edgeActionMap,
  ...flowActionMap,
  ...localeActionMap,
  ...nodeActionMap,
  ...nonMessageActionMap,
  ...webviewActionMap,
}
