import { memo } from 'react'

import { HTTP_URL_REGEX } from '../../constants'
import { VideoNodeProps } from '../../types'
import { HtIcon, Icon } from '../base'
import { Handles, NodeHeader } from './node-components'
import { MessageNode, StyledVideo } from './node-styles'

const VideoNode = ({ data, selected }: VideoNodeProps) => {
  function isVideoValid(): boolean {
    if (!data.videoUrl) return false
    return HTTP_URL_REGEX.test(data.videoUrl)
  }
  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Video'} icon={Icon.FILM} />
      {isVideoValid() && (
        <StyledVideo $isSelected={selected}>
          <HtIcon icon={Icon.FILM} />
          <span>{data.videoUrl}</span>
        </StyledVideo>
      )}
    </MessageNode>
  )
}

export default memo(VideoNode)
