import { useEffect, useState } from 'react'

import { HelperText } from '../base-styles'

interface CharacterCounterProps {
  enabled?: boolean
  maxLength?: number
  textValue: string
}
export const CharacterCounter = ({
  enabled,
  maxLength,
  textValue,
}: CharacterCounterProps) => {
  const [characterCount, setCharacterCount] = useState(0)

  useEffect(() => {
    setCharacterCount(textValue.length)
  }, [textValue])

  if (maxLength) {
    return (
      <HelperText>
        {characterCount}/{maxLength}
      </HelperText>
    )
  }

  if (!enabled) return null

  return <HelperText>{characterCount}</HelperText>
}
