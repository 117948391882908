import styled, { css, keyframes } from 'styled-components'

const visibilityTransition = css<{ $isActive: boolean }>`
  visibility: ${props => (props.$isActive ? 'visible' : 'hidden')};
  transition: visibility 0.3s linear;
`

export const FadeInOutContainer = styled.div<{ $isActive: boolean }>`
  ${visibilityTransition};
  animation: ${props => (props.$isActive ? fadeIn : fadeOut)} 0.3s ease;
`

export const FadeOutContainer = styled.div<{ $isActive: boolean }>`
  ${visibilityTransition};
  animation: ${props => !props.$isActive && fadeOut} 0.3s ease;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
