import { FeedbackType } from '../../../../domain/feedback'
import { ROLES } from '../../../roles'
import { HtBtnType, HtButton } from '../ht-button/ht-button'
import { HtIcon } from '../ht-icon/ht-icons'
import { getIconFromFeedbackType } from '../ht-icon/icon-utils'
import { Icon } from '../ht-icon/icons-list'
import { TextBodySmallBold, TextSmall } from '../typography'
import { Size } from '../variants'
import { SnackbarContainer } from './ht-snackbar-styles'

export interface HtSnackbarProps {
  text: string
  title: string
  type: FeedbackType
  onClose: () => void
}

export const HtSnackbar = ({
  text,
  title,
  type,
  onClose,
}: HtSnackbarProps): JSX.Element => {
  return (
    <SnackbarContainer $type={type} role={ROLES.ALERT}>
      <HtIcon icon={getIconFromFeedbackType(type)} size={Size.LARGE} />
      <div>
        <TextBodySmallBold>{title}</TextBodySmallBold>
        <TextSmall>{text}</TextSmall>
      </div>
      <HtButton type={HtBtnType.ICON_ONLY} size={Size.SMALL} onClick={onClose}>
        <HtIcon icon={Icon.XMARK} />
      </HtButton>
    </SnackbarContainer>
  )
}
