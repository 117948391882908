import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { iconMediumSize, iconSmallSize } from '../ht-icon/ht-icon-styles'
import { Size } from '../variants'
import { HtBtnSize } from './ht-button'

export const StyledIconOnlyButton = styled.button<{
  $size: HtBtnSize
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;

  ${props => props.$size === Size.LARGE && sizeLarge};
  ${props => props.$size === Size.MEDIUM && sizeMedium};
  ${props => props.$size === Size.SMALL && sizeSmall};
  ${props => props.$size === Size.TINY && sizeTiny};

  svg {
    color: ${COLORS.N900};
    &:disabled {
      color: ${COLORS.N200};
    }
  }

  &:hover:not([disabled]) {
    background: rgba(39, 38, 43, 0.05);
  }

  &:disabled {
    cursor: initial;
  }

  &:focus {
    outline: 0;
  }
`

// css icon button size
const sizeLarge = css`
  height: 48px;
  width: 48px;
  padding: 16px;
  border-radius: 8px;

  svg {
    ${iconMediumSize}
  }
`

const sizeMedium = css`
  height: 45px;
  width: 45px;
  padding: 16px;
  border-radius: 8px;

  svg {
    ${iconSmallSize}
  }
`

const sizeSmall = css`
  height: 37px;
  width: 37px;
  padding: 12px;
  border-radius: 8px;

  svg {
    ${iconSmallSize}
  }
`

const sizeTiny = css`
  height: 26px;
  width: 26px;
  padding: 8px;
  border-radius: 4px;

  svg {
    ${iconSmallSize}
  }
`
