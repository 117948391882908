import { State } from '../../types'
import { actionMap } from '../action-map'
import { ChangesHistory } from '../action-types'

export class RedoAction {
  static apply = (state: State) => {
    const changeToRedo = state.changeHistory[state.historyIndex + 1]
    if (!changeToRedo) return
    if ('currentFlowId' in changeToRedo) {
      state.currentFlowId = changeToRedo.currentFlowId
    }
    this.revokeChange(state, changeToRedo)
    state.historyIndex++
  }

  private static revokeChange = (state: State, change: ChangesHistory) => {
    const actionClass = actionMap[change.type]
    if (actionClass) {
      actionClass.redo(state, change as any)
    }
  }
}
