import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { HtBtnType, HtButton, Size } from '../../base'
import { useCloseFromHubtype } from './use-close-from-hubtype'
import { useExit } from './use-exit'

export const ExitButton = (): JSX.Element => {
  const { exitFlow } = useExit()
  const { state } = useFlowBuilderSelector(ctx => ctx)

  useCloseFromHubtype(state.isFlowSaved, exitFlow)

  const onExit = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    evt.currentTarget.blur()
    exitFlow()
  }

  return (
    <HtButton size={Size.TINY} type={HtBtnType.TERTIARY} onClick={onExit}>
      Exit
    </HtButton>
  )
}
