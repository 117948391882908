import styled from 'styled-components'

import { TextBody } from '../typography'
import { Size } from '../variants'

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledSwitch = styled.label<{
  $isDisabled?: boolean
  $size: Size
}>`
  cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  opacity: ${props => (props.$isDisabled ? 0.4 : 1)};

  ${TextBody} {
    font-size: ${props => (props.$size === Size.SMALL ? '12px' : '14px')};
    user-select: none;
    -webkit-user-select: none;
  }
`
