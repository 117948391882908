import { memo } from 'react'

import { KeywordFields } from '../../../../../domain/models/content-fields'
import { HtInfoPanel } from '../../../base'
import { KEYWORDS } from '../../constants'
import { EditorTitle } from '../../editor-styles'
import { EditorProps } from '../../node-editor-panel'
import { KeywordsField } from './keywords-widget'

interface KeywordEditorProps extends EditorProps {
  nodeContent: KeywordFields
}

export const KeywordEditor = memo(
  ({ nodeContent, ...props }: KeywordEditorProps): JSX.Element => {
    return (
      <>
        <EditorTitle>Keywords</EditorTitle>
        <KeywordsField
          {...props}
          field={KEYWORDS}
          values={nodeContent.keywords}
        />
        <HtInfoPanel
          title='About keywords'
          text={
            'Enhance your conversational app by incorporating keywords. These keywords enable the app to seamlessly guide your customers to predefined flow paths when they’re mentioned in the conversation.\n\nYou can finely tune your criteria to precisely match patterns in your data by entering standard regex (regular expressions) commands.\n\nRegex commands allow for sophisticated pattern matching and manipulation, offering unparalleled flexibility when looking to validate an input based on complex patterns.'
          }
        />
      </>
    )
  }
)
