import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import styled from 'styled-components'

import { COLORS } from '../components/base'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    background: COLORS.N800,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1)',
    fontSize: '12px',
    maxWidth: '210px',
  },
  zIndex: '100001 !important',
}))
