import styled from 'styled-components'

import { COLORS } from '../colors'

export const StyledLoading = styled.div`
  background: ${COLORS.WHITE};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 80px;
  gap: 12px;
`
