import { memo, useEffect, useState } from 'react'

import {
  FALLBACK_FLOW,
  HIDDEN_FLOW_DESCRIPTIONS,
  KNOWLEDGE_BASE_FLOW,
  MAIN_FLOW,
} from '../../constants'
import {
  HtBtnType,
  HtButton,
  HtCheckbox,
  HtIcon,
  Icon,
  Size,
  TextBodySmallBold,
  TextSmall,
} from '../base'
import { StyledNote } from './node-styles'

interface FlowNoteProps {
  flowId: string
  $positionRight?: string
}

export const FlowNote = memo(({ flowId, $positionRight }: FlowNoteProps) => {
  const [notShowAgain, setNotShowAgain] = useState(false)
  const [idDescriptionHidden, setIsDescriptionHidden] = useState(true)

  useEffect(() => {
    setNotShowAgain(false)
    const hiddenFlows = getHiddenFlowsIds()
    setIsDescriptionHidden(hiddenFlows.includes(flowId))
  }, [flowId])

  const getHiddenFlowsIds = (): string[] => {
    return JSON.parse(
      window.localStorage.getItem(HIDDEN_FLOW_DESCRIPTIONS) || '[]'
    )
  }

  const setHiddenFlowsIds = (hiddenFlows: string[]) => {
    window.localStorage.setItem(
      HIDDEN_FLOW_DESCRIPTIONS,
      JSON.stringify(hiddenFlows)
    )
  }

  const hideDescription = () => {
    if (notShowAgain) {
      const hiddenFlows = getHiddenFlowsIds()
      hiddenFlows.push(flowId)
      setHiddenFlowsIds(hiddenFlows)
    }
    setIsDescriptionHidden(true)
  }

  const flowDescriptions = {
    [MAIN_FLOW.id]: {
      title: 'Main flow',
      content:
        'This is the start of the conversational. You can begin by adding the first messages you’d like your customers to receive and then create other flows for specific use cases to which you can redirect your customers to by the use of the ‘Go to flow” component.\n\nKeep in mind that if customers type in their inquiries, they can be detected by using Keywords, Smart intents or Intents and, if these fail to detect what they are looking for, the knowledge base can try to respond by AI generated responses before moving on to the Fallback flow, where customers can be given options to help them continue the conversation.',
    },
    [FALLBACK_FLOW.id]: {
      title: 'Fallback flow',
      content:
        'This flow will be triggered anytime your customers send and inquiry that the conversational app can’t detect neither as an intent or a keyword and that the knowledge base can’t answer (in case it is activated).\n\nA good use for this flow would be to give customers options on how to proceed since the conversational app couldn’t help on their previous inquiry, for example suggesting to rephrase their question or offering the option to talk to an agent.',
    },

    [KNOWLEDGE_BASE_FLOW.id]: {
      title: 'Knowledge base flow',
      content:
        'In this flow you can set the sources that will provide knowledge to AI as well as the conversational flow once an AI response has been triggered.\n\nYou can set different knowledge base messages depending on conditions by adding them right before each knowledge base component, for example to gather different sources depending on the country where the user is located.',
    },
  }

  if (idDescriptionHidden || !flowDescriptions[flowId]) return <></>

  return (
    <StyledNote $positionRight={$positionRight}>
      <div>
        <HtIcon icon={Icon.CIRCLE_INFO} prefix='fas' size={Size.MEDIUM} />
        <TextBodySmallBold>{flowDescriptions[flowId].title}</TextBodySmallBold>
        <HtButton
          type={HtBtnType.ICON_ONLY}
          size={Size.TINY}
          onClick={hideDescription}
        >
          <HtIcon icon={Icon.XMARK} />
        </HtButton>
      </div>
      <TextSmall>{flowDescriptions[flowId].content}</TextSmall>
      <HtCheckbox
        label='Don’t show this message again.'
        checked={notShowAgain}
        onChange={evt => setNotShowAgain(evt.target.checked)}
      />
    </StyledNote>
  )
})
