import { Flow, State } from '../../../types'
import { ActionType } from '../../action-types'
import { FlowAction } from './flow-action'

export interface SetFlowsInterface {
  type: ActionType.SET_FLOWS
  flows: Flow[]
}

export class SetFlowsAction {
  static apply = (state: State, { flows }: SetFlowsInterface): void => {
    FlowAction.setFlows(state, flows)
  }
}
