import { useEffect } from 'react'

import { SaveOrigin } from '../../../analytics'
import { useDebounce } from '../../../custom-hooks/use-debounce'
import { State } from '../../../types'

const DELAY_TIME_IN_SECONDS = 3000
const DEBOUNCE_TIME_IN_SECONDS = 4000

export const useAutoSave = (
  state: State,
  save: (origin: SaveOrigin) => Promise<boolean>
) => {
  const debouncedNodes = useDebounce(state.nodes, DEBOUNCE_TIME_IN_SECONDS)
  const debouncedFlows = useDebounce(state.flows, DEBOUNCE_TIME_IN_SECONDS)

  useEffect(() => {
    const interval = setInterval(async () => {
      await save(SaveOrigin.AUTO_SAVE)
    }, DELAY_TIME_IN_SECONDS)

    return () => clearInterval(interval)
  }, [save, debouncedNodes, debouncedFlows])
}
