import { memo } from 'react'

import {
  CarouselFields,
  ContentType,
  ElementFields,
} from '../../../../../domain/models/content-fields'
import { TextBodyBold, TextBodySmall, TextBodySmallBold } from '../../../base'
import { ActionType, ADD_ELEMENT } from '../../constants'
import { SubContentTitle } from '../../editor-styles'
import { ButtonWidget } from '../../editor-widgets/button-widget'
import { SortableContentWidget } from '../../editor-widgets/sortable-widget/sortable-content-widget'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'
import { ElementEditor } from './element-editor'

interface CarouselEditorProps extends EditorProps {
  nodeContent: CarouselFields
}

export const CarouselEditor = memo(
  ({ nodeContent, ...props }: CarouselEditorProps): JSX.Element => {
    const isElementSelected = (element: ElementFields) => {
      return element.id === nodeContent.selectedSubContentId
    }

    return (
      <>
        <TextBodyBold>Carousel</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        {nodeContent.elements.length > 0 && (
          <SortableContentWidget
            {...props}
            contentType={ContentType.ELEMENT}
            nodeId={nodeContent.id}
            sortableContents={nodeContent.elements}
            selectedContentId={nodeContent.selectedSubContentId}
            isSelectable={true}
            isRemovable={nodeContent.elements.length > 1}
            onRemove={element => {
              props.onChange({
                elementToDelete: element,
                type: ActionType.DELETE_ELEMENT,
              })
            }}
            header={(element, i) => (
              <SubContentTitle>
                <TextBodySmallBold>{`Element ${i + 1}`}</TextBodySmallBold>
                {element.title && (
                  <TextBodySmall>{element.title}</TextBodySmall>
                )}
              </SubContentTitle>
            )}
          >
            {(element, i) => (
              <ElementEditor
                {...props}
                index={i}
                element={element}
                isSelected={isElementSelected(element)}
                isRemovable={nodeContent.elements.length > 1}
              />
            )}
          </SortableContentWidget>
        )}
        <ButtonWidget {...props} field={ADD_ELEMENT} />
      </>
    )
  }
)
