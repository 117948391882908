import { KNOWLEDGE_BASE_FLOW } from '../../../constants'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { FlowAction } from '../flow-actions/flow-action'
import { ReversibleAction } from '../reversible-action'

export interface SetKnowledgeBaseActiveInterface {
  type: ActionType.SET_KNOWLEDGE_BASE_ACTIVE
  isKnowledgeBaseActive: boolean
  isReversible?: boolean
}

export interface SetKnowledgeBaseActiveHistoryChange {
  type: ActionType.SET_KNOWLEDGE_BASE_ACTIVE
  currentNode?: NodeTypes
  currentFlowId: string
}

export class SetKnowledgeBaseActiveAction extends ReversibleAction {
  static apply = (
    state: State,
    {
      isKnowledgeBaseActive,
      isReversible = true,
    }: SetKnowledgeBaseActiveInterface
  ): void => {
    if (state.isKnowledgeBaseActive === isKnowledgeBaseActive) return
    if (!isReversible) {
      state.isKnowledgeBaseActive = isKnowledgeBaseActive
      return
    }
    this.trackHistoryChange(state)
    this.toggleKnowledgeBase(state, isKnowledgeBaseActive)
  }

  static undo = (state: State): void => {
    this.toggleKnowledgeBase(state, !state.isKnowledgeBaseActive)
  }

  static redo = (state: State): void => {
    this.toggleKnowledgeBase(state, !state.isKnowledgeBaseActive)
  }

  private static trackHistoryChange = (state: State): void => {
    const newChange: SetKnowledgeBaseActiveHistoryChange = {
      type: ActionType.SET_KNOWLEDGE_BASE_ACTIVE,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static toggleKnowledgeBase = (
    state: State,
    isKnowledgeBaseActive: boolean
  ): void => {
    if (isKnowledgeBaseActive) {
      this.activateKnowledgeBase(state)
    } else {
      this.deactivateKnowledgeBase(state)
    }
  }

  private static activateKnowledgeBase = (state: State): void => {
    state.isKnowledgeBaseActive = true
    FlowAction.addFlow(state, KNOWLEDGE_BASE_FLOW)
    FlowAction.selectFlow(state, KNOWLEDGE_BASE_FLOW.id)
  }

  private static deactivateKnowledgeBase = (state: State): void => {
    state.isKnowledgeBaseActive = false
    FlowAction.removeFlow(state, KNOWLEDGE_BASE_FLOW)
    if (state.currentNode?.data.flowId === KNOWLEDGE_BASE_FLOW.id) {
      state.currentNode = undefined
    }
  }
}
