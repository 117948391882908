import { ActionType } from '../../action-types'
import {
  ConnectNodesAction,
  ConnectNodesHistoryChange,
  ConnectNodesInterface,
} from './connect-nodes'
import {
  RemoveEdgesAction,
  RemoveEdgesHistoryChange,
  RemoveEdgesInterface,
} from './remove-edge'
import { SelectEdgeAction, SelectEdgesInterface } from './select-edges'

export { ConnectNodesAction, RemoveEdgesAction, SelectEdgeAction }

export type EdgeActionInterface =
  | ConnectNodesInterface
  | SelectEdgesInterface
  | RemoveEdgesInterface

export type EdgeChangesHistory =
  | ConnectNodesHistoryChange
  | RemoveEdgesHistoryChange

export const edgeActionMap = {
  [ActionType.CONNECT_NODES]: ConnectNodesAction,
  [ActionType.SELECT_EDGES]: SelectEdgeAction,
  [ActionType.REMOVE_EDGES_BY_ID]: RemoveEdgesAction,
}
