import { useRef } from 'react'
import type { AriaMenuProps } from 'react-aria'
import { useMenu } from 'react-aria'
import { useTreeState } from 'react-stately'

import { StyledList } from '../../common/dropdown/styles'
import { MenuItem } from './menu-item'

export const MenuList = <T extends object>(props: AriaMenuProps<T>) => {
  const state = useTreeState(props)
  const ref = useRef(null)
  const { menuProps } = useMenu(props, state, ref)

  return (
    <StyledList {...menuProps} ref={ref}>
      {[...state.collection].map(item => (
        <MenuItem key={item.key} item={item} state={state} />
      ))}
    </StyledList>
  )
}
