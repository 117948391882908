import { ActionType } from '../../action-types'
import {
  AddPayloadsAction,
  AddPayloadsHistoryChange,
  AddPayloadsInterface,
} from './add-payloads'
import { AddUrlAction, AddUrlHistoryChange, AddUrlInterface } from './add-url'
import {
  EditUrlAction,
  EditUrlHistoryChange,
  EditUrlInterface,
} from './edit-url'
import {
  RemovePayloadsAction,
  RemovePayloadsHistoryChange,
  RemovePayloadsInterface,
} from './remove-payload'
import {
  RemoveUrlsAction,
  RemoveUrlsHistoryChange,
  RemoveUrlsInterface,
} from './remove-url'
import {
  SetBotVariablesAction,
  SetBotVariablesInterface,
} from './set-bot-variables'

export {
  AddPayloadsAction,
  AddUrlAction,
  EditUrlAction,
  RemovePayloadsAction,
  RemoveUrlsAction,
  SetBotVariablesAction,
}

export type NonMessageActionInterface =
  | AddPayloadsInterface
  | AddUrlInterface
  | EditUrlInterface
  | RemovePayloadsInterface
  | RemoveUrlsInterface
  | SetBotVariablesInterface

export type NonMessageChangesHistory =
  | AddPayloadsHistoryChange
  | AddUrlHistoryChange
  | EditUrlHistoryChange
  | RemovePayloadsHistoryChange
  | RemoveUrlsHistoryChange

export const nonMessageActionMap = {
  [ActionType.ADD_PAYLOAD]: AddPayloadsAction,
  [ActionType.ADD_URL]: AddUrlAction,
  [ActionType.EDIT_URL]: EditUrlAction,
  [ActionType.REMOVE_PAYLOAD]: RemovePayloadsAction,
  [ActionType.REMOVE_URL]: RemoveUrlsAction,
  [ActionType.SET_BOT_VARIABLES]: SetBotVariablesAction,
}
