import { memo } from 'react'

import { HtLoader } from '../ht-loader/ht-loader'
import { Size } from '../variants'
import { StyledButton } from './ht-button-styles'
import { StyledIconOnlyButton } from './ht-icon-only-button-styles'

export enum HtBtnType {
  PRIMARY = 'primary',
  PRIMARY_GREY = 'primary-grey',
  SECONDARY = 'secondary',
  SECONDARY_WHITE = 'secondary-white',
  TERTIARY = 'Tertiary',
  BORDERLESS = 'borderless',
  BORDERLESS_BLACK = 'borderless-black',
  DANGER = 'danger',
  ICON_ONLY = 'icon-only',
  AI = 'ai',
}

export type HtBtnSize = Size

export interface HtButtonProps {
  children?: any
  disabled?: boolean
  size?: HtBtnSize
  type?: HtBtnType
  ref?: React.RefObject<HTMLButtonElement>
  isLoading?: boolean
  onClick?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onPointerUp?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface HtButtonPropsWithOnClick extends HtButtonProps {
  onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface HtButtonPropsWithPointerUp extends HtButtonProps {
  onPointerUp: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const HtButton = memo(
  ({
    type = HtBtnType.PRIMARY,
    size = Size.MEDIUM,
    disabled,
    children,
    ref,
    isLoading,
    onClick,
    onPointerUp,
  }: HtButtonPropsWithPointerUp | HtButtonPropsWithOnClick): JSX.Element => {
    const props = {
      ref,
      $size: size,
      $type: type,
      disabled: disabled || isLoading,
      onClick,
      onPointerUp,
    }
    const Component =
      type === HtBtnType.ICON_ONLY ? StyledIconOnlyButton : StyledButton
    return (
      <Component {...props}>
        <span>
          {children}
          {isLoading && <HtLoader size={size} />}
        </span>
      </Component>
    )
  }
)
