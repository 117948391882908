import { Item, Section } from 'react-stately'

import { flatten } from '../../../../utils/array-utils'
import { DropdownData } from '../common/dropdown/types'
import { HtSelectDropdownProps } from './ht-select-dropdown'
import { Select } from './widgets/select'

export interface MultisectionDropdownData<T extends DropdownData> {
  title?: string
  items: T[]
}

interface HtMultisectionDropdownProps<T extends DropdownData>
  extends Omit<HtSelectDropdownProps<T>, 'data'> {
  data: MultisectionDropdownData<T>[]
}

export const HtMultisectionDropdown = <T extends DropdownData>(
  props: HtMultisectionDropdownProps<T>
): JSX.Element => {
  const onSelectionChange = (id: React.Key) => {
    const flattenedData = flatten(props.data.map(s => s.items))
    const selectedValue = flattenedData.find(item => item.id === id)
    props.onChange(selectedValue)
  }

  return (
    <Select
      {...props}
      items={props.data}
      placeholder={props.placeholder || props.label}
      onSelectionChange={onSelectionChange}
      selectedKey={props.selectedKey || null}
    >
      {item => (
        <Section key={item.title} items={item.items} title={item.title}>
          {item => (
            <Item textValue={item.name || item.id}>
              {props.children?.(item) || item.name || item.id}
            </Item>
          )}
        </Section>
      )}
    </Select>
  )
}
