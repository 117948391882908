import { HtBtnType, HtButton } from '../ht-button/ht-button'
import { HtIcon } from '../ht-icon/ht-icons'
import { Icon } from '../ht-icon/icons-list'
import { TextSmall } from '../typography'
import { Size } from '../variants'
import { StyledAiNotificationWithActions } from './ht-notification-styles'

interface NotificationButton {
  text: string
  icon?: Icon
  onClick: () => void
}

export interface HtAiNotificationWithActionsProps {
  confirmButton: NotificationButton
  discardButton: NotificationButton
  isLoading?: boolean
  children: string
}

export const HtAiNotificationWithActions = ({
  confirmButton,
  discardButton,
  isLoading,
  children,
}: HtAiNotificationWithActionsProps): JSX.Element => {
  return (
    <StyledAiNotificationWithActions>
      <div>
        <TextSmall>{children}</TextSmall>
      </div>
      <div>
        <HtButton
          type={HtBtnType.BORDERLESS}
          size={Size.TINY}
          disabled={isLoading}
          onClick={discardButton.onClick}
        >
          {discardButton.text}
        </HtButton>
        <HtButton
          type={HtBtnType.AI}
          size={Size.TINY}
          isLoading={isLoading}
          onClick={confirmButton.onClick}
        >
          {confirmButton.icon && <HtIcon icon={confirmButton.icon} />}
          {confirmButton.text}
        </HtButton>
      </div>
    </StyledAiNotificationWithActions>
  )
}
