import { memo, useEffect, useRef, useState } from 'react'

import { INPUT_DEBOUNCE_DELAY } from '../../../constants'
import { useDebounce } from '../../../custom-hooks'
import { HtInput, HtInputProps } from '../../base'
import { InputAction } from '../constants'
import { TextFieldContainer } from '../editor-styles'
import { BotVariablesDropdown } from './bot-variables-dropdown'

interface TextFieldProps extends HtInputProps {
  id: string
  changeProps?: {
    buttonIndex?: number
    elementIndex?: number
    sectionId?: string
    rowId?: string
    conditionValueId?: string
  }
  field: InputAction
  readOnly?: boolean
  hasVariables?: boolean
  onChange: (action: any) => void
}

export const ContentTextField = memo((props: TextFieldProps): JSX.Element => {
  const [value, setValue] = useState(props.value)
  const ref = useRef<HTMLInputElement>(null)
  const debouncedValue = useDebounce<string>(value, INPUT_DEBOUNCE_DELAY)

  useEffect(() => setValue(props.value), [props.id])
  useEffect(() => setValue(props.value), [props.value])
  useEffect(() => updateContent(), [debouncedValue])

  const updateContent = () => {
    if (value === props.value) return
    const sanitizedValue = removeZeroWidthSpaces(value)
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: sanitizedValue,
    })
  }

  const removeZeroWidthSpaces = (inputString: string) => {
    return inputString.replace(/\u200B/g, '')
  }

  const onUseVariable = (variable: string) => {
    const textWithVariable = addVariableOnText(variable)
    setValue(textWithVariable)
    ref.current?.focus()
  }

  const addVariableOnText = (variable: string): string => {
    const cursor = ref?.current?.selectionStart || props.value.length
    const textBeforeCursor = props.value.slice(0, cursor)
    const textAfterCursor = props.value.slice(cursor)
    return `${textBeforeCursor}{${variable}}${textAfterCursor}`
  }

  const getHelperText = (): string | undefined => {
    if (props.error && props.field.errorText) {
      return props.field.errorText
    }

    if (props.counterMaxLength && value.length > props.counterMaxLength) {
      return 'Text is too long.'
    }
    return props.field.helperText || props.helperText
  }

  return (
    <TextFieldContainer>
      <HtInput
        {...props}
        inputRef={ref}
        id={props.field.label}
        label={props.field.label}
        placeholder={props.field.placeholder}
        value={value}
        onChange={setValue}
        actionButton={
          !props.hasVariables ? undefined : (
            <BotVariablesDropdown onUseVariable={onUseVariable} />
          )
        }
        helperText={getHelperText()}
      />
    </TextFieldContainer>
  )
})
