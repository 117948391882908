import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../../UI/components/edges/edge-utils'
import {
  MAX_WHATSAPP_BUTTON_TEXT_LENGTH,
  MAX_WHATSAPP_LIST_MESSAGE_LENGTH,
} from '../../../../UI/constants'
import { IdMapping } from '../../../../UI/types'
import { HtContentType } from '../../cms/hubtype/node-types'
import { HtWhatsappButtonListNode } from '../../cms/hubtype/whatsapp'
import { LocaleCode } from '../../locales/code'
import { LocaleManager } from '../../utils/locale-manager'
import { ContentType, MessageContentType, TopContentFieldsBase } from '..'
import { TextLocale } from '../common'
import { WhatsappButtonListRowFields } from './whatsapp-button-list-row-fields'
import { WhatsappButtonListSectionFields } from './whatsapp-button-list-section-fields'

export class WhatsappButtonListFields extends TopContentFieldsBase {
  selectedSubContentId?: string
  constructor(
    public text = '',
    public listButtonText = '',
    public sections: WhatsappButtonListSectionFields[] = [
      new WhatsappButtonListSectionFields('', [
        new WhatsappButtonListRowFields(''),
        new WhatsappButtonListRowFields(''),
        new WhatsappButtonListRowFields(''),
        new WhatsappButtonListRowFields(''),
      ]),
    ],
    public textLocales: TextLocale[] = [],
    public listButtonLocales: TextLocale[] = []
  ) {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.WHATSAPP_BUTTON_LIST
  }

  stringContentType(): string {
    return 'button list'
  }

  setValuesByLocale(locale: string): void {
    this.text = LocaleManager.getTextByLocale(this.textLocales, locale)
    this.listButtonText = LocaleManager.getTextByLocale(
      this.listButtonLocales,
      locale
    )
    this.sections.forEach(section => section.setValuesByLocale(locale))
  }

  setContentByLocale(locale: LocaleCode): void {
    this.textLocales = LocaleManager.setTextByLocale(
      this.textLocales,
      this.text,
      locale
    )
    this.listButtonLocales = LocaleManager.setTextByLocale(
      this.listButtonLocales,
      this.listButtonText,
      locale
    )
    this.sections.forEach(section => section.setContentByLocale(locale))
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.textLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.listButtonLocales, newLocale, copyFrom)
    this.sections.forEach(section => section.addLocale(newLocale, copyFrom))
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.textLocales, localesToRemove)
    LocaleManager.removeLocales(this.listButtonLocales, localesToRemove)
    this.sections.forEach(section => section.removeLocales(localesToRemove))
  }

  static fromHubtypeCMS(
    component: HtWhatsappButtonListNode,
    locale: LocaleCode
  ): WhatsappButtonListFields {
    const newWhatsappButtonList = new WhatsappButtonListFields()
    this.setHubtypeCmsCommonData(newWhatsappButtonList, component)
    newWhatsappButtonList.textLocales = component.content.text
    newWhatsappButtonList.listButtonLocales = component.content.button_text
    newWhatsappButtonList.sections = component.content.sections.map(section =>
      WhatsappButtonListSectionFields.fromHubtypeCMS(section, locale)
    )
    newWhatsappButtonList.setValuesByLocale(locale)
    return newWhatsappButtonList
  }

  toHubtypeCMS(
    position: XYPosition,
    locale: LocaleCode
  ): HtWhatsappButtonListNode {
    this.setContentByLocale(locale)
    const node: HtWhatsappButtonListNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.WHATSAPP_BUTTON_LIST,
      content: {
        text: this.textLocales,
        button_text: this.listButtonLocales,
        sections: this.sections.map(s => s.toHubtypeCMS(locale)),
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    const tooLongTextErrors = []
    if (!this.code) requiredFields.push('content ID')
    if (!this.text) requiredFields.push('message')
    if (!this.listButtonText) requiredFields.push('button to open list')
    if (this.text.length > MAX_WHATSAPP_LIST_MESSAGE_LENGTH) {
      tooLongTextErrors.push('message')
    }
    if (this.listButtonText.length > MAX_WHATSAPP_BUTTON_TEXT_LENGTH) {
      tooLongTextErrors.push('button to open list')
    }
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const fieldToLongErrors = this.getTooLongFieldsErrors(tooLongTextErrors)
    const sectionsErrors = this.sections.flatMap(section =>
      section.getErrors(this)
    )
    this.errors.fieldErrors = [
      ...fieldToLongErrors,
      ...sectionsErrors,
      ...fieldErrors,
    ]
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    const textErrors = LocaleManager.getLocalesWithEmptyText(
      [...this.textLocales, ...this.listButtonLocales],
      locales
    )
    const sectionErrors = this.sections.flatMap(section =>
      section.getLocalesWithErrorsInSection(locales, this.sections.length)
    )
    this.errors.localesWithErrors = [...textErrors, ...sectionErrors]
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    if (
      this.fieldsIncludeString(
        [this.code, this.text, this.listButtonText],
        value
      )
    ) {
      return true
    }
    const includesString = this.sections.map(s => s.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): NodeEdges {
    return {
      buttons: this.sections.map(s => s.getConnections(edges)).flat(),
      followUp: this.getFollowUp(edges),
    }
  }

  static getCopy(content: WhatsappButtonListFields): WhatsappButtonListFields {
    const newWhatsappButtonList = new WhatsappButtonListFields()
    TopContentFieldsBase.copyContent(content, newWhatsappButtonList)
    newWhatsappButtonList.sections = content.sections.map(section =>
      WhatsappButtonListSectionFields.getCopy(section)
    )
    return newWhatsappButtonList
  }

  getIdMappingForOldFields(
    oldWhatsappButtonList: WhatsappButtonListFields
  ): IdMapping {
    return this.sections.reduce((idMapping: IdMapping, section, i) => {
      const elementIdMapping = section.getIdMappingForOldFields(
        oldWhatsappButtonList.sections[i]
      )
      return { ...idMapping, ...elementIdMapping }
    }, {})
  }
}
