import { memo, useEffect, useState } from 'react'
import { useUpdateNodeInternals } from 'reactflow'

import { useFlowBuilderSelector } from '../../reducer/hooks'
import { CarouselNodeProps } from '../../types'
import { Icon } from '../base'
import { CarouselElement, Handles, NodeHeader } from './node-components'
import { MessageNode, StyledList } from './node-styles'

const CarouselNode = ({
  data,
  selected,
  id,
}: CarouselNodeProps): JSX.Element => {
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)
  const updateNodeInternals = useUpdateNodeInternals()
  const [selectedElement, setSelectedElement] = useState(
    data.selectedSubContentId
  )
  const selectElement = (id: string) => {
    if (id === data.selectedSubContentId) return
    setSelectedElement(id)
    data.selectedSubContentId = id
    updateNode(data)
    // used to call updateNodeInternals after the state is updated
    setTimeout(() => updateNodeInternals(id), 50)
  }

  useEffect(() => {
    setSelectedElement(data.selectedSubContentId)
  }, [data.selectedSubContentId])

  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader
        data={data}
        title={'Carousel'}
        icon={Icon.RECTANGLE_VERTICAL_HISTORY}
      />
      <StyledList>
        {data.elements.map((e, i) => (
          <CarouselElement
            key={i}
            index={i}
            element={e}
            isElementSelected={selectedElement === e.id}
            isCarouselSelected={selected}
            selectElement={() => selectElement(e.id)}
          />
        ))}
      </StyledList>
    </MessageNode>
  )
}

export default memo(CarouselNode)
