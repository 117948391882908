import { memo } from 'react'

import {
  QUEUE_CLOSED,
  QUEUE_OPEN,
  QUEUE_OPEN_WITHOUT_AGENTS,
} from '../../constants'
import { QueueStatusNodeProps } from '../../types'
import { COLORS, Icon, TextSmall } from '../base'
import { Condition, Handles, NodeHeader, NodeSection } from './node-components'
import {
  Border,
  ConditionalNode,
  StyledCircle,
  StyledConditionWithSubsection,
  StyledList,
} from './node-styles'

const QueueStatusNode = ({ data, selected, id }: QueueStatusNodeProps) => {
  return (
    <ConditionalNode $isSelected={selected}>
      <Handles hasFollowUp={false} contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Queue status'} icon={Icon.SPLIT} />
      <NodeSection
        title='Queue'
        name={data.queue?.isInvalid ? '' : data.queue?.name}
      />
      <StyledList>
        <Condition id={`${id}-${QUEUE_OPEN}`} border={Border.NONE}>
          <StyledConditionWithSubsection>
            If queue is open
            {data.checkAvailableAgents && (
              <div>
                <StyledCircle $color={COLORS.G500} />
                <TextSmall>Agents available</TextSmall>
              </div>
            )}
          </StyledConditionWithSubsection>
        </Condition>
        {data.checkAvailableAgents && (
          <Condition
            id={`${id}-${QUEUE_OPEN_WITHOUT_AGENTS}`}
            border={Border.TOP}
          >
            <StyledConditionWithSubsection>
              If queue is open
              <div>
                <StyledCircle $color={COLORS.R500} />
                <TextSmall>No agents available</TextSmall>
              </div>
            </StyledConditionWithSubsection>
          </Condition>
        )}
        <Condition id={`${id}-${QUEUE_CLOSED}`} border={Border.TOP}>
          If queue is closed
        </Condition>
      </StyledList>
    </ConditionalNode>
  )
}

export default memo(QueueStatusNode)
