import { Key, useState } from 'react'

import {
  ContentType,
  TopContentFields,
} from '../../../../domain/models/content-fields'
import {
  DropdownData,
  HtMultiSelectDropdown,
  HtSearchBar,
  Size,
  TextBodySmall,
} from '../../base'
import { StyledHeader } from '../../flow-selector/flow-selector-styles'
import { InputAction } from '../constants'
import { ConditionalContentTitle, FullWidthContainer } from '../editor-styles'
import { SortableContentWidget } from './sortable-widget/sortable-content-widget'

interface SortableDropdownWidgetProps<T extends DropdownData> {
  nodeContent: TopContentFields
  contentType: ContentType
  applyButtonText: string
  field: InputAction
  values: T[]
  availableItems: T[]
  readOnly: boolean
  sortableValues?: T[]
  isDragDisabled?: boolean
  searchPlaceholder?: string
  onChange: (action: any) => void
  header?: (item: T, index: number) => React.ReactNode
}

export const SortableDropdownWidgetField = <T extends DropdownData>(
  props: SortableDropdownWidgetProps<T>
): JSX.Element => {
  const [filterValue, setFilterValue] = useState('')
  const onApply = (selectedKeys: Key[]) => {
    const newValues = selectedKeys
      .map(key => props.availableItems.find(item => item.id === key))
      .filter(Boolean)

    if (props.isDragDisabled) {
      newValues.sort((a, b) => (a?.name || '').localeCompare(b?.name || ''))
    }
    props.onChange({
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: newValues,
      edges: getEdges(selectedKeys),
    })
  }

  const getEdges = (selectedKeys: Key[]) => {
    const invalidEdges = props.values
      .filter(item => !selectedKeys.includes(item.id))
      .map(item => `${props.nodeContent.id}-${item.id}`)

    return props.nodeContent.edges.filter(
      edge => edge.sourceHandle && !invalidEdges.includes(edge.sourceHandle)
    )
  }

  const handleDelete = (item: DropdownData) => {
    props.onChange({
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: (props.sortableValues || props.values).filter(
        v => v.id !== item.id
      ),
      edges: props.nodeContent.edges.filter(
        edge => edge.sourceHandle !== `${props.nodeContent.id}-${item.id}`
      ),
    })
  }

  return (
    <FullWidthContainer>
      <HtMultiSelectDropdown
        defaultSelectedKeys={props.values.map(v => v.id)}
        applyButtonText={props.applyButtonText}
        onApply={onApply}
        data={props.availableItems}
        label={props.field.label!}
        placeholder={props.field.placeholder}
        readOnly={props.readOnly}
        listProps={{ maxListHeight: 'calc(50vh - 120px)' }}
        filterValue={filterValue}
        header={
          props.searchPlaceholder &&
          props.availableItems.length > 10 && (
            <StyledHeader>
              <HtSearchBar
                placeholder={props.searchPlaceholder}
                size={Size.SMALL}
                onFilterChange={setFilterValue}
              />
            </StyledHeader>
          )
        }
      />
      {(props.values.length > 0 || (props.sortableValues?.length ?? 0) > 0) && (
        <SortableContentWidget
          {...props}
          nodeId={props.nodeContent.id}
          sortableContents={props.sortableValues || props.values}
          isRemovable={true}
          onRemove={handleDelete}
          header={(item, index) => (
            <ConditionalContentTitle
              key={item.id}
              $disableGripDots={props.isDragDisabled}
            >
              {props.header?.(item, index) || (
                <TextBodySmall>{item.name}</TextBodySmall>
              )}
            </ConditionalContentTitle>
          )}
        ></SortableContentWidget>
      )}
    </FullWidthContainer>
  )
}
