import { ActionType } from '../../action-types'
import {
  AddFlowAction,
  AddFlowHistoryChange,
  AddFlowInterface,
} from './add-flow'
import {
  RemoveFlowAction,
  RemoveFlowHistoryChange,
  RemoveFlowInterface,
} from './remove-flow'
import {
  SelectFlowAction,
  SelectFlowHistoryChange,
  SelectFlowInterface,
} from './select-flow'
import { SetFlowsAction, SetFlowsInterface } from './set-flows'

export { AddFlowAction, RemoveFlowAction, SelectFlowAction, SetFlowsAction }

export type FlowActionInterface =
  | AddFlowInterface
  | RemoveFlowInterface
  | SelectFlowInterface
  | SetFlowsInterface

export type FlowChangesHistory =
  | AddFlowHistoryChange
  | RemoveFlowHistoryChange
  | SelectFlowHistoryChange

export const flowActionMap = {
  [ActionType.ADD_FLOW]: AddFlowAction,
  [ActionType.REMOVE_FLOW]: RemoveFlowAction,
  [ActionType.SELECT_FLOW]: SelectFlowAction,
  [ActionType.SET_FLOWS]: SetFlowsAction,
}
