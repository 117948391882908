import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
} from 'reactflow'

import { MessageContentType } from '../../domain/models/content-fields'
import { ReactComponent as AiMagicWandIcon } from '../../UI/assets/ai-magic-wand.svg'
import {
  HtIcon,
  Icon,
  Size,
  TextSmall,
  TextSmallBold,
} from '../../UI/components/base'
import { HorizontalContainer } from '../../UI/components/base/containers'
import { getIconFromFileType } from '../../UI/components/base/ht-icon/icon-utils'
import { Handles, NodeHeader } from '../../UI/components/nodes/node-components'
import {
  AiMessageNode,
  StyledKnowledgebaseFeedback,
} from '../../UI/components/nodes/node-styles'
import { KnowledgeBaseFields } from './model'

export interface KnowledgeBaseNodeInterface
  extends ReactFlowNode<KnowledgeBaseFields> {
  data: KnowledgeBaseFields
  type: MessageContentType.KNOWLEDGE_BASE
}

export interface KnowledgeBaseNodeProps
  extends ReactFlowNodeProps<KnowledgeBaseFields> {
  data: KnowledgeBaseFields
  type: MessageContentType.KNOWLEDGE_BASE
}

export const KnowledgeBaseNode = ({
  data,
  selected,
}: KnowledgeBaseNodeProps) => {
  return (
    <AiMessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader
        data={data}
        title='Knowledge base'
        iconComponent={<AiMagicWandIcon />}
      />
      <hr />
      <TextSmallBold>Knowledge sources</TextSmallBold>
      {data.sources.slice(0, 3).map(source => (
        <HorizontalContainer key={source.id} $size={Size.SMALL}>
          <HtIcon icon={getIconFromFileType(source.fileType)} />
          <TextSmall $wordBreak='break-word'>{source.name}</TextSmall>
        </HorizontalContainer>
      ))}
      {data.sources.length > 3 && (
        <TextSmall> +{data.sources.length - 3} more</TextSmall>
      )}
      {data.feedbackEnabled && (
        <StyledKnowledgebaseFeedback>
          <HtIcon icon={Icon.THUMBS_UP} />
          <HtIcon icon={Icon.THUMBS_DOWN} />
        </StyledKnowledgebaseFeedback>
      )}
    </AiMessageNode>
  )
}
