import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { HtGoToFlowNode } from '../cms/hubtype/go-to-flow'
import { HtContentType } from '../cms/hubtype/node-types'
import { ContentType, TopContentFieldsBase } from '.'
import { MessageContentType } from './content-types'

export class GoToFlowFields extends TopContentFieldsBase {
  public targetFlowId?: string

  constructor() {
    super()
    this.code = 'Go to flow'
  }

  contentType(): MessageContentType {
    return ContentType.GO_TO_FLOW
  }

  stringContentType(): string {
    return 'go to flow'
  }

  static fromHubtypeCMS(component: HtGoToFlowNode): GoToFlowFields {
    const newGoToFlow = new GoToFlowFields()
    this.setHubtypeCmsCommonData(newGoToFlow, component)
    newGoToFlow.code = 'Go to flow'
    newGoToFlow.targetFlowId = component.content.flow_id
    return newGoToFlow
  }

  toHubtypeCMS(position: XYPosition): HtGoToFlowNode {
    const node: HtGoToFlowNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.GO_TO_FLOW,
      content: {
        flow_id: this.targetFlowId,
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.targetFlowId) requiredFields.push('flow')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    this.errors.fieldErrors = fieldErrors
  }

  hasString(value: string): boolean {
    return false
  }

  getConnections(edges: Edge[]): NodeEdges {
    return {}
  }

  static getCopy(content: GoToFlowFields): GoToFlowFields {
    const newGoToFlow = new GoToFlowFields()
    TopContentFieldsBase.copyContent(content, newGoToFlow)
    return newGoToFlow
  }
}
