import styled from 'styled-components'

import { ReactComponent as TrashIcon } from '../../assets/edge-trash-icon.svg'
import { ReactComponent as ToSourceIcon } from '../../assets/to-source-icon.svg'
import { ReactComponent as ToTargetIcon } from '../../assets/to-target-icon.svg'
import { COLORS } from '../base'

export const EdgePath = styled.path<{
  $hasHover: boolean
  $isSelected?: boolean
}>`
  cursor: pointer;
  fill: none;
  stroke: ${COLORS.WHITE};
  stroke-width: 2;
  ${props => (props.$hasHover || props.$isSelected) && `stroke-width: 3.5`};
`

export const EdgeBackground = styled.path`
  cursor: pointer;
  fill: none;
  stroke-width: 20;
`

export const RemoveButton = styled(TrashIcon)`
  pointer-events: bounding-box;
  cursor: pointer;
`

export const ToTarget = styled(ToTargetIcon)`
  pointer-events: bounding-box;
  cursor: pointer;
`

export const ToSource = styled(ToSourceIcon)`
  pointer-events: bounding-box;
  cursor: pointer;
`
