import { Locale } from '../../../domain/models/locales/locale'
import { normalizeValue } from '../../../utils/string-utils'
import { useAnalytics } from '../../analytics'
import { TrackEventName } from '../../analytics/utils'
import { HtSearchBar } from '../base'

interface SearchBarProps {
  locales: Locale[]
  setFilteredLocales: (locale: Locale[]) => void
}

export const SearchBar = (props: SearchBarProps): JSX.Element => {
  const analytics = useAnalytics()

  const filter = (normalizedValue: string) => {
    if (normalizedValue) {
      analytics.trackEvent(TrackEventName.LANGUAGE_SEARCH_BAR, {
        search_text: normalizedValue,
      })
    }
    props.setFilteredLocales(
      props.locales.filter(locale =>
        normalizeValue(locale.getLocaleString()).includes(normalizedValue)
      )
    )
  }

  return <HtSearchBar onFilterChange={filter} width={'388px'} />
}
