import cloneDeep from 'lodash.clonedeep'
import { XYPosition } from 'reactflow'

import {
  ButtonFields,
  ContentType,
  StartFields,
  TopContentFields,
} from '../../../../domain/models/content-fields'
import { normalizeValue } from '../../../../utils/string-utils'
import { MAIN_FLOW } from '../../../constants'
import { NodeTypes, State } from '../../../types'

export abstract class NodeAction {
  static addNode = (state: State, node: NodeTypes): void => {
    state.nodes.push(node)
  }

  static addNodes = (state: State, nodes: NodeTypes[]): void => {
    state.nodes.push(...nodes)
  }

  static removeNode = (state: State, nodeToRemove: NodeTypes): void => {
    state.nodes = state.nodes.filter(node => node.id !== nodeToRemove.id)
  }

  static removeNodes = (state: State, nodesToRemove: NodeTypes[]): void => {
    state.nodes = state.nodes.filter(
      node => !nodesToRemove.some(nodeToRemove => nodeToRemove.id === node.id)
    )
  }

  static getNodeById = (state: State, id: string): NodeTypes | null => {
    return state.nodes.find(node => node.id === id) || null
  }

  static selectNode = (state: State, node: NodeTypes) => {
    this.setSelectedNodes(state, [node.id])
    state.currentNode = node
    state.currentFlowId = node.data.flowId
  }

  static createNewNode = (
    data: TopContentFields,
    position: XYPosition,
    flowId: string
  ): NodeTypes => {
    data.flowId = flowId
    const newNode = {
      id: data.id,
      type: data.contentType(),
      position,
      data,
      selected: true,
    } as NodeTypes
    return newNode
  }

  static createStartNode(): NodeTypes {
    const data = new StartFields()
    data.flowId = MAIN_FLOW.id
    return {
      id: data.id,
      type: data.contentType(),
      position: { x: 0, y: 0 },
      data,
      selected: false,
      selectable: false,
      draggable: false,
    }
  }

  static setSelectedNodes = (state: State, nodeIds: string[]) => {
    state.nodes.forEach(node => {
      node.selected = nodeIds.includes(node.id)
    })
  }

  static updateButtonsInNodes = (
    state: State,
    updateAction: (button: ButtonFields) => void
  ) => {
    state.nodes = cloneDeep(state.nodes).map(node => {
      if (node.type === ContentType.TEXT) {
        node.data.buttons.forEach(button => updateAction(button))
      }
      if (node.type === ContentType.WHATSAPP_CTA_URL_BUTTON) {
        updateAction(node.data.button)
      }
      return node
    })
  }

  static reportRepeatedContentIds(
    state: State,
    setOtherErrors?: (node: NodeTypes) => void
  ) {
    const codeCount = this.getCodeCount(state)
    state.nodes = state.nodes.map(node => {
      const isCodeDuplicated = this.isCodeDuplicated(node, codeCount)
      node.data.errors.hasDuplicatedCode = isCodeDuplicated
      setOtherErrors?.(node)
      return node
    })
  }

  private static isCodeDuplicated = (
    node: NodeTypes,
    codeCount: Record<string, number>
  ): boolean => {
    return (
      !!node.data.code &&
      codeCount[normalizeValue(node.data.code)] > 1 &&
      node.type !== ContentType.GO_TO_FLOW
    )
  }

  private static getCodeCount = (state: State): Record<string, number> => {
    return state.nodes.reduce((acc: Record<string, number>, node) => {
      const normalizedCode = normalizeValue(node.data.code)
      acc[normalizedCode] = (acc[normalizedCode] || 0) + 1
      return acc
    }, {})
  }
}
