/* eslint-disable @typescript-eslint/naming-convention */
export enum Language {
  AFRIKAANS = 'Afrikaans',
  ARABIC = 'Arabic',
  BENGALI = 'Bengali',
  BULGARIAN = 'Bulgarian',
  CATALAN = 'Catalan',
  CHINESE = 'Chinese',
  CROATIAN = 'Croatian',
  CZECH = 'Czech',
  DANISH = 'Danish',
  DUTCH = 'Dutch',
  ENGLISH = 'English',
  ESTONIAN = 'Estonian',
  FINNISH = 'Finnish',
  FRENCH = 'French',
  GEORGIAN = 'Georgian',
  GERMAN = 'German',
  GREEK = 'Greek',
  HINDI = 'Hindi',
  HUNGARIAN = 'Hungarian',
  ICELANDIC = 'Icelandic',
  INDONESIAN = 'Indonesian',
  ITALIAN = 'Italian',
  JAPANESE = 'Japanese',
  KOREAN = 'Korean',
  LITHUANIAN = 'Lithuanian',
  MALAY = 'Malay',
  NORWEGIAN_NYNORSK = 'Norwegian Nynorsk',
  POLISH = 'Polish',
  PORTUGUESE = 'Portuguese',
  ROMANIAN = 'Romanian',
  RUSSIAN = 'Russian',
  SERBIAN = 'Serbian',
  SINHALA = 'Sinhala',
  SLOVAK = 'Slovak',
  SLOVENIAN = 'Slovenian',
  SPANISH = 'Spanish',
  SWEDISH = 'Swedish',
  THAI = 'Thai',
  TURKISH = 'Turkish',
  UKRAINIAN = 'Ukrainian',
  VIETNAMESE = 'Vietnamese',
}

export interface LanguageInfo {
  id: string
  name: Language
}
