import { Node } from '@react-types/shared'
import { Key, useRef } from 'react'
import { useGridList } from 'react-aria'
import { ListProps, OverlayTriggerState, useListState } from 'react-stately'

import { normalizeValue } from '../../../../../utils/string-utils'
import { Popover } from '../../common/dropdown/popover'
import { DropdownData, DropdownProps } from '../../common/dropdown/types'
import { HtButton } from '../../ht-button/ht-button'
import { Size } from '../../variants'
import { ListItem } from './list-item'
import { ListFooter, StyledCheckboxList } from './multiselect-styles'

interface CheckboxListProps<T extends DropdownData>
  extends ListProps<T>,
    DropdownProps {
  overlayState: OverlayTriggerState
  applyButtonText: string
  filterValue?: string
  triggerRef: React.RefObject<HTMLButtonElement>
  onApply: (keys: Key[]) => void
}

export const CheckboxList = <T extends DropdownData>({
  overlayState,
  ...props
}: CheckboxListProps<T>): JSX.Element => {
  const state = useListState(props)
  const ref = useRef<HTMLUListElement>(null)
  const { gridProps } = useGridList(props, state, ref)

  const onApply = () => {
    props.onApply(Array.from(state.selectionManager.selectedKeys))
    overlayState.close()
  }

  const shouldFilterItem = (item: Node<object>): boolean => {
    return Boolean(
      props.filterValue &&
        item.textValue &&
        !normalizeValue(item.textValue).includes(
          normalizeValue(props.filterValue)
        )
    )
  }

  return (
    <Popover
      state={overlayState}
      triggerRef={props.triggerRef}
      listProps={props.listProps}
    >
      {props.header}
      <StyledCheckboxList {...gridProps} ref={ref}>
        {[...state.collection].map(
          item =>
            !shouldFilterItem(item) && (
              <ListItem key={item.key} item={item} state={state} />
            )
        )}
      </StyledCheckboxList>
      <ListFooter>
        <HtButton size={Size.SMALL} onClick={onApply}>
          {props.applyButtonText}
        </HtButton>
      </ListFooter>
    </Popover>
  )
}
