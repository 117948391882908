import { ActionType } from '../../action-types'
import {
  SelectAiModelAction,
  SelectAiModelHistoryChange,
  SelectAiModelInterface,
} from './select-ai-model'
import {
  SetKnowledgeBaseActiveAction,
  SetKnowledgeBaseActiveHistoryChange,
  SetKnowledgeBaseActiveInterface,
} from './set-knowledge-base-active'

export { SelectAiModelAction, SetKnowledgeBaseActiveAction }

export type AiActionInterface =
  | SelectAiModelInterface
  | SetKnowledgeBaseActiveInterface

export type AiChangesHistory =
  | SelectAiModelHistoryChange
  | SetKnowledgeBaseActiveHistoryChange

export const aiActionMap = {
  [ActionType.SELECT_AI_MODEL]: SelectAiModelAction,
  [ActionType.SET_KNOWLEDGE_BASE_ACTIVE]: SetKnowledgeBaseActiveAction,
}
