import { useEffect, useState } from 'react'

import { CountryInfo } from '../../../domain/models/content-fields/country'
import { LocaleCode } from '../../../domain/models/locales/code'
import { CountryCode } from '../../../domain/models/locales/country-code'
import { LanguageInfo } from '../../../domain/models/locales/language'
import { FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY } from '../../constants'
import { FlowBuilderPreviewState } from '../../types'
import {
  getStoragePreviewStateFromFBApp,
  LoadLocalesFromStorage,
  LocalesLoader,
  LocalesLoaderStrategy,
  parsePreviewStateForBotId,
  updateLocalStoragePreviewStateForBotId,
} from '../utils'
import { botonicUpdateUserExtraData } from '../utils/botonic-utils'

export function useLanguageCountries(strategy: LocalesLoaderStrategy) {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageInfo>()
  const [selectedCountry, setSelectedCountry] = useState<CountryInfo>()
  const [availableLanguages, setAvailableLanguages] = useState<LanguageInfo[]>()
  const [
    availableCountriesForLanguage,
    setAvailableCountriesForCurrentLanguage,
  ] = useState<CountryInfo[]>()

  const [localesLoader, setLocalesLoader] = useState<LocalesLoader>(
    new LocalesLoader()
  )

  const [previewState, setPreviewState] = useState<
    FlowBuilderPreviewState | undefined
  >()

  const handlePreviewStateChange = (event: StorageEvent) => {
    if (strategy instanceof LoadLocalesFromStorage) {
      const botId = strategy.botId
      if (event.key === FLOW_BUILDER_PREVIEW_STATE_STORAGE_KEY) {
        if (event.newValue && event.newValue !== event.oldValue) {
          const parsedPreviewState = parsePreviewStateForBotId({
            previewStateStorageValue: event.newValue,
            botId,
          })
          if (parsedPreviewState) {
            setPreviewState(parsedPreviewState)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (strategy instanceof LoadLocalesFromStorage) {
      const botId = strategy.botId
      const storagePreviewState = getStoragePreviewStateFromFBApp()
      if (storagePreviewState) {
        const parsedPreviewState = parsePreviewStateForBotId({
          previewStateStorageValue: storagePreviewState,
          botId,
        })
        if (parsedPreviewState) {
          setPreviewState(parsedPreviewState)
        }
      }
    }
  }, [])

  useEffect(() => {
    // Listens to FBAppStoragePreviewState
    window.addEventListener('storage', handlePreviewStateChange)
    return () => {
      window.removeEventListener('storage', handlePreviewStateChange)
    }
  }, [handlePreviewStateChange])

  useEffect(() => {
    const localesLoader = new LocalesLoader(strategy).load()
    setLocalesLoader(localesLoader)
  }, [previewState])

  useEffect(() => {
    const {
      currentLanguage,
      currentCountry,
      availableLanguages,
      availableCountriesForCurrentLanguage,
    } = localesLoader
    if (!currentLanguage || !currentCountry) return
    setSelectedLanguage(currentLanguage)
    setSelectedCountry(currentCountry)
    setAvailableLanguages(availableLanguages)
    setAvailableCountriesForCurrentLanguage(
      availableCountriesForCurrentLanguage
    )
  }, [localesLoader])

  const handleSelectedLanguage = (language: LanguageInfo) => {
    if (!language || !localesLoader) return
    setSelectedLanguage(language)

    const itemForSelectedLanguage = localesLoader.languageCountriesMap.get(
      language.id
    )
    if (itemForSelectedLanguage) {
      const refreshedCountries = itemForSelectedLanguage.countries
      setAvailableCountriesForCurrentLanguage(refreshedCountries)
      setSelectedCountry(refreshedCountries[0])
      if (strategy instanceof LoadLocalesFromStorage) {
        updateLocalStoragePreviewStateForBotId(strategy.botId, {
          current: language.id as LocaleCode,
          currentCountry: refreshedCountries[0].id as CountryCode,
        })
      }
      botonicUpdateUserExtraData({
        language: language.id,
        country: refreshedCountries[0].id,
      })
    }
  }

  const handleSelectedCountry = (country: CountryInfo) => {
    if (!country) return
    botonicUpdateUserExtraData({
      country: country.id,
    })
    setSelectedCountry(country)
    if (strategy instanceof LoadLocalesFromStorage) {
      updateLocalStoragePreviewStateForBotId(strategy.botId, {
        currentCountry: country.id as CountryCode,
      })
    }
  }

  return {
    localesLoader,
    selectedLanguage,
    selectedCountry,
    availableCountriesForLanguage,
    availableLanguages,
    handleSelectedLanguage,
    handleSelectedCountry,
  }
}
