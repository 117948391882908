import {
  PayloadFields,
  UrlFields,
} from '../../../../domain/models/content-fields'
import { State } from '../../../types'

export abstract class NonMessageAction {
  static addPayload = (state: State, newPayload: PayloadFields) => {
    state.nonMessageContents.payloads.push(newPayload)
    state.nonMessageContents.payloads = state.nonMessageContents.payloads.sort(
      this.orderByName
    )
  }

  static removePayload = (state: State, payloadToRemove: PayloadFields) => {
    state.nonMessageContents.payloads =
      state.nonMessageContents.payloads.filter(
        payload => payload.id !== payloadToRemove.id
      )
  }

  static addUrl = (state: State, newUrl: UrlFields) => {
    state.nonMessageContents.urls.push(newUrl)
    state.nonMessageContents.urls = state.nonMessageContents.urls.sort(
      this.orderByName
    )
  }

  static removeUrl = (state: State, urlToRemove: UrlFields) => {
    state.nonMessageContents.urls = state.nonMessageContents.urls.filter(
      url => url.id !== urlToRemove.id
    )
  }

  static editUrl = (state: State, urlToEdit: UrlFields, newName: string) => {
    state.nonMessageContents.urls = state.nonMessageContents.urls
      .map(url => {
        if (url.id === urlToEdit.id) {
          return url.cloneWithUrl(newName)
        }
        return url
      })
      .sort(this.orderByName)
  }

  static orderByName = <T extends { name: string }>(a: T, b: T) => {
    return a.name.localeCompare(b.name)
  }
}
