import { useRef } from 'react'
import { AriaMenuProps, useMenuTrigger } from 'react-aria'
import { MenuTriggerProps, useMenuTriggerState } from 'react-stately'

import { HelperText } from '../../base-styles'
import { DropdownButton } from '../../common/dropdown/dropdown-button'
import { Popover } from '../../common/dropdown/popover'
import { DropdownContainer } from '../../common/dropdown/styles'
import { DropdownProps } from '../../common/dropdown/types'
import { TextSmallBold } from '../../typography'
import { MenuList } from './menu-list'

export interface MenuProps<T extends object>
  extends AriaMenuProps<T>,
    MenuTriggerProps,
    DropdownProps {
  placeholder?: string
  isDisabled?: boolean
}

export const Menu = <T extends object>(props: MenuProps<T>) => {
  const state = useMenuTriggerState(props)

  const ref = useRef(null)
  const { menuTriggerProps, menuProps } = useMenuTrigger<T>(
    { isDisabled: props.isDisabled },
    state,
    ref
  )

  return (
    <DropdownContainer width={props.width}>
      {props.placeholder && <TextSmallBold>{props.label}</TextSmallBold>}
      <DropdownButton
        {...menuTriggerProps}
        buttonRef={ref}
        btnProps={props.btnProps}
        readOnly={props.readOnly}
        isDisabled={props.isDisabled}
        isOpen={state.isOpen}
        isEmpty={!!props.placeholder}
        buttonSymbol={props.buttonSymbol}
      >
        {props.placeholder || props.label}
      </DropdownButton>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} listProps={props.listProps}>
          {props.header}
          <MenuList {...props} {...menuProps} />
          {props.footer}
        </Popover>
      )}
      {props.helperText && <HelperText>{props.helperText}</HelperText>}
    </DropdownContainer>
  )
}
