import styled from 'styled-components'

export const StyledTestWebchatOptionsContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  align-self: flex-end;
  gap: 8px;
  width: ${props => `${props.width - 10}px` || '100%'};
  height: ${props => `${props.height - 4}%` || '100%'};
  margin-right: 25px;

  @media screen and (max-width: 768px) {
    align-items: center;
    height: 10%;
    margin-right: 2%;
    width: 96%;
  }
`

export const StyledWebchatOptionContainer = styled.div<any>`
  flex: 1 1 100px;
  max-width: ${props => `${props.$maxWidth || 180}px`};
  @media screen and (max-width: 768px) {
    flex: 1;
    max-width: ${props => `${props.$maxWidth || 180}px`};
  }
`
