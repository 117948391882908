import { StartFields } from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { NodeAction } from './node-action'

export interface SelectNodeInterface {
  type: ActionType.SELECT_NODE
  node: NodeTypes
}

export class SelectNodeAction {
  static apply = (state: State, { node }: SelectNodeInterface): void => {
    if (node.data instanceof StartFields) return
    NodeAction.setSelectedNodes(state, [node.id])
    state.currentNode = node
    state.currentFlowId = node.data.flowId
  }
}
