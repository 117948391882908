import { Edge, Viewport, XYPosition } from 'reactflow'

import {
  BotActionFields,
  CarouselFields,
  ChannelFields,
  ContentId,
  ContentType,
  CountryConditionFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  IntentFields,
  KeywordFields,
  QueueStatusFields,
  TextFields,
  TopContentFields,
  VideoFields,
  WhatsappButtonListFields,
} from '../../../../domain/models/content-fields'
import { CustomConditionFields } from '../../../../nodes/custom-conditional'
import { KnowledgeBaseFields } from '../../../../nodes/knowledge-base'
import { SmartIntentFields } from '../../../../nodes/smart-intent'
import { WhatsappCTAUrlButtonFields } from '../../../../nodes/whatsapp-cta-url-button'
import { isWebview } from '../../../../webviews/utils'
import {
  getNewEdge,
  hasFollowUpHandle,
} from '../../../components/edges/edge-utils'
import { APPLICATION_COPY_PASTE, KNOWLEDGE_BASE_FLOW } from '../../../constants'
import { IdMapping, NodeTypes, State } from '../../../types'
import {
  hasKnowledgeBaseFeature,
  hasSmartIntentsFeature,
} from '../../../utils/feature-flags'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NodeAction } from './node-action'

export interface PasteInterface {
  type: ActionType.PASTE_ELEMENTS
  event: ClipboardEvent
}

export interface PasteHistoryChange {
  type: ActionType.PASTE_ELEMENTS
  newNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

interface DataToCopy {
  nodesToCopy: NodeTypes[]
  viewportOnCopy: Viewport
}

export class PasteAction extends ReversibleAction {
  static apply = (state: State, { event }: PasteInterface): void => {
    if (state.isReadOnly) return
    const data = event.clipboardData?.getData(APPLICATION_COPY_PASTE)
    if (!data) return
    event.preventDefault()
    const dataToCopy = JSON.parse(data) as DataToCopy
    const newNodes = this.getNewNodes(state, dataToCopy)
    this.trackHistoryChange(state, newNodes)
    this.addNewNodes(state, newNodes)
  }

  static undo = (state: State, change: PasteHistoryChange) => {
    NodeAction.removeNodes(state, change.newNodes)
  }

  static redo = (state: State, change: PasteHistoryChange) => {
    this.addNewNodes(state, change.newNodes)
  }

  private static trackHistoryChange = (state: State, newNodes: NodeTypes[]) => {
    const newChange: PasteHistoryChange = {
      type: ActionType.PASTE_ELEMENTS,
      newNodes,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static addNewNodes = (state: State, newNodes: NodeTypes[]) => {
    NodeAction.addNodes(state, newNodes)
    const newNodeIds = newNodes.map(node => node.id)
    NodeAction.setSelectedNodes(state, newNodeIds)
    if (newNodes.length === 1) {
      state.currentNode = newNodes[0]
    } else {
      state.currentNode = undefined
    }
  }

  private static getNewNodes = (
    state: State,
    dataToCopy: DataToCopy
  ): NodeTypes[] => {
    const { newNodes, idMapping } = this.getNewNodesAndIdMapping(
      state,
      dataToCopy
    )
    return this.pasteEdgesOnNodes(newNodes, idMapping)
  }

  private static getNewNodesAndIdMapping = (
    state: State,
    { nodesToCopy, viewportOnCopy }: DataToCopy
  ): { newNodes: NodeTypes[]; idMapping: IdMapping } => {
    let idMapping: IdMapping = {}
    const positionVector = this.getPositionVector(
      state,
      viewportOnCopy,
      nodesToCopy[0].position
    )
    const newNodes = nodesToCopy.reduce((acc: NodeTypes[], node) => {
      const newData = this.getNodeCopyAndIdMapping(node, state, positionVector)
      if (newData) {
        acc.push(newData.newNode)
        idMapping = { ...idMapping, ...newData.idMapping }
      }
      return acc
    }, [])
    return { newNodes, idMapping }
  }

  private static getNodeCopyAndIdMapping = (
    node: NodeTypes,
    state: State,
    positionVector: XYPosition
  ): { newNode: NodeTypes; idMapping: IdMapping } | undefined => {
    let newData: TopContentFields | undefined
    let idMapping: IdMapping = {}
    if (isWebview(state.currentFlowId)) {
      switch (node.type) {
        case ContentType.TEXT:
          newData = TextFields.getCopy(node.data, true)
          break
        case ContentType.IMAGE:
          newData = ImageFields.getCopy(node.data)
          break
      }
    } else {
      switch (node.type) {
        case ContentType.TEXT:
          newData = TextFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.CAROUSEL:
          newData = CarouselFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.IMAGE:
          newData = ImageFields.getCopy(node.data)
          break
        case ContentType.HANDOFF:
          newData = HandoffFields.getCopy(node.data)
          break
        case ContentType.VIDEO:
          newData = VideoFields.getCopy(node.data)
          break
        case ContentType.WHATSAPP_BUTTON_LIST:
          newData = WhatsappButtonListFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.QUEUE_STATUS:
          newData = QueueStatusFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.CHANNEL:
          newData = ChannelFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.KEYWORD:
          if (state.currentFlowId !== KNOWLEDGE_BASE_FLOW.id) {
            newData = KeywordFields.getCopy(node.data)
          }
          break
        case ContentType.INTENT:
          if (state.currentFlowId !== KNOWLEDGE_BASE_FLOW.id) {
            newData = IntentFields.getCopy(node.data)
          }
          break
        case ContentType.GO_TO_FLOW:
          newData = GoToFlowFields.getCopy(node.data)
          break
        case ContentType.BOT_ACTION:
          newData = BotActionFields.getCopy(node.data)
          break
        case ContentType.COUNTRY_CONDITION:
          newData = CountryConditionFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.CUSTOM_CONDITION:
          newData = CustomConditionFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.SMART_INTENT: {
          if (
            hasSmartIntentsFeature(state.organizationContents.featureFlags) &&
            state.currentFlowId !== KNOWLEDGE_BASE_FLOW.id
          ) {
            newData = SmartIntentFields.getCopy(node.data)
          }
          break
        }
        case ContentType.WHATSAPP_CTA_URL_BUTTON:
          newData = WhatsappCTAUrlButtonFields.getCopy(node.data)
          idMapping = newData.getIdMappingForOldFields(node.data)
          break
        case ContentType.KNOWLEDGE_BASE:
          if (
            hasKnowledgeBaseFeature(state.organizationContents.featureFlags) &&
            state.currentFlowId === KNOWLEDGE_BASE_FLOW.id
          ) {
            newData = KnowledgeBaseFields.getCopy(node.data)
          }
          break
      }
    }
    if (!newData) return undefined
    this.removeContentIdIfExists(newData, state.nodes)
    const newPosition = {
      x: node.position.x + positionVector.x,
      y: node.position.y + positionVector.y,
    }
    newData.setErrors()
    newData.errors.showErrors = false
    newData.getLocalesWithErrors(state.locales.map(locale => locale.code))
    const newNode = NodeAction.createNewNode(
      newData,
      newPosition,
      state.currentFlowId
    )
    idMapping[node.id] = newNode.id
    return { idMapping, newNode }
  }

  private static removeContentIdIfExists = (
    newData: TopContentFields,
    nodes: NodeTypes[]
  ) => {
    if (nodes.some(node => node.data.code === newData.code)) {
      newData.code = ''
    }
  }

  private static pasteEdgesOnNodes = (
    newNodes: NodeTypes[],
    idMapping: IdMapping
  ): NodeTypes[] => {
    return newNodes.map(node => {
      const oldEdges = node.data.edges
      if (!oldEdges.length) return node
      const newEdges = this.getNewEdges(oldEdges, newNodes, idMapping)
      node.data.edges = newEdges
      return node
    })
  }

  private static getNewEdges = (
    edgesToCopy: Edge[],
    newNodes: NodeTypes[],
    idMapping: IdMapping
  ): Edge[] => {
    return edgesToCopy.reduce((newEdges: Edge[], edgeToCopy) => {
      const newEdgeIds = this.getNewEdgeIds(edgeToCopy, idMapping)
      if (newEdgeIds) {
        const { sourceId, sourceHandleId, targetId } = newEdgeIds
        const target = this.getTargetContentId(newNodes, targetId)
        if (target) {
          const newEdge = getNewEdge(sourceId, sourceHandleId, target)
          newEdges.push(newEdge)
        }
      }
      return newEdges
    }, [])
  }

  private static getNewEdgeIds = (edge: Edge, idMapping: IdMapping) => {
    const { target, source, sourceHandle } = edge
    const targetId = idMapping[target]
    const sourceId = idMapping[source]
    let sourceHandleId = sourceHandle ? idMapping[sourceHandle] : null
    if (hasFollowUpHandle(edge)) {
      sourceHandleId = sourceId
    }
    if (targetId && sourceId && sourceHandleId) {
      return { sourceId, sourceHandleId, targetId }
    }
    return undefined
  }

  private static getTargetContentId = (
    newNodes: NodeTypes[],
    targetId: string
  ): ContentId | undefined => {
    const targetNode = newNodes.find(node => node.id === targetId)
    if (targetNode) {
      return targetNode.data.getContentId()
    }
    return undefined
  }

  private static getPositionVector = (
    state: State,
    viewportOnCopy: Viewport,
    nodePosition: XYPosition
  ): XYPosition => {
    if (!state.reactFlowInstance || !state.reactFlowWrapper?.current) {
      return { x: 0, y: 0 }
    }
    const currentViewport = state.reactFlowInstance.getViewport()
    if (JSON.stringify(viewportOnCopy) === JSON.stringify(currentViewport)) {
      return { x: 50, y: 50 }
    }
    const { width, height } =
      state.reactFlowWrapper.current.getBoundingClientRect()
    const centerPosition = state.reactFlowInstance.project({
      x: width / 2,
      y: height / 2,
    })
    return {
      x: centerPosition.x - nodePosition.x,
      y: centerPosition.y - nodePosition.y,
    }
  }
}
