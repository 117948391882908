import { useEffect } from 'react'

export function useUndoRedo(undo: () => void, redo: () => void) {
  const onKeyDown = (evt: KeyboardEvent) => {
    if (evt.code === 'KeyZ' && (evt.ctrlKey || evt.metaKey) && evt.shiftKey) {
      evt.preventDefault()
      redo()
    } else if (evt.code === 'KeyZ' && (evt.ctrlKey || evt.metaKey)) {
      evt.preventDefault()
      undo()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [undo, redo])
}
