import { memo } from 'react'

import { AssetFields } from '../../../../domain/models/content-fields'
import { ARIA_LABEL } from '../../../roles'
import { HtIcon, Icon, Size, TextSmallBold } from '../../base'
import { StyledHtInput } from '../../base/ht-input/ht-input-styles'
import { InputAction } from '../constants'
import {
  AddImageIcon,
  ImageContainer,
  ImageLabel,
  ImageText,
} from '../editor-styles'

interface ImageFieldProps {
  changeProps?: { elementIndex: number }
  field: InputAction
  readOnly: boolean
  image?: string
  onChange: (action: any) => void
  uploadFile(file: File): Promise<AssetFields | null>
}

export const ContentImageField = memo((props: ImageFieldProps) => {
  return (
    <StyledHtInput>
      <TextSmallBold>Image</TextSmallBold>
      <ImageContainer $isReadOnly={props.readOnly}>
        {props.image ? (
          <>
            <img src={props.image} />
            {!props.readOnly && <RemoveImageButton {...props} />}
          </>
        ) : (
          <ImageText>Image</ImageText>
        )}
        {!props.readOnly && (
          <ImageLabel>
            <AddImageIcon />
            <ImageInput {...props} />
          </ImageLabel>
        )}
      </ImageContainer>
    </StyledHtInput>
  )
})

export const RemoveImageButton = memo((props: ImageFieldProps) => {
  const onClick = (evt: React.MouseEvent<SVGSVGElement>) => {
    evt.stopPropagation()
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: undefined,
    })
  }
  return <HtIcon icon={Icon.TRASH_CAN} size={Size.SMALL} onClick={onClick} />
})

export const ImageInput = memo((props: ImageFieldProps) => {
  const uploadImage = async (file: File) => {
    const newAsset = await props.uploadFile(file)
    if (!newAsset) return
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: newAsset,
    })
  }

  return (
    <input
      aria-label={ARIA_LABEL.IMAGE_INPUT}
      accept='image/jpeg, image/png'
      style={{ display: 'none' }}
      type='file'
      onChange={({ target }) => {
        if (target.files && target.files[0]) {
          uploadImage(target.files[0])
        }
      }}
    />
  )
})
