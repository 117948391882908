import { BotonicPackageNames } from '../../domain/models/bot-config-models'
import { useFlowBuilderSelector } from '../reducer/hooks'

export function useBotConfig(): {
  isBotActionConnectable: boolean
  isPreviewEnabled: boolean
} {
  const { botConfig, organizationContents } = useFlowBuilderSelector(
    ctx => ctx.state
  )

  const isAllowedToConnectBotActions = (): boolean => {
    if (!botConfig?.packages) return false
    const flowBuilderVersion =
      botConfig.packages[BotonicPackageNames.PluginFlowBuilder]?.version

    if (!flowBuilderVersion) {
      return false
    }

    return isVersionHigherOrEqualThan(flowBuilderVersion, '0.30.0')
  }

  const isPreviewEnabled = (): boolean => {
    const isPreviewEnabledByFeatureFlag =
      organizationContents.currentConversationalApp?.flowBuilderSettings
        ?.isPreviewEnabled

    if (isPreviewEnabledByFeatureFlag) return true

    if (!botConfig?.packages) return false

    const reactVersion = botConfig.packages[BotonicPackageNames.React]?.version

    if (!reactVersion) {
      return false
    }

    return isVersionHigherOrEqualThan(reactVersion, '0.28.0')
  }

  return {
    isBotActionConnectable: isAllowedToConnectBotActions(),
    isPreviewEnabled: isPreviewEnabled(),
  }
}

function isVersionHigherOrEqualThan(
  version: string,
  targetVersion: string
): boolean {
  const currentParsedVersion = parseVersion(version)
  const targetParsedVersion = parseVersion(targetVersion)

  if (currentParsedVersion.major !== targetParsedVersion.major) {
    return currentParsedVersion.major > targetParsedVersion.major
  }
  if (currentParsedVersion.minor !== targetParsedVersion.minor) {
    return currentParsedVersion.minor > targetParsedVersion.minor
  }
  return currentParsedVersion.patch >= targetParsedVersion.patch
}

function parseVersion(ver: string) {
  const [main, ...suffix] = ver.split('-')
  const [major, minor, patch] = main.split('.').map(Number)
  return { major, minor, patch, suffix: suffix.join('-') }
}
