import { memo } from 'react'

import { IntentNodeProps } from '../../types'
import { Icon } from '../base'
import { ChipList, InputHandles, NodeHeader } from './node-components'
import { InputNode } from './node-styles'

const IntentNode = ({ data, selected }: IntentNodeProps) => {
  return (
    <InputNode $isSelected={selected}>
      <NodeHeader data={data} title='Intents' icon={Icon.BRAIN} />
      <InputHandles contentId={data.getContentId()} />
      <ChipList items={data.intents} selected={selected} />
    </InputNode>
  )
}

export default memo(IntentNode)
