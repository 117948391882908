import cloneDeep from 'lodash.clonedeep'

import { Locale } from '../../../../domain/models/locales/locale'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { LocaleAction } from './locale-action'

export interface RemoveLocalesInterface {
  type: ActionType.REMOVE_LOCALES
  localesToRemove: Locale[]
}

export interface RemoveLocalesHistoryChange {
  type: ActionType.REMOVE_LOCALES
  removedLocales: Locale[]
  oldNodes: NodeTypes[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class RemoveLocalesAction extends ReversibleAction {
  static apply = (
    state: State,
    { localesToRemove }: RemoveLocalesInterface
  ): void => {
    if (localesToRemove.length === 0) return
    this.trackHistoryChange(state, localesToRemove)
    LocaleAction.removeLocales(state, localesToRemove)
    this.removeLocalesFromNodes(state, localesToRemove)
  }

  static undo = (state: State, change: RemoveLocalesHistoryChange) => {
    LocaleAction.addLocales(state, change.removedLocales)
    state.nodes = change.oldNodes
  }

  static redo = (state: State, change: RemoveLocalesHistoryChange) => {
    LocaleAction.removeLocales(state, change.removedLocales)
    this.removeLocalesFromNodes(state, change.removedLocales)
  }

  private static trackHistoryChange = (
    state: State,
    removedLocales: Locale[]
  ) => {
    const newChange: RemoveLocalesHistoryChange = {
      type: ActionType.REMOVE_LOCALES,
      removedLocales,
      oldNodes: state.nodes,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static removeLocalesFromNodes = (
    state: State,
    localesToRemove: Locale[]
  ): void => {
    const newNodes = cloneDeep(state.nodes)
    const codesOfLocalesToRemove = localesToRemove.map(locale => locale.code)
    newNodes.forEach(node => node.data.removeLocales(codesOfLocalesToRemove))
    state.nodes = newNodes
  }
}
