import { FeedbackMessage } from '../../../../domain/feedback'
import { deepEqual } from '../../../../utils/object-utils'
import { State } from '../../../types'
import { ActionType } from '../../action-types'

export interface AddFeedbackMessageInterface {
  type: ActionType.ADD_FEEDBACK_MESSAGE
  message: FeedbackMessage
}

export class AddFeedbackMessageAction {
  static apply = (
    state: State,
    { message }: AddFeedbackMessageInterface
  ): void => {
    this.addFeedbackMessage(state, message)
  }

  private static addFeedbackMessage = (
    state: State,
    newMessage: FeedbackMessage
  ): void => {
    const messageExists = state.feedback.some(
      m =>
        m.message === newMessage.message &&
        deepEqual(m.details, newMessage.details)
    )
    if (messageExists) return

    state.feedback = [newMessage, ...state.feedback]
  }
}
