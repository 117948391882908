import styled from 'styled-components'

import { COLORS } from './components/base'
import { LEFT_PANEL_MARGIN } from './constants'

export const FlowWrapper = styled.div`
  height: calc(100% - 46px);
  margin-top: 46px;
  flex-grow: 1;
  background-color: ${COLORS.N700};
`

export const FlowContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`

export const Container = styled.div<{ height: string }>`
  height: ${props => props.height};
  background-color: rgba(65, 63, 72, 0.5);
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
`

export const LeftContainer = styled.div`
  max-height: calc(100% - 32px);
  position: absolute;
  left: ${LEFT_PANEL_MARGIN}px;
  top: 16px;
  display: flex;
  gap: 16px;
  user-select: none;
  -webkit-user-select: none;

  > div {
    z-index: 10;

    &:first-child {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`
