import styled, { css } from 'styled-components'

import { HelperText } from '../base-styles'
import { COLORS } from '../colors'
import { TextSmall } from '../typography'

export const Checkmark = styled.div`
  height: 8px;
  width: 8px;
  background: ${COLORS.L500};
  border-radius: 2px;
`

export const CheckmarkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12px;
  height: 12px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.N400};
  border-radius: 4px;
`

export const Checkbox = styled.label<{
  checked?: boolean
  disabled?: boolean
}>`
  display: flex;
  align-items: baseline;
  gap: 8px;
  cursor: pointer;
  ${props => props.disabled && disableStyles};

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
  }

  > ${CheckmarkContainer} > ${Checkmark} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

export const CheckboxLabel = styled(TextSmall)`
  align-items: center;
  color: ${COLORS.N800};
`

export const CheckboxHelperText = styled(HelperText)`
  margin-top: 8px;
`

const disableStyles = css`
  cursor: default;

  ${CheckboxLabel} {
    color: ${COLORS.N500};
  }

  ${Checkmark} {
    background: ${COLORS.L300};
  }
`
