import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { Icon } from '../ht-icon/icons-list'

export const StyledNotification = styled.div<{ $isAi?: boolean }>`
  background-color: ${COLORS.N900};
  color: ${COLORS.WHITE};
  padding: 5px 10px;
  border-radius: 4px;
  padding: 14px 18px;
  white-space: pre-line;
  display: flex;
  align-items: center;
  gap: 8px;

  ${props => props.$isAi && aiNotificationStyles}

  [data-icon=${Icon.XMARK}] {
    color: ${COLORS.N300};
  }

  [data-icon=${Icon.CLOUD_SLASH}] {
    color: ${COLORS.R300} !important;
  }

  [data-icon=${Icon.CLOUD_CHECK}] {
    color: ${COLORS.G300} !important;
  }

  > :last-child {
    margin-left: auto;
  }
`

export const aiNotificationStyles = css`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.N900};
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12);

  [data-icon=${Icon.CIRCLE_CHECK}] {
    color: ${COLORS.G600};
  }

  [data-icon=${Icon.CIRCLE_XMARK}] {
    color: ${COLORS.R600};
  }

  [data-icon=${Icon.XMARK}] {
    color: ${COLORS.N500};
  }
`

export const StyledAiNotificationWithActions = styled(StyledNotification)`
  ${aiNotificationStyles}
  flex-direction: column;
  align-items: start;

  > :first-child {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    > svg:last-child {
      margin-left: auto;
    }
  }

  > :last-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: inherit;
  }
`
