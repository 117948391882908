import { ActionType } from '../../action-types'
import {
  AddFeedbackMessageAction,
  AddFeedbackMessageInterface,
} from './add-feedback-message'
import {
  RemoveFeedbackMessagesAction,
  RemoveFeedbackMessagesInterface,
} from './remove-feedback-messages'

export { AddFeedbackMessageAction, RemoveFeedbackMessagesAction }

export type FeedbackActionInterface =
  | AddFeedbackMessageInterface
  | RemoveFeedbackMessagesInterface

export const feedbackActionMap = {
  [ActionType.ADD_FEEDBACK_MESSAGE]: AddFeedbackMessageAction,
  [ActionType.REMOVE_FEEDBACK_MESSAGES]: RemoveFeedbackMessagesAction,
}
