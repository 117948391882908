import styled from 'styled-components'

import { COLORS } from '../../base'

export const StyledStartNode = styled.div<{ $hasPreviousFlows: boolean }>`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.N900};
  width: ${props => (props.$hasPreviousFlows ? '265px' : `220px`)};
  box-shadow: 0px 1.034px 2.068px 0px rgba(102, 102, 102, 0.1);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: auto;
  background-color: ${COLORS.G400};
  border-radius: 24px;

  svg {
    color: ${COLORS.G600};
  }
`

export const StyledStartNodeHeader = styled.div`
  display: flex;
  padding: 12px 20px 12px 20px;
  border-radius: 24px 24px 0 0;
  flex-direction: row;
  gap: 16px;
`

export const StyledPreviousFlowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.G100};
  padding: 12px 20px 12px 20px;
  border-radius: 0 0 24px 24px;
  width: 225px;
`

export const StyledGoToPreviousFlowsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`

export const StyledGoToFlowItemContainer = styled.div<{ $isLast: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${props =>
    props.$isLast ? 'none' : `1px solid ${COLORS.N200}`};
  padding: ${props => (props.$isLast ? '12px 0px 0px 0px' : `12px 0px`)};
`
