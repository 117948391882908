import { FC } from 'react'

import { FlowBuilderContext, FlowBuilderContextProps } from './context'

export const FlowBuilderProvider: FC<{
  store: FlowBuilderContextProps
  children: any
}> = ({ children, store }) => {
  return (
    <FlowBuilderContext.Provider value={store}>
      {children}
    </FlowBuilderContext.Provider>
  )
}
