import { useEffect } from 'react'
import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
  useUpdateNodeInternals,
} from 'reactflow'

import { ConditionalContentType } from '../../domain/models/content-fields'
import {
  Icon,
  TextBodySmall,
  TextBodySmallBold,
} from '../../UI/components/base'
import {
  Condition,
  Handles,
  NodeHeader,
} from '../../UI/components/nodes/node-components'
import {
  Border,
  ConditionalNode,
  StyledList,
} from '../../UI/components/nodes/node-styles'
import { CustomConditionFields, VariableFormat } from './model'

export interface CustomConditionNodeInterface
  extends ReactFlowNode<CustomConditionFields> {
  data: CustomConditionFields
  type: ConditionalContentType.CUSTOM_CONDITION
}

export interface CustomConditionNodeProps
  extends ReactFlowNodeProps<CustomConditionFields> {
  data: CustomConditionFields
  type: ConditionalContentType.CUSTOM_CONDITION
}

export const CustomConditionNode = ({
  data,
  selected,
  id,
}: CustomConditionNodeProps) => {
  const updateNodeInternals = useUpdateNodeInternals()
  useEffect(() => updateNodeInternals(id), [data.values])

  return (
    <ConditionalNode $isSelected={selected}>
      <Handles contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Custom'} icon={Icon.SPLIT} />
      <hr />
      <span>
        <TextBodySmallBold>If</TextBodySmallBold>
        {data.conditionVariable && (
          <TextBodySmall $wordBreak='break-word'>
            {data.conditionVariable}
          </TextBodySmall>
        )}
      </span>
      <StyledList>
        {data.values.map((value, i) => (
          <Condition
            key={i}
            id={`${id}-${value.id}`}
            border={i === 0 ? Border.NONE : Border.TOP}
          >
            <span>
              <TextBodySmallBold>is</TextBodySmallBold>
              {value.name}
            </span>
          </Condition>
        ))}
        {data.variableFormat !== VariableFormat.BOOLEAN && (
          <Condition id={id + '-default'} border={Border.TOP}>
            Otherwise
          </Condition>
        )}
      </StyledList>
    </ConditionalNode>
  )
}
