import styled from 'styled-components'

import { COLORS, TextBodySmallBold } from '../../base'

export const ComponentTitle = styled(TextBodySmallBold)`
  width: fit-content;
  display: flex;
  align-items: baseline;
  gap: 4px;
  padding: 8px 16px 16px;
  color: ${COLORS.N900};
  cursor: default;

  svg {
    color: ${COLORS.N500};
    margin-left: 4px;
  }
`

export const StyledFooter = styled.div`
  padding: 16px 16px 8px 16px;
  border-top: 1px solid ${COLORS.N100};
`
