import { useEffect } from 'react'
import { ReactFlowProvider } from 'reactflow'

import { useRepository } from '../repository/repository-utils'
import { SaveOrigin } from './analytics'
import { FeedbackPanel } from './components/feedback-panel/feedback-panel'
import { Header } from './components/header/header'
import { usePublish } from './components/header/publish/use-publish'
import { useSave } from './components/header/save/use-save'
import { VersionHistoryData } from './components/header/version-history-dropdown/version-history-dropdown'
import { LocalesPanel } from './components/locale-panel/locales-panel'
import { NodeEditorPanel } from './components/node-editor-panel/node-editor-panel'
import { renderPopup } from './components/popup/popup'
import { VersionBanner } from './components/version-banner/version-banner'
import { DRAFT_VERSION } from './constants'
import { SessionMode, useAbly } from './custom-hooks/use-ably'
import { useLoadFlow } from './custom-hooks/use-load-flow'
import Flow from './flow'
import { FlowContainer } from './flow-styles'
import { useFlowBuilderSelector } from './reducer/hooks'
import { LoadingMessage, PopupType } from './types'
import { useFlowBuilderFeedback } from './use-flow-builder-feedback'

const FlowBuilder = (): JSX.Element => {
  const { reportError } = useFlowBuilderFeedback()
  const {
    state,
    removeFeedbackMessages,
    restoreChangeHistory,
    setLoadingMessage,
    setPopupContent,
    toggleFlowSaved,
    toggleInteractivity,
    setCurrentVersion,
  } = useFlowBuilderSelector(ctx => ctx)

  const authToken = state.authToken

  const { loadInitialFlow, loadFlow } = useLoadFlow()
  const repository = useRepository()
  const { saveFlow } = useSave()
  const { getVersionHistoryData } = usePublish()
  const {
    sessionMode,
    flowBuilderUser,
    membersInfo,
    handleKickEditingUsers,
    handleDisconnectMyself,
  } = useAbly(authToken)

  useEffect(() => {
    if (!flowBuilderUser || !membersInfo) {
      return
    }

    const isFlowAlreadyLoaded = state.nodes.length > 0

    if (sessionMode === SessionMode.Edit) {
      setPopupContent()
      if (!isFlowAlreadyLoaded) {
        setLoadingMessage(LoadingMessage.LOADING_CONTENT)
        loadInitialFlow()
        getVersionHistoryData()
      }
      return
    }

    if (sessionMode === SessionMode.Lobby) {
      setPopupContent({
        type: PopupType.SESSION_CONFLICT,
        membersInfo,
        onConfirm: handleKickEditingUsers,
        onDiscard: handleDisconnectMyself,
        closeData: null,
      })
      return
    }

    if (sessionMode === SessionMode.SessionEnded) {
      setPopupContent({
        type: PopupType.SESSION_ENDED,
        membersInfo,
        closeData: null,
      })
      return
    }
  }, [sessionMode, flowBuilderUser, membersInfo])

  const uploadFile = async (file: File) => {
    const asset = await repository.cmsWriter.uploadFile(authToken, file)
    if (!asset) {
      reportError('Error uploading image')
    }
    return asset
  }

  const loadPublishedVersion = async (
    version: VersionHistoryData
  ): Promise<void> => {
    setLoadingMessage(LoadingMessage.LOADING_VERSION)
    if (!state.isFlowSaved) {
      await saveFlow(SaveOrigin.ON_OPEN_PREVIOUS_VERSION)
    }
    restoreChangeHistory()
    removeFeedbackMessages()
    await loadFlow(state.currentLocale, version.id, state.organizationContents)
    setLoadingMessage(undefined)
    toggleInteractivity(false)
    toggleFlowSaved(true)
    setCurrentVersion(version)
  }

  const restoreDraftFlow = async (): Promise<void> => {
    setCurrentVersion(undefined)
    toggleInteractivity(true)
    setLoadingMessage(LoadingMessage.LOADING_CONTENT)
    await loadFlow(
      state.currentLocale,
      DRAFT_VERSION,
      state.organizationContents
    )
  }

  return (
    <>
      {sessionMode === SessionMode.Edit && (
        <ReactFlowProvider>
          <VersionBanner restoreDraftFlow={restoreDraftFlow} />
          {!state.isReadOnly && !state.loadingMessage && <FeedbackPanel />}
          {state.nodes.length > 0 && (
            <FlowContainer id='flow-container'>
              <Header loadPublishedVersion={loadPublishedVersion} />
              {state.isLocalesPanelOpen && <LocalesPanel />}
              <Flow />
              {state.currentNode && <NodeEditorPanel uploadFile={uploadFile} />}
            </FlowContainer>
          )}
        </ReactFlowProvider>
      )}
      {state.popupContent && renderPopup(state.popupContent)}
    </>
  )
}
export default FlowBuilder
