import { memo } from 'react'

import { Size, TextBodySmall } from '../base'
import { HtIcon, Icon } from '../base'
import { StyledTooltip } from '../material-ui-components-styles'

interface BuildComponentTooltipProps {
  title: string
  tooltipText: string
}

export const BuildComponentTooltip = memo(
  ({ title, tooltipText }: BuildComponentTooltipProps) => {
    return (
      <StyledTooltip title={tooltipText} placement='right-start'>
        <TextBodySmall>
          {title}
          <HtIcon icon={Icon.CIRCLE_QUESTION} size={Size.MICRO} />
        </TextBodySmall>
      </StyledTooltip>
    )
  }
)
