import { useEffect } from 'react'

import {
  GoToFlowFields,
  MessageContentType,
} from '../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../reducer/hooks'

export const usePreviousFlows = () => {
  const { currentFlowId, nodes, flows, computedPreviousFlows } =
    useFlowBuilderSelector(ctx => ctx.state)

  const { setComputedPreviousFlows } = useFlowBuilderSelector(ctx => ctx)

  useEffect(() => {
    const goToFlowNodeIds = nodes
      .filter(n => n.type === MessageContentType.GO_TO_FLOW)
      .filter(n => (n.data as GoToFlowFields).targetFlowId === currentFlowId)
      .map(n => (n.data as GoToFlowFields).flowId)

    const updatedFlows = computedPreviousFlows.map(flow =>
      flow.id === currentFlowId
        ? {
            ...flow,
            previousFlows: flows.filter(flow =>
              goToFlowNodeIds.includes(flow.id)
            ),
          }
        : flow
    )

    if (!updatedFlows.some(flow => flow.id === currentFlowId)) {
      updatedFlows.push({
        id: currentFlowId,
        previousFlows: flows.filter(flow => goToFlowNodeIds.includes(flow.id)),
        showPreviousFlows: false,
      })
    }

    setComputedPreviousFlows(updatedFlows)
  }, [currentFlowId, flows])

  const setShowPreviousFlows = (showPreviousFlows: boolean) => {
    setComputedPreviousFlows(
      computedPreviousFlows.map(flow => {
        if (flow.id === currentFlowId) {
          return { ...flow, showPreviousFlows }
        }
        return flow
      })
    )
  }

  return {
    flowWithComputedPreviousFlows: computedPreviousFlows.find(
      flow => flow.id === currentFlowId
    ),
    setShowPreviousFlows,
  }
}
