import {
  Edge,
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
  ReactFlowInstance,
} from 'reactflow'

import { FeedbackMessage } from '../../domain/feedback'
import { BotConfig } from '../../domain/models/bot-config-models'
import {
  BotActionFields,
  CarouselFields,
  ChannelFields,
  ContentType,
  CountryConditionFields,
  FallbackFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  InputContentType,
  IntentFields,
  KeywordFields,
  MessageContentType,
  PayloadFields,
  QueueStatusFields,
  StartContentType,
  StartFields,
  TextFields,
  UrlFields,
  VideoFields,
  WhatsappButtonListFields,
} from '../../domain/models/content-fields'
import { LocaleCode } from '../../domain/models/locales/code'
import { CountryCode } from '../../domain/models/locales/country-code'
import { Locale } from '../../domain/models/locales/locale'
import {
  ConversationalApp,
  FlowBuilderUser,
  OrganizationAiModel,
  OrganizationAiModelWithIntents,
  OrganizationKnowledgeSource,
  OrganizationProject,
} from '../../domain/models/organization-models'
import { CustomConditionNodeInterface } from '../../nodes/custom-conditional'
import { KnowledgeBaseNodeInterface } from '../../nodes/knowledge-base'
import { SmartIntentNodeInterface } from '../../nodes/smart-intent/node'
import { WhatsappCTAUrlButtonNodeInterface } from '../../nodes/whatsapp-cta-url-button'
import { VersionHistoryData } from '../components/header/version-history-dropdown/version-history-dropdown'
import { MembersInfo } from '../custom-hooks/use-ably'
import { ChangesHistory } from '../reducer/action-types'
import { ConditionalContentType } from './../../domain/models/content-fields'

export type IdMapping = Record<string, string>

export interface TextNode extends ReactFlowNode<TextFields> {
  data: TextFields
  type: MessageContentType.TEXT
}

export interface TextNodeProps extends ReactFlowNodeProps<TextFields> {
  data: TextFields
  type: MessageContentType.TEXT
}

export interface CarouselNode extends ReactFlowNode<CarouselFields> {
  data: CarouselFields
  type: MessageContentType.CAROUSEL
}

export interface CarouselNodeProps extends ReactFlowNodeProps<CarouselFields> {
  data: CarouselFields
  type: MessageContentType.CAROUSEL
}

export interface ImageNode extends ReactFlowNode<ImageFields> {
  data: ImageFields
  type: MessageContentType.IMAGE
}

export interface ImageNodeProps extends ReactFlowNodeProps<ImageFields> {
  data: ImageFields
  type: MessageContentType.IMAGE
}

export interface VideoNode extends ReactFlowNode<VideoFields> {
  data: VideoFields
  type: MessageContentType.VIDEO
}

export interface VideoNodeProps extends ReactFlowNodeProps<VideoFields> {
  data: VideoFields
  type: MessageContentType.VIDEO
}

export interface HandoffNode extends ReactFlowNode<HandoffFields> {
  data: HandoffFields
  type: MessageContentType.HANDOFF
}

export interface HandoffNodeProps extends ReactFlowNodeProps<HandoffFields> {
  data: HandoffFields
  type: MessageContentType.HANDOFF
}

export interface IntentNode extends ReactFlowNode<IntentFields> {
  data: IntentFields
  type: InputContentType.INTENT
}

export interface IntentNodeProps extends ReactFlowNodeProps<IntentFields> {
  data: IntentFields
  type: InputContentType.INTENT
}

export interface KeywordNode extends ReactFlowNode<KeywordFields> {
  data: KeywordFields
  type: InputContentType.KEYWORD
}

export interface KeywordNodeProps extends ReactFlowNodeProps<KeywordFields> {
  data: KeywordFields
  type: InputContentType.KEYWORD
}

export interface QueueStatusNode extends ReactFlowNode<QueueStatusFields> {
  data: QueueStatusFields
  type: ConditionalContentType.QUEUE_STATUS
}

export interface QueueStatusNodeProps
  extends ReactFlowNodeProps<QueueStatusFields> {
  data: QueueStatusFields
  type: ConditionalContentType.QUEUE_STATUS
}

export interface ChannelNode extends ReactFlowNode<ChannelFields> {
  data: ChannelFields
  type: ConditionalContentType.CHANNEL
}

export interface ChannelNodeProps extends ReactFlowNodeProps<ChannelFields> {
  data: ChannelFields
  type: ConditionalContentType.CHANNEL
}

export interface FallbackNode extends ReactFlowNode<FallbackFields> {
  data: FallbackFields
  type: ConditionalContentType.FALLBACK
}

export interface FallbackNodeProps extends ReactFlowNodeProps<FallbackFields> {
  data: FallbackFields
  type: ConditionalContentType.FALLBACK
}

export interface WhatsappButtonListNode
  extends ReactFlowNode<WhatsappButtonListFields> {
  data: WhatsappButtonListFields
  type: MessageContentType.WHATSAPP_BUTTON_LIST
}

export interface WhatsappButtonListNodeProps
  extends ReactFlowNodeProps<WhatsappButtonListFields> {
  data: WhatsappButtonListFields
  type: MessageContentType.WHATSAPP_BUTTON_LIST
}

export interface GoToFlowNode extends ReactFlowNode<GoToFlowFields> {
  data: GoToFlowFields
  type: MessageContentType.GO_TO_FLOW
}

export interface GoToFlowNodeProps extends ReactFlowNodeProps<GoToFlowFields> {
  data: GoToFlowFields
  type: MessageContentType.GO_TO_FLOW
}

export interface BotActionNode extends ReactFlowNode<BotActionFields> {
  data: BotActionFields
  type: MessageContentType.BOT_ACTION
}

export interface BotActionNodeProps
  extends ReactFlowNodeProps<BotActionFields> {
  data: BotActionFields
  type: MessageContentType.BOT_ACTION
}

export interface StartNode extends ReactFlowNode<StartFields> {
  data: StartFields
  type: StartContentType.START
}

export interface StartNodeProps extends ReactFlowNodeProps<StartFields> {
  data: StartFields
  type: StartContentType.START
}

export interface CountryConditionNode
  extends ReactFlowNode<CountryConditionFields> {
  data: CountryConditionFields
  type: ConditionalContentType.COUNTRY_CONDITION
}

export interface CountryConditionNodeProps
  extends ReactFlowNodeProps<CountryConditionFields> {
  data: CountryConditionFields
  type: ConditionalContentType.COUNTRY_CONDITION
}

export type NodeTypes =
  | TextNode
  | CarouselNode
  | ImageNode
  | HandoffNode
  | KeywordNode
  | IntentNode
  | QueueStatusNode
  | ChannelNode
  | FallbackNode
  | VideoNode
  | WhatsappButtonListNode
  | GoToFlowNode
  | BotActionNode
  | StartNode
  | CountryConditionNode
  | CustomConditionNodeInterface
  | SmartIntentNodeInterface
  | WhatsappCTAUrlButtonNodeInterface
  | KnowledgeBaseNodeInterface

export type FlowElement = NodeTypes | Edge

export interface State {
  currentAiModel?: OrganizationAiModelWithIntents
  currentLocale: Locale
  currentNode?: NodeTypes
  nodes: NodeTypes[]
  isLocalesPanelOpen: boolean
  locales: Locale[]
  nonMessageContents: NonMessageContents
  organizationContents: OrganizationContents
  popupContent?: PopupContent
  feedback: FeedbackMessage[]
  reactFlowInstance?: ReactFlowInstance
  reactFlowWrapper?: React.RefObject<any>
  authToken: string
  isReadOnly: boolean
  botVariables: string[]
  isKnowledgeBaseActive: boolean
  isFlowSaved: boolean
  changeHistory: ChangesHistory[]
  historyIndex: number
  flows: Flow[]
  computedPreviousFlows: ComputedPreviousFlow[]
  currentFlowId: string
  webviews: Webview[]
  currentWebviewId?: string
  hashPublished: string
  hash: string
  botConfig?: BotConfig
  loadingMessage?: LoadingMessage
  currentVersion?: VersionHistoryData
  isOffline: boolean
  versionHistoryData: VersionHistoryData[]
  flowBuilderUser?: FlowBuilderUser
}

export interface Flow {
  id: string
  name: string
}

export interface ComputedPreviousFlow {
  id: string
  previousFlows: Flow[]
  showPreviousFlows: boolean
}
export interface Webview {
  id: string
  name: string
}

export interface NonMessageContents {
  payloads: PayloadFields[]
  urls: UrlFields[]
}

export interface OrganizationContents {
  projects: OrganizationProject[]
  aiModels: OrganizationAiModel[]
  featureFlags: Record<string, boolean>
  knowledgeSources: OrganizationKnowledgeSource[]
  currentConversationalApp: ConversationalApp
  conversationalApps: ConversationalApp[]
}

export type PopupContent =
  | { type: PopupType.ADD_FLOW }
  | { type: PopupType.INTENT_MODELS }
  | { type: PopupType.KNOWLEDGE_BASE }
  | { type: PopupType.DELETE_FLOW; flowToRemove: Flow; linkedFlows: Flow[] }
  | { type: PopupType.EDIT_FLOW; flowToEdit: Flow }
  | { type: PopupType.PUBLISH }
  | { type: PopupType.REMOVE_LOCALES; localesToRemove: Locale[] }
  | { type: PopupType.RESTORE_VERSION }
  | { type: PopupType.ADD_FLOW }
  | { type: PopupType.DELETE_PAYLOAD; payloadToRemove: PayloadFields }
  | { type: PopupType.DELETE_URL; urlToRemove: UrlFields }
  | { type: PopupType.EDIT_URL; urlToEdit: UrlFields }
  | { type: PopupType.TRANSFER_FLOW }
  | {
      type: PopupType.SESSION_CONFLICT
      membersInfo?: MembersInfo
      onConfirm: () => void
      onDiscard: () => void
      closeData: string | null
    }
  | {
      type: PopupType.SESSION_ENDED
      membersInfo?: MembersInfo
      closeData: string | null
    }

export enum PopupType {
  ADD_FLOW = 'Add flow',
  INTENT_MODELS = 'Intent models',
  KNOWLEDGE_BASE = 'Knowledge base',
  DELETE_FLOW = 'Delete flow',
  EDIT_FLOW = 'Rename flow',
  EXIT = 'Exit Flow Builder',
  PUBLISH = 'Publish conversational app',
  REMOVE_LOCALES = 'Remove languages',
  RESTORE_VERSION = 'Restore version',
  DELETE_PAYLOAD = 'Delete payload in use',
  DELETE_URL = 'Delete URL in use',
  EDIT_URL = 'Edit URL',
  SESSION_CONFLICT = 'Flow currently being edited',
  SESSION_ENDED = 'Session ended',
  TRANSFER_FLOW = 'Transfer flow',
}

export interface FlowContent {
  nodes: NodeTypes[]
  isPublished?: boolean
  nonMessageContents: NonMessageContents
  aiModelId?: string
  isKnowledgeBaseActive: boolean
  locales: string[]
  botVariables: string[]
  flows: Flow[]
  hash: string
}

export interface Credentials {
  accessToken: string
  environment: string
  manageToken: string
  spaceId: string
}

export enum EdgeType {
  BEZIER = 'Bezier',
}

export interface TreeEntryContent {
  id: string
  nodeId: string
  code: string
  contentType: ContentType
  children?: TreeEntryContent[]
  isLink?: boolean
}

export enum LoadingMessage {
  ADD_LOCALES = 'Adding locales',
  LOADING_APP = 'Loading Flow Builder',
  LOADING_CONTENT = 'Loading content',
  LOADING_VERSION = 'Loading version',
  PUBLISH = 'Publishing',
  REMOVE_LOCALES = 'Removing locales',
  SAVE = 'Saving',
  RESTORING_VERSION = 'Restoring version',
}

export enum PreviewUrlParams {
  BOT_ID = 'bot_id',
  IS_SHARED = 'shared',
  WEBCHAT_OPEN_AUTO = 'open',
  WEBCHAT_APP_ID = 'app_id',
  WEBCHAT_REFRESH_COUNT = 'refresh_count',
  BUNDLE_LOCATION = 'bundle_location',
  CURRENT_LOCALE = 'current',
  CURRENT_COUNTRY = 'currentCountry',
  LOCALES = 'locales',
}

export interface FlowBuilderPreviewState {
  hasFlowErrors: boolean
  current: LocaleCode
  locales: LocaleCode[]
  currentCountry?: CountryCode
}

export interface WebchatDimensions {
  ready: boolean
  width: number
  height: number
}
