import { ContentType } from '../../domain/models/content-fields'
import { HtIcon, Icon, Size } from '../../UI/components/base'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { Node } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'

export const WebviewTextBuildNode = () => {
  return (
    <Node onDragStart={event => onDragStart(event, ContentType.TEXT)} draggable>
      <BuildComponentTooltip title='Text' tooltipText='Webview text' />
      <HtIcon icon={Icon.TEXT} size={Size.LARGE} />
    </Node>
  )
}
