import { Edge } from 'reactflow'

import { ContentType } from '../../../../domain/models/content-fields'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { EdgeAction } from '../edge-actions/edge-action'
import { ReversibleAction } from '../reversible-action'
import { NodeAction } from './node-action'

export interface RemoveNodesInterface {
  type: ActionType.REMOVE_NODES
  nodesToRemove: NodeTypes[]
}

export interface RemoveNodesHistoryChange {
  type: ActionType.REMOVE_NODES
  removedNodes: NodeTypes[]
  removedEdges: Edge[]
  currentNode?: NodeTypes
  currentFlowId: string
}

export class RemoveNodesAction extends ReversibleAction {
  static apply = (state: State, nodesToRemove: NodeTypes[]) => {
    nodesToRemove = nodesToRemove.filter(
      nodes => nodes.type !== ContentType.FALLBACK
    )
    const currentNode = state.currentNode
    state.popupContent = undefined
    const removedEdges = this.removeNodesAndEdges(state, nodesToRemove)
    this.trackHistoryChange(state, nodesToRemove, removedEdges, currentNode)
  }

  static undo = (state: State, change: RemoveNodesHistoryChange) => {
    NodeAction.addNodes(state, change.removedNodes)
    EdgeAction.addEdges(state, change.removedEdges)
    NodeAction.setSelectedNodes(state, [])
  }

  static redo = (state: State, change: RemoveNodesHistoryChange) => {
    this.removeNodesAndEdges(state, change.removedNodes)
  }

  private static trackHistoryChange = (
    state: State,
    removedNodes: NodeTypes[],
    removedEdges: Edge[],
    currentNode?: NodeTypes
  ) => {
    const newChange: RemoveNodesHistoryChange = {
      type: ActionType.REMOVE_NODES,
      removedNodes,
      removedEdges,
      currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  static removeNodes = (state: State, nodesToRemove: NodeTypes[]) => {
    if (this.isRemovingCurrentNode(state, nodesToRemove)) {
      state.currentNode = undefined
    }
    NodeAction.removeNodes(state, nodesToRemove)
  }

  static removeNodesAndEdges = (
    state: State,
    nodesToRemove: NodeTypes[]
  ): Edge[] => {
    this.removeNodes(state, nodesToRemove)
    const nodeIdsToRemove = nodesToRemove.map(node => node.id)
    return this.removeEdgesTargetingRemovedNodes(state, nodeIdsToRemove)
  }

  private static removeEdgesTargetingRemovedNodes = (
    state: State,
    nodeIdsToRemove: string[]
  ): Edge[] => {
    const edgesTargetingRemovedNodes = state.nodes
      .filter(node => !nodeIdsToRemove.includes(node.id))
      .flatMap(node => node.data.edges)
      .filter(edge => nodeIdsToRemove.includes(edge.target))
    EdgeAction.removeEdges(state, edgesTargetingRemovedNodes)
    return edgesTargetingRemovedNodes
  }

  private static isRemovingCurrentNode = (
    state: State,
    nodeIdsToRemove: NodeTypes[]
  ): boolean => {
    return nodeIdsToRemove?.some(node => node.id === state.currentNode?.id)
  }
}
