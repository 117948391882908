import { Item } from 'react-stately'

import { DropdownData } from '../common/dropdown/types'
import { Menu, MenuProps } from './widgets/menu'

export interface HtMenuDropdownProps<T extends DropdownData>
  extends Omit<MenuProps<T>, 'children'> {
  data: T[]
  children?: (item: T) => JSX.Element | string
  onChange: (value?: T) => void
}

export const HtMenuDropdown = <T extends DropdownData>(
  props: HtMenuDropdownProps<T>
): JSX.Element => {
  const onSelectionChange = (id: React.Key) => {
    const selectedValue = props.data.find(item => item.id === id)
    props.onChange(selectedValue)
  }

  return (
    <Menu {...props} items={props.data} onAction={onSelectionChange}>
      {item => (
        <Item textValue={item.id}>
          {props.children?.(item) || item.name || item.id}
        </Item>
      )}
    </Menu>
  )
}
