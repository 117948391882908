import { Draft, produce } from 'immer'
import cloneDeep from 'lodash.clonedeep'

import { State } from '../types'
import { Action, ActionType } from './action-types'
import {
  SelectAiModelAction,
  SetKnowledgeBaseActiveAction,
} from './actions/ai-actions'
import { RemoveEdgesAction, SelectEdgeAction } from './actions/edge-actions'
import {
  AddFeedbackMessageAction,
  RemoveFeedbackMessagesAction,
} from './actions/feedback-actions'
import {
  AddLocalesAction,
  RemoveLocalesAction,
  SetLocalesAction,
} from './actions/locale-actions'
import { SelectLocaleAction } from './actions/locale-actions/select-locale'
import { NodeAction } from './actions/node-actions/node-action'
import {
  AddPayloadsAction,
  AddUrlAction,
  EditUrlAction,
  RemovePayloadsAction,
  RemoveUrlsAction,
  SetBotVariablesAction,
} from './actions/non-message-actions'
import {
  AddWebviewAction,
  RemoveWebviewAction,
  SelectWebviewAction,
  SetWebviewsAction,
} from './actions/webview-actions'
import { nodeReducer } from './node-reducer'

export function reducer(state: State, action: Action): Draft<State> {
  // eslint-disable-next-line
  return produce((draftState: Draft<State>, appraisalAction: Action) => {
    switch (appraisalAction.type) {
      case ActionType.SET_LOCALES:
        SetLocalesAction.apply(draftState, appraisalAction)
        break

      case ActionType.ADD_LOCALES:
        AddLocalesAction.apply(draftState, appraisalAction)
        break

      case ActionType.REMOVE_LOCALES:
        RemoveLocalesAction.apply(draftState, appraisalAction)
        break

      case ActionType.SELECT_EDGES: {
        SelectEdgeAction.apply(draftState, appraisalAction)
        break
      }

      case ActionType.TOGGLE_INTERACTIVITY: {
        draftState.isReadOnly = !appraisalAction.isInteractive
        break
      }

      case ActionType.REMOVE_EDGES_BY_ID:
        RemoveEdgesAction.apply(draftState, appraisalAction)
        break

      case ActionType.REMOVE_FEEDBACK_MESSAGES:
        RemoveFeedbackMessagesAction.apply(draftState, appraisalAction)
        break

      case ActionType.TOGGLE_FLOW_SAVED:
        draftState.isFlowSaved = appraisalAction.isFlowSaved
        break

      case ActionType.SET_LOADING_MESSAGE:
        draftState.loadingMessage = appraisalAction.message
        break

      case ActionType.SET_CURRENT_VERSION:
        draftState.currentVersion = appraisalAction.currentVersion
        break

      case ActionType.TOGGLE_IS_OFFLINE:
        draftState.isOffline = appraisalAction.isOffline
        break

      case ActionType.SELECT_LOCALE:
        SelectLocaleAction.apply(draftState, appraisalAction)
        break

      case ActionType.SET_REACT_FLOW_REFS:
        draftState.reactFlowInstance = appraisalAction.reactFlowInstance
        draftState.reactFlowWrapper = cloneDeep(
          appraisalAction.reactFlowWrapper
        )
        break
      case ActionType.SET_COMPUTED_PREVIOUS_FLOWS:
        draftState.computedPreviousFlows = appraisalAction.computedPreviousFlows
        break

      case ActionType.SET_AUTH_TOKEN:
        draftState.authToken = appraisalAction.authToken
        break

      case ActionType.SET_ORGANIZATION_CONTENTS:
        draftState.organizationContents = appraisalAction.organizationContents
        break

      case ActionType.SET_BOT_VARIABLES:
        SetBotVariablesAction.apply(draftState, appraisalAction)
        break

      case ActionType.SELECT_AI_MODEL:
        SelectAiModelAction.apply(draftState, appraisalAction)
        break

      case ActionType.CLOSE_NODE_EDITOR:
        draftState.currentNode = undefined
        NodeAction.setSelectedNodes(draftState, [])
        break

      case ActionType.TOGGLE_LOCALES_PANEL:
        draftState.isLocalesPanelOpen = appraisalAction.toggle
        break

      case ActionType.ADD_PAYLOAD:
        AddPayloadsAction.apply(draftState, appraisalAction.newPayload)
        break

      case ActionType.REMOVE_PAYLOAD:
        RemovePayloadsAction.apply(draftState, appraisalAction.payloadToRemove)
        break

      case ActionType.ADD_URL:
        AddUrlAction.apply(draftState, appraisalAction.newUrl)
        break

      case ActionType.REMOVE_URL:
        RemoveUrlsAction.apply(draftState, appraisalAction.urlToRemove)
        break

      case ActionType.EDIT_URL:
        EditUrlAction.apply(draftState, appraisalAction)
        break

      case ActionType.ADD_FEEDBACK_MESSAGE:
        AddFeedbackMessageAction.apply(draftState, appraisalAction)
        break

      case ActionType.SET_KNOWLEDGE_BASE_ACTIVE:
        SetKnowledgeBaseActiveAction.apply(draftState, appraisalAction)
        break

      case ActionType.RESTORE_CHANGE_HISTORY:
        draftState.changeHistory = []
        draftState.historyIndex = -1
        break

      case ActionType.ADD_WEBVIEW:
        AddWebviewAction.apply(draftState, appraisalAction)
        break

      case ActionType.REMOVE_WEBVIEW:
        RemoveWebviewAction.apply(draftState, appraisalAction)
        break

      case ActionType.SET_WEBVIEWS:
        SetWebviewsAction.apply(draftState, appraisalAction)
        break

      case ActionType.SELECT_WEBVIEW:
        SelectWebviewAction.apply(draftState, appraisalAction)
        break

      case ActionType.SET_HASH_PUBLISHED:
        draftState.hashPublished = appraisalAction.hashPublished
        break

      case ActionType.SET_HASH:
        draftState.hash = appraisalAction.hash
        break

      case ActionType.SET_BOT_CONFIG:
        draftState.botConfig = appraisalAction.botConfig
        break

      case ActionType.SET_VERSION_HISTORY_DATA:
        draftState.versionHistoryData = appraisalAction.versionHistoryData
        break

      case ActionType.SET_FLOW_BUILDER_USER:
        draftState.flowBuilderUser = appraisalAction.user
        break

      default:
        nodeReducer(draftState, appraisalAction)
    }
  })(state, action)
}
