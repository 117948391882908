import { memo } from 'react'

import {
  HtIcon,
  HtSwitch,
  HtTooltip,
  Size,
  TextBodyBold,
  TextBodySmall,
} from '../../UI/components/base'
import { getIconFromFileType } from '../../UI/components/base/ht-icon/icon-utils'
import { TooltipPlacement } from '../../UI/components/base/ht-tooltip/ht-tooltip-styles'
import {
  Action,
  KNOWLEDGE_FEEDBACK,
  KNOWLEDGE_SOURCES,
} from '../../UI/components/node-editor-panel/constants'
import { StyledIconWithText } from '../../UI/components/node-editor-panel/editor-styles'
import { SortableDropdownWidgetField } from '../../UI/components/node-editor-panel/editor-widgets/sortable-dropdown-widget'
import { UniqueContentField } from '../../UI/components/node-editor-panel/editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../UI/components/node-editor-panel/node-editor-panel'
import { useFlowBuilderSelector } from '../../UI/reducer/hooks'
import { KnowledgeBaseFields } from './model'

export interface KnowledgeBaseEditorProps extends EditorProps {
  nodeContent: KnowledgeBaseFields
}

export const KnowledgeBaseEditor = memo(
  ({ nodeContent, ...props }: KnowledgeBaseEditorProps): JSX.Element => {
    const organizationContents = useFlowBuilderSelector(
      ctx => ctx.state.organizationContents
    )

    const onCheckboxChange = (isSelected: boolean) => {
      props.onChange({
        type: KNOWLEDGE_FEEDBACK.actionType,
        fieldKey: KNOWLEDGE_FEEDBACK.key,
        value: isSelected,
      } as Action)
    }

    return (
      <>
        <TextBodyBold>Knowledge base</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <SortableDropdownWidgetField
          {...props}
          nodeContent={nodeContent}
          contentType={nodeContent.contentType()}
          applyButtonText='Add sources'
          field={KNOWLEDGE_SOURCES}
          values={nodeContent.sources.filter(source => !source.isInvalid)}
          sortableValues={nodeContent.sources}
          availableItems={organizationContents.knowledgeSources}
          isDragDisabled={true}
          searchPlaceholder='Search sources'
          header={item => {
            return (
              <HtTooltip
                tooltip='This source no longer exists'
                isDisabled={!item.isInvalid}
                placement={TooltipPlacement.BOTTOM}
              >
                <StyledIconWithText $hasError={item.isInvalid}>
                  <HtIcon icon={getIconFromFileType(item.fileType)} />
                  <TextBodySmall>{item.name}</TextBodySmall>
                </StyledIconWithText>
              </HtTooltip>
            )
          }}
        />
        <HtSwitch
          size={Size.SMALL}
          isReadOnly={props.readOnly}
          isSelected={nodeContent.feedbackEnabled}
          helperText={KNOWLEDGE_FEEDBACK.helperText}
          onChange={onCheckboxChange}
        >
          {KNOWLEDGE_FEEDBACK.label}
        </HtSwitch>
      </>
    )
  }
)
