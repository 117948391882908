import { useRef } from 'react'
import {
  AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover,
} from 'react-aria'
import { OverlayTriggerState } from 'react-stately'

import { Placement } from '../../variants'
import { ListContainer } from './styles'
import { ListProps } from './types'

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode
  state: OverlayTriggerState
  listProps?: ListProps
}

export const Popover = ({
  children,
  state,
  listProps,
  ...props
}: PopoverProps) => {
  const popoverRef = useRef(null)
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
      placement: listProps?.listPlacement || Placement.BOTTOM_START,
    },
    state
  )

  return (
    <Overlay>
      <div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />
      <ListContainer
        {...popoverProps}
        ref={popoverRef}
        $hasItemsWithBorder={listProps?.hasItemsWithBorder}
        $size={listProps?.itemSize}
        $listWidth={listProps?.listWidth}
        $maxListHeight={listProps?.maxListHeight}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </ListContainer>
    </Overlay>
  )
}
