import { useEffect } from 'react'

import { usePanelVisibility } from '../../../custom-hooks'
import { ARIA_LABEL, ROLES } from '../../../roles'
import { WindowContainer } from '../base-styles'
import { HtBtnType, HtButton } from '../ht-button/ht-button'
import { HtIcon } from '../ht-icon/ht-icons'
import { Icon } from '../ht-icon/icons-list'
import { Size } from '../variants'
import {
  ModalButtonsContainer,
  ModalHeader,
  StyledModal,
} from './ht-modal-styles'

interface ModalButton {
  text: string
  disabled?: boolean
  isHidden?: boolean
  type?: HtBtnType
  onClick: () => void
}

export interface ModalProps {
  title: React.ReactNode
  message?: string
  confirmButton?: ModalButton
  discardButton?: Partial<ModalButton>
  children?: React.ReactNode
  canCloseOnClickOutside?: boolean
  closeButtonEnabled?: boolean
  cancel: () => void
}

export const HtModal = ({
  title,
  message,
  confirmButton,
  discardButton,
  children,
  canCloseOnClickOutside = true,
  cancel,
  closeButtonEnabled = true,
}: ModalProps): JSX.Element => {
  const ref = usePanelVisibility(
    cancel,
    !confirmButton?.disabled ? confirmButton?.onClick : undefined
  )

  useEffect(() => ref.current?.focus(), [])

  return (
    <WindowContainer aria-label={ARIA_LABEL.SCREEN_OVERLAY}>
      <StyledModal
        ref={canCloseOnClickOutside ? ref : null}
        role={ROLES.DIALOG}
        tabIndex={-1}
      >
        <ModalHeader>
          {title}
          {closeButtonEnabled && discardButton?.onClick && (
            <HtButton
              type={HtBtnType.ICON_ONLY}
              size={Size.SMALL}
              onClick={cancel}
            >
              <HtIcon icon={Icon.XMARK} />
            </HtButton>
          )}
        </ModalHeader>
        {message}
        {children}
        <ModalButtonsContainer>
          {!discardButton?.isHidden && (
            <HtButton
              type={HtBtnType.TERTIARY}
              disabled={discardButton?.disabled}
              onClick={() =>
                discardButton ? discardButton.onClick?.() : cancel()
              }
            >
              {discardButton?.text || 'Cancel'}
            </HtButton>
          )}
          {confirmButton && !confirmButton?.isHidden && (
            <HtButton
              type={confirmButton.type}
              disabled={confirmButton.disabled}
              onClick={() => confirmButton.onClick()}
            >
              {confirmButton.text}
            </HtButton>
          )}
        </ModalButtonsContainer>
      </StyledModal>
    </WindowContainer>
  )
}
