import { HtFlow } from '../../domain/models/cms/hubtype/flow'
import { HtFlowBuilderData } from '../../domain/models/cms/hubtype/flow-builder-data'
import {
  FALLBACK_FLOW,
  FALLBACK_NODE_ID,
  MAIN_FLOW,
  START_NODE_ID,
} from '../../UI/constants'
import { EdgeAction } from '../../UI/reducer/actions/edge-actions/edge-action'
import { Flow, NodeTypes } from '../../UI/types'
import { flowIdToWebviewId } from '../../webviews/utils'

export class FlowFactory {
  constructor(
    private data: HtFlowBuilderData,
    private startNode: NodeTypes
  ) {}

  getFlows(): Flow[] {
    const htFlows: HtFlow[] = this.data.flows.length
      ? this.data.flows
      : [MAIN_FLOW, FALLBACK_FLOW]
    const flows = htFlows.map(flow =>
      this.getFlow(flow, this.data.start_node_id)
    )
    const webviews = this.getWebviews()
    return [...flows, ...webviews]
  }

  private getFlow(flow: HtFlow, startNodeId?: string): Flow {
    switch (flow.id) {
      case MAIN_FLOW.id:
        this.addMainStartEdge(startNodeId)
        break
      case FALLBACK_FLOW.id:
        this.addFallbackStartEdge()
        break
      default:
        this.addFlowStartEdge(flow.id, flow.start_node_id)
        break
    }
    return { id: flow.id, name: flow.name }
  }

  private getWebviews(): Flow[] {
    return (
      this.data.webviews?.map(webview => ({
        id: flowIdToWebviewId(webview.id),
        name: webview.name,
      })) || []
    )
  }

  private addMainStartEdge(startNodeId?: string) {
    this.addFlowStartEdge(MAIN_FLOW.id, startNodeId)
  }

  private addFallbackStartEdge() {
    this.addFlowStartEdge(FALLBACK_FLOW.id, FALLBACK_NODE_ID)
  }

  private addFlowStartEdge(flowId: string, startNodeId?: string): void {
    if (!startNodeId) return
    const startEdge = EdgeAction.connectionToEdge({
      source: START_NODE_ID,
      sourceHandle: `start|${flowId}_${START_NODE_ID}`,
      target: startNodeId,
      targetHandle: startNodeId,
    })
    if (startEdge) this.startNode?.data.edges.push(startEdge)
  }
}
