import { LanguageInfo } from '../../../domain/models/locales/language'
import { TrackEventName, useAnalytics } from '../../analytics'
import {
  HtBtnType,
  HtSelectDropdown,
  TextBodySmall,
} from '../../components/base'

interface SelectLanguageDropdownProps {
  languages: LanguageInfo[]
  onSelect: (language: LanguageInfo) => void
  selectedLanguage: LanguageInfo
}

export const SelectLanguageDropdown = ({
  languages,
  onSelect,
  selectedLanguage,
}: SelectLanguageDropdownProps) => {
  const analytics = useAnalytics()
  const formatLanguageInfo = (language: LanguageInfo) =>
    `${language.name} (${language.id})`

  const onLanguageChange = (language: LanguageInfo | undefined) => {
    if (language) {
      onSelect(language)
      analytics.trackEvent(TrackEventName.PREVIEW_WINDOW_LANGUAGE, {
        language: `${selectedLanguage.id} - ${selectedLanguage.name}`,
      })
    }
  }
  return (
    <HtSelectDropdown
      btnProps={{ type: HtBtnType.TERTIARY, width: '100%' }}
      label={formatLanguageInfo(selectedLanguage)}
      hideLabel={true}
      data={languages}
      onChange={language => onLanguageChange(language)}
    >
      {language => (
        <TextBodySmall>{formatLanguageInfo(language)}</TextBodySmall>
      )}
    </HtSelectDropdown>
  )
}
