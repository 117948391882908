import { Key, useRef } from 'react'
import { AriaMenuTriggerProps, useMenuTrigger } from 'react-aria'
import { ListProps, useMenuTriggerState } from 'react-stately'

import { DropdownButton } from '../../common/dropdown/dropdown-button'
import { DropdownContainer } from '../../common/dropdown/styles'
import { DropdownData, DropdownProps } from '../../common/dropdown/types'
import { TextSmallBold } from '../../typography'
import { CheckboxList } from './checkbox-list'

export interface MultiSelectMenuProps<T extends DropdownData>
  extends ListProps<T>,
    AriaMenuTriggerProps,
    DropdownProps {
  applyButtonText: string
  placeholder?: string
  onApply: (keys: Key[]) => void
}

export const MultiSelectMenu = <T extends DropdownData>(
  props: MultiSelectMenuProps<T>
): JSX.Element => {
  const state = useMenuTriggerState(props)
  const ref = useRef<HTMLButtonElement>(null)
  const { menuTriggerProps } = useMenuTrigger(props, state, ref)
  return (
    <DropdownContainer width={props.width}>
      {!props.hideLabel && <TextSmallBold>{props.label}</TextSmallBold>}
      <DropdownButton
        {...menuTriggerProps}
        buttonRef={ref}
        btnProps={props.btnProps}
        readOnly={props.readOnly}
        isOpen={state.isOpen}
      >
        {props.placeholder || props.label}
      </DropdownButton>
      {state.isOpen && (
        <CheckboxList {...props} overlayState={state} triggerRef={ref} />
      )}
    </DropdownContainer>
  )
}
