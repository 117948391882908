import {
  FALLBACK_FLOW,
  KNOWLEDGE_BASE_FLOW,
  MAIN_FLOW,
} from '../../../constants'
import { Flow, State } from '../../../types'

export abstract class FlowAction {
  static addFlow = (state: State, flow: Flow): void => {
    this.setFlows(state, [...state.flows, flow])
  }

  static setFlows = (state: State, flows: Flow[]): void => {
    state.currentNode = undefined
    if (!this.currentFlowExists(state, flows)) {
      state.currentFlowId = MAIN_FLOW.id
    }
    if (!flows.length) {
      state.flows = [MAIN_FLOW, FALLBACK_FLOW]
      return
    }
    const additionalFlows = flows
      .filter(flow => !this.isDefaultFlow(flow))
      .sort((a, b) => a.name.localeCompare(b.name))

    state.flows = state.isKnowledgeBaseActive
      ? [MAIN_FLOW, ...additionalFlows, KNOWLEDGE_BASE_FLOW, FALLBACK_FLOW]
      : [MAIN_FLOW, ...additionalFlows, FALLBACK_FLOW]
    state.isFlowSaved = false
  }

  static removeFlow = (state: State, flowToRemove: Flow): void => {
    state.flows = state.flows.filter(flow => flow.id !== flowToRemove.id)
    state.isFlowSaved = false
    if (state.currentFlowId === flowToRemove.id) {
      this.selectFlow(state, MAIN_FLOW.id)
    }
  }

  static selectFlow = (state: State, flowId: string): void => {
    state.currentFlowId = flowId
  }

  static currentFlowExists = (state: State, flows: Flow[]): boolean => {
    return flows.some(flow => flow.id === state.currentFlowId)
  }

  static isDefaultFlow = (flow: Flow): boolean => {
    return [MAIN_FLOW.id, KNOWLEDGE_BASE_FLOW.id, FALLBACK_FLOW.id].includes(
      flow.id
    )
  }
}
