import { ContentType } from '../../domain/models/content-fields'
import { HtIcon, Icon, Size } from '../../UI/components/base'
import { BuildComponentTooltip } from '../../UI/components/build-panel/build-component-tooltip'
import { Node } from '../../UI/components/build-panel/build-panel-styles'
import { onDragStart } from '../../UI/node-utils'

export const WhatsappCTAUrlButtonBuildNode = () => {
  return (
    <Node
      onDragStart={event =>
        onDragStart(event, ContentType.WHATSAPP_CTA_URL_BUTTON)
      }
      draggable
    >
      <BuildComponentTooltip
        title='URL Button'
        tooltipText='Add a text message along with a button that directs to a website or webview'
      />
      <div>
        <HtIcon icon={Icon.WHATSAPP} size={Size.LARGE} />
        <HtIcon icon={Icon.LINK} size={Size.MEDIUM} />
      </div>
    </Node>
  )
}
