import { memo } from 'react'

import { IntentFields } from '../../../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { HtBtnType, HtButton, HtInfoPanel, HtSlider, Size } from '../../../base'
import { CONFIDENCE, INTENTS } from '../../constants'
import { EditorTitle, NoIntentsMessage } from '../../editor-styles'
import { EditorProps } from '../../node-editor-panel'
import { IntentsDropdownField } from './intents-dropdown-widget'

interface IntentEditorProps extends EditorProps {
  nodeContent: IntentFields
}

export const IntentEditor = memo(
  ({ nodeContent, ...props }: IntentEditorProps): JSX.Element => {
    const analytics = useAnalytics()
    const currentAiModel = useFlowBuilderSelector(
      ctx => ctx.state.currentAiModel
    )

    const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

    const onConfidenceChange = (confidence: number) => {
      if (confidence === nodeContent.confidence) return
      analytics.trackEvent(TrackEventName.CHANGE_INTENT_CONFIDENCE, {
        confidence,
      })
      //@ts-ignore
      props.onChange({
        type: CONFIDENCE.actionType,
        fieldKey: CONFIDENCE.key!,
        //@ts-ignore
        value: confidence,
      })
    }

    return (
      <>
        <EditorTitle>Intents</EditorTitle>
        {currentAiModel ? (
          <>
            <IntentsDropdownField
              {...props}
              field={INTENTS}
              values={nodeContent.intents}
              intents={currentAiModel.intents || []}
            />
            <HtSlider
              label={CONFIDENCE.label!}
              helperText={CONFIDENCE.helperText}
              defaultValue={nodeContent.confidence}
              isDisabled={props.readOnly}
              onUpdate={onConfidenceChange}
            />
            <HtInfoPanel
              title='About intents'
              text={
                'The conversational app will always be detecting at least one of the AI model’s intents but it may not reach the minimum confidence to trigger the intent flow.\n\nIf a minimum intent confidence has not been reached, fallback message will be sent to the user.'
              }
            />
          </>
        ) : (
          <NoIntentsMessage>
            No Intent models has been selected for this conversational app yet.
            Please select one to then choose any intents from it.
            <HtButton
              type={HtBtnType.TERTIARY}
              size={Size.SMALL}
              disabled={props.readOnly}
              onClick={evt => {
                evt.stopPropagation()
                setPopupContent({ type: PopupType.INTENT_MODELS })
              }}
            >
              Select AI model
            </HtButton>
          </NoIntentsMessage>
        )}
      </>
    )
  }
)
