import { useEffect, useState } from 'react'

import { PreviewUrlParams, WebchatDimensions } from '../../types'
import { getParamsFromUrl } from '../utils'

const appendScript = (src: string, onLoad: () => any) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = src
  script.onload = onLoad
  document.body.appendChild(script)
}

export function useBotonicRenderer() {
  const [botonicScriptLoaded, setBotonicScriptLoaded] = useState(false)
  const [botonicRendered, setBotonicRendered] = useState(false)
  const [webchatDimensions, setWebchatDimensions] = useState<WebchatDimensions>(
    {
      ready: false,
      width: 0,
      height: 0,
    }
  )

  const renderBotonic = (appId: string) => {
    // @ts-ignore
    window.Botonic &&
      // @ts-ignore
      window.Botonic.render(document.getElementById('webchat-container'), {
        appId,
        hostId: 'webchat-container',
        theme: {
          mobileStyle: {
            height: '90%',
          },
        },
      }).then(() => {
        setBotonicRendered(true)
      })
  }

  useEffect(() => {
    const [botId, bundleLocation] = getParamsFromUrl([
      PreviewUrlParams.BOT_ID,
      PreviewUrlParams.BUNDLE_LOCATION,
    ])
    if (botId && bundleLocation) {
      appendScript(`https://${bundleLocation}/webchat.botonic.js`, () =>
        setBotonicScriptLoaded(true)
      )
    }
  }, [])

  useEffect(() => {
    if (botonicScriptLoaded) {
      const [appId] = getParamsFromUrl([PreviewUrlParams.WEBCHAT_APP_ID])
      if (appId) renderBotonic(appId)
    }
  }, [botonicScriptLoaded])

  useEffect(() => {
    if (botonicRendered) {
      const webchat = document.getElementById('webchat-container')
        ?.firstChild as HTMLElement
      if (webchat) {
        setWebchatDimensions({
          ready: true,
          width: webchat?.clientWidth,
          height: webchat?.clientHeight,
        })
      }
    }
  }, [botonicRendered])

  return {
    webchatDimensions,
    botonicScriptLoaded,
    setBotonicScriptLoaded,
    botonicRendered,
    setBotonicRendered,
  }
}
