import { useRef } from 'react'
import { mergeProps, useOverlayPosition, useTooltipTrigger } from 'react-aria'
import { TooltipTriggerProps, useTooltipTriggerState } from 'react-stately'

import { StyledTooltip, TooltipPlacement } from './ht-tooltip-styles'

interface HtTooltipProps extends TooltipTriggerProps {
  children: React.ReactNode
  tooltip?: string
  placement?: TooltipPlacement
}

export const HtTooltip = (props: HtTooltipProps): JSX.Element => {
  const state = useTooltipTriggerState({ delay: 100, ...props })
  const targetRef = useRef(null)
  const overlayRef = useRef(null)
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    props,
    state,
    targetRef
  )

  const { placement } = useOverlayPosition({
    placement: props.placement || TooltipPlacement.RIGHT,
    targetRef,
    isOpen: state.isOpen,
    overlayRef,
  })

  return (
    <span style={{ position: 'relative' }}>
      <div ref={targetRef} {...triggerProps}>
        {props.children}
      </div>
      {state.isOpen && (
        <StyledTooltip
          ref={overlayRef}
          {...mergeProps(props, tooltipProps)}
          $placement={placement}
        >
          {props.tooltip}
        </StyledTooltip>
      )}
    </span>
  )
}
