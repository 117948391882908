import { State, Webview } from '../../../types'

export abstract class WebviewAction {
  static addWebview = (state: State, webview: Webview): void => {
    state.webviews.push(webview)
    this.selectWebview(state, webview.id)
  }

  static removeWebview = (state: State, webviewToRemove: Webview): void => {
    state.webviews = state.webviews.filter(
      webview => webview.id !== webviewToRemove.id
    )
    if (state.currentWebviewId === webviewToRemove.id) {
      this.selectWebview(state, undefined)
    }
  }

  static selectWebview = (state: State, webviewId?: string): void => {
    state.currentWebviewId = webviewId
  }
}
