import { Locale } from '../../domain/models/locales/locale'
import { KnowledgeBaseFields } from '../../nodes/knowledge-base'
import { HubtypeService } from '../../repository/hubtype/hubtype-service'
import { useRepository } from '../../repository/repository-utils'
import { useAnalytics } from '../analytics'
import { useIntentModels } from '../components/header/ai-settings/intent-models/use-intent-models'
import { DRAFT_VERSION, LATEST_VERSION } from '../constants'
import { useFlowBuilderSelector } from '../reducer/hooks'
import { setFBAppStoragePreviewState } from '../test-webchat-app/utils'
import { OrganizationContents } from '../types'
import { useFlowBuilderFeedback } from '../use-flow-builder-feedback'
import { stringArrayToLocaleArray } from '../utils/locales'

export const useLoadFlow = () => {
  const analytics = useAnalytics()
  const { reportVersionNotLoaded } = useFlowBuilderFeedback()
  const repository = useRepository()
  const { loadAiModel, getFlowAiModel } = useIntentModels()
  const {
    state,
    selectLocale,
    setBotConfig,
    setBotVariables,
    setFlows,
    setHash,
    setHashPublished,
    setKnowledgeBaseActive,
    setLoadingMessage,
    setLocales,
    setOrganizationContents,
    toggleFlowSaved,
    updateAllContents,
  } = useFlowBuilderSelector(ctx => ctx)

  const loadInitialFlow = async (): Promise<void> => {
    if (!state.flowBuilderUser) return
    analytics.identify(state.flowBuilderUser) // TODO: Investigate why app is not working with await here
    const orgContents = await HubtypeService.getOrganizationContents(
      state.authToken,
      state.flowBuilderUser.botId
    )
    setOrganizationContents(orgContents)

    await Promise.all([
      loadFlow(undefined, DRAFT_VERSION, orgContents),
      loadPublishedHash(orgContents),
    ])

    const botConfig = await HubtypeService.getBotConfig(
      state.authToken,
      state.flowBuilderUser.botId
    )
    if (botConfig) {
      setBotConfig(botConfig)
    }
    toggleFlowSaved(true)
  }

  const loadFlow = async (
    locale: Locale | undefined,
    version: string,
    organizationContents: OrganizationContents
  ): Promise<void> => {
    const newFlowContent = await repository.cmsReader.readFlowContent(
      state.authToken,
      locale,
      organizationContents,
      version
    )
    if (!newFlowContent) {
      reportVersionNotLoaded()
      setLoadingMessage(undefined)
      return
    }
    const aiModel = getFlowAiModel(newFlowContent, organizationContents)
    await loadAiModel(aiModel)
    if (newFlowContent.locales.length) {
      const locales = stringArrayToLocaleArray(newFlowContent.locales)
      setLocales(locales)
      if (!locale) selectLocale(locales[0], false)
      if (version === DRAFT_VERSION) {
        const botId = organizationContents.currentConversationalApp.id
        setFBAppStoragePreviewState(botId, {
          hasFlowErrors: false,
          current: locales[0],
          locales,
        })
      }
    }
    setKnowledgeBaseActive(newFlowContent.isKnowledgeBaseActive, false)
    setFlows(newFlowContent.flows)
    setBotVariables(newFlowContent.botVariables)
    setLoadingMessage(undefined)
    setHash(newFlowContent.hash)
    if (version === DRAFT_VERSION) {
      KnowledgeBaseFields.trackInvalidSources(newFlowContent.nodes, analytics)
    }
    updateAllContents(newFlowContent.nodes, newFlowContent.nonMessageContents)
  }

  const loadPublishedHash = async (
    orgContents: OrganizationContents
  ): Promise<void> => {
    const publishedFlow = await repository.cmsReader.readFlowContent(
      state.authToken,
      undefined,
      orgContents,
      LATEST_VERSION
    )

    setHashPublished(publishedFlow?.hash || '')
    return
  }

  return { loadInitialFlow, loadFlow }
}
