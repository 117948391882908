import { memo } from 'react'
import { useReactFlow } from 'reactflow'

import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { HtIcon, Icon } from '../base'
import { ControlsContainer, StyledControlButton } from './flow-controls-style'

export const ViewControls = memo(() => {
  const { zoomIn, zoomOut, fitView } = useReactFlow()
  const analytics = useAnalytics()
  const currentNode = useFlowBuilderSelector(ctx => ctx.state.currentNode)

  const onZoomIn = () => {
    analytics.trackEvent(TrackEventName.CLICK_ZOOM_BUTTONS, {
      action: 'in',
    })
    zoomIn()
  }

  const onZoomOut = () => {
    analytics.trackEvent(TrackEventName.CLICK_ZOOM_BUTTONS, {
      action: 'out',
    })
    zoomOut()
  }

  const onFitView = () => {
    analytics.trackEvent(TrackEventName.CLICK_VIEW_EVERYTHING_CTA)
    fitView({ padding: 0.2 })
  }

  return (
    <ControlsContainer $isEditorOpen={!!currentNode}>
      <StyledControlButton onClick={onZoomIn}>
        <HtIcon icon={Icon.PLUS} />
      </StyledControlButton>
      <StyledControlButton onClick={onZoomOut}>
        <HtIcon icon={Icon.MINUS} />
      </StyledControlButton>
      <StyledControlButton onClick={onFitView}>
        <HtIcon icon={Icon.EXPAND} />
      </StyledControlButton>
    </ControlsContainer>
  )
})
