import { useState } from 'react'

import { TrackEventName, useAnalytics } from '../../../../analytics'
import {
  IS_SAVING_OTHER_USER_SESSION_TIMEOUT,
  SESSION_RESOLUTION_TIME,
} from '../../../../constants'
import { MembersInfo } from '../../../../custom-hooks/use-ably'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { COLORS, TextBodySmall } from '../../../base'
import { Popup } from '../../popup'
import { ActiveUserInfo } from './active-user-info'
import {
  StyledEndSessionContainer,
  StyledSessionEndedContainer,
} from './session-styles'

export interface SessionConflictPopupProps {
  onConfirm: () => void
  onDiscard: () => void
  membersInfo?: MembersInfo
  closeData: string | null
}

export const SessionConflictPopup = ({
  membersInfo,
  onConfirm,
  onDiscard,
  closeData,
}: SessionConflictPopupProps): JSX.Element => {
  const analytics = useAnalytics()
  const { updateAllNodes, setPopupContent } = useFlowBuilderSelector(ctx => ctx)
  const [hasEndedSession, setHasEndedSession] = useState(false)
  const [isSavingUserFlow, setIsSavingUserFlow] = useState(false)

  const isOtherUserSession = () => {
    if (!membersInfo) return false
    if (membersInfo?.activeUsers.length === 0) {
      return false
    }
    return membersInfo?.activeUsers.some(u => u.clientId !== membersInfo.me)
  }

  const logUserOut = (): void => {
    analytics.trackEvent(TrackEventName.USER_ENDS_SESSION, {
      action: 'end_session',
      other_user_session: isOtherUserSession(),
    })
    setHasEndedSession(true)
    onConfirm()
    setIsSavingUserFlow(true)

    // Remove all nodes from the flow, because if you have a previous flow loaded don't want to keep it
    updateAllNodes([])

    setTimeout(() => {
      setIsSavingUserFlow(false)
    }, SESSION_RESOLUTION_TIME - IS_SAVING_OTHER_USER_SESSION_TIMEOUT)
  }

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.USER_ENDS_SESSION, {
      action: 'cancel',
      other_user_session: isOtherUserSession(),
    })
    setPopupContent()
    onDiscard()
    postCloseFromFlowBuilderMessage(closeData)
  }

  const SessionEndedText = () => (
    <StyledSessionEndedContainer>
      {!isSavingUserFlow ? (
        <TextBodySmall>Session ended</TextBodySmall>
      ) : (
        <TextBodySmall>Saving changes...</TextBodySmall>
      )}
    </StyledSessionEndedContainer>
  )

  return (
    <Popup
      title={PopupType.SESSION_CONFLICT}
      confirmButton={{
        text: 'End their session',
        onClick: logUserOut,
        isHidden: hasEndedSession,
      }}
      discardButton={{
        text: 'Cancel',
        onClick: discard,
        isHidden: hasEndedSession,
      }}
      message={
        'Another user is currently working on this flow. To start editing, end their session and their changes will be saved.'
      }
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    >
      <StyledEndSessionContainer>
        <ActiveUserInfo
          membersInfo={membersInfo}
          color={!hasEndedSession ? COLORS.N900 : COLORS.N500}
        />
        {hasEndedSession && <SessionEndedText />}
      </StyledEndSessionContainer>
    </Popup>
  )
}
