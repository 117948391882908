import { CountryInfo } from '../content-fields'
import { LocaleCode } from './code'
import { Country } from './country'
import { CountryCode } from './country-code'
import { Language, LanguageInfo } from './language'
import { countryCodesToCountryInfo } from './locale-utils'

export class Locale {
  public id: LocaleCode
  public genericCode: LocaleCode

  constructor(
    public code: LocaleCode,
    public language: Language,
    public country?: Country
  ) {
    this.id = code
    this.genericCode = code.split('-')[0] as LocaleCode
  }

  getLocaleString(): string {
    return `${this.language} (${this.code})`
  }
  isGenericLocale(): boolean {
    return this.code === this.genericCode
  }
  getLanguageInfo(): LanguageInfo {
    return { id: this.genericCode, name: this.language }
  }

  getCountryInfo(): CountryInfo | undefined {
    const splittedCode = this.code.split('-')
    if (splittedCode.length === 1) return undefined
    return countryCodesToCountryInfo([
      splittedCode[1],
    ] as unknown as typeof CountryCode)[0]
  }
}

export class NewLocale {
  constructor(
    public locale: Locale,
    public copyFrom?: LocaleCode
  ) {}
}
