import {
  OrganizationAiIntent,
  OrganizationAiModel,
} from '../../../../../domain/models/organization-models'
import { HubtypeService } from '../../../../../repository/hubtype/hubtype-service'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { FlowContent, OrganizationContents } from '../../../../types'

export const useIntentModels = () => {
  const { state, selectAiModel } = useFlowBuilderSelector(ctx => ctx)

  const loadAiModel = async (aiModel?: OrganizationAiModel): Promise<void> => {
    if (!aiModel) selectAiModel(undefined)
    else {
      const orgAiIntents = await getAiModelIntents(aiModel, state.authToken)
      selectAiModel({ ...aiModel, intents: orgAiIntents })
    }
  }

  const getFlowAiModel = (
    flowContent: FlowContent,
    organizationContents: OrganizationContents
  ): OrganizationAiModel | undefined => {
    return organizationContents.aiModels.find(
      aiModel => aiModel.id === flowContent.aiModelId
    )
  }

  const getAiModelIntents = async (
    aiModel: OrganizationAiModel,
    authToken: string
  ): Promise<OrganizationAiIntent[]> => {
    const orgAiIntents = await HubtypeService.getOrganizationAiIntents(
      authToken,
      aiModel.id
    )
    return orgAiIntents || []
  }

  return { loadAiModel, getFlowAiModel }
}
