import { AriaButtonProps, useButton } from 'react-aria'

import { StyledTooltip } from '../../../material-ui-components-styles'
import { HtBtnSize, HtBtnType } from '../../ht-button/ht-button'
import { HtIcon } from '../../ht-icon/ht-icons'
import { Icon } from '../../ht-icon/icons-list'
import { Size } from '../../variants'
import { StyledDropdownButton } from './styles'

interface ButtonProps extends AriaButtonProps {
  buttonRef: React.RefObject<HTMLButtonElement>
  btnProps?: {
    type?: HtBtnType
    size?: HtBtnSize
    width?: string
    tooltip?: string
  }
  readOnly?: boolean
  isInvalid?: boolean
  isOpen: boolean
  isEmpty?: boolean
  buttonSymbol?: React.ReactNode
}

export const DropdownButton = (props: ButtonProps): JSX.Element => {
  const ref = props.buttonRef
  const { buttonProps } = useButton(
    { ...props, isDisabled: props.readOnly },
    ref
  )

  return (
    <StyledDropdownButton
      {...buttonProps}
      ref={ref}
      $type={props.btnProps?.type || HtBtnType.TERTIARY}
      $size={props.btnProps?.size || Size.MEDIUM}
      $width={props.btnProps?.width || '100%'}
      $isEmpty={props.isEmpty}
      $isReadOnly={props.readOnly}
      $isInvalid={props.isInvalid}
      disabled={props.isDisabled}
      aria-label={props.children?.toString()}
    >
      <div>
        {props.buttonSymbol}
        <StyledTooltip
          title={props.btnProps?.tooltip}
          disableInteractive={!props.btnProps?.tooltip}
          enterNextDelay={500}
          placement='bottom-start'
        >
          <span>{props.children}</span>
        </StyledTooltip>
        <HtIcon
          icon={props.isOpen ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
          size={Size.SMALL}
        />
      </div>
    </StyledDropdownButton>
  )
}
