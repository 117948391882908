import styled, { css } from 'styled-components'

import { COLORS } from '../colors'
import { StyledIcon } from '../ht-icon/ht-icon-styles'
import {
  bold,
  ellipsis,
  TextBody,
  textBodySmall,
  textSmall,
} from '../typography'
import { Size } from '../variants'
import { HtChipSize, HtChipType } from './ht-chip'

export const StyledChip = styled.button<{
  $type: HtChipType
  $size: HtChipSize
  $hasEllipsis?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  gap: 5px;
  border: none;
  word-break: break-word;
  max-width: fit-content;

  ${props => props.$size === Size.MEDIUM && sizeMedium};
  ${props => props.$size === Size.LARGE && sizeLarge};

  ${props => props.$type === 'default' && chipDefault};
  ${props => props.$type === 'primary' && chipPrimary};
  ${props => props.$type === 'aqua' && chipAqua};
  ${props => props.$type === 'green' && chipGreen};
  ${props => props.$type === 'yellow' && chipYellow};
  ${props => props.$type === 'orange' && chipOrange};
  ${props => props.$type === 'red' && chipRed};
  ${props => props.$type === 'pink' && chipPink};
  ${props => props.$type === 'darkLilara' && darkLilara};

  ${StyledIcon} {
    color: ${COLORS.WHITE};
  }

  ${TextBody} {
    ${bold}
    ${props => props.$hasEllipsis && ellipsis}
  }
`

// css chip type
const chipDefault = css`
  background: ${COLORS.N500};
  color: ${COLORS.WHITE};
`

const chipPrimary = css`
  background: ${COLORS.L500};
  color: ${COLORS.L50};
`

const chipAqua = css`
  background: ${COLORS.A500};
  color: ${COLORS.A900};
`

const chipGreen = css`
  background: ${COLORS.G500};
  color: ${COLORS.G900};
`

const chipYellow = css`
  background: ${COLORS.Y500};
  color: ${COLORS.Y900};
`

const chipOrange = css`
  background: ${COLORS.O500};
  color: ${COLORS.O900};
`

const chipRed = css`
  background: ${COLORS.R600};
  color: ${COLORS.R50};
`

const chipPink = css`
  background: ${COLORS.P300};
  color: ${COLORS.P900};
`

const darkLilara = css`
  background: ${COLORS.L700};
  color: ${COLORS.WHITE};
`

// css chip size
const sizeMedium = css`
  min-height: 22px;
  padding: 2px 8px;

  ${TextBody} {
    ${textSmall};
  }
`

const sizeLarge = css`
  min-height: 26px;
  padding: 2px 8px 3px;

  ${TextBody} {
    ${textBodySmall};
  }
`
