import styled from 'styled-components'

import { COLORS } from './colors'
import { TextSmallExtra } from './typography'

export const WindowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(65, 63, 72, 0.7);
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const HelperText = styled(TextSmallExtra)`
  color: ${COLORS.N500};
  user-select: none;
  -webkit-user-select: none;
`
