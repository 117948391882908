import { useState } from 'react'

import { OrganizationAiModel } from '../../../../../domain/models/organization-models'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { hasIntentModelsFeature } from '../../../../utils/feature-flags'
import { HtSelectDropdown } from '../../../base'
import { Popup } from '../../../popup/popup'
import { IntentModelsAddonsPopup } from './intent-models-addons-modal'
import { useIntentModels } from './use-intent-models'

export const IntentModelsPopup = (): JSX.Element => {
  const analytics = useAnalytics()
  const { loadAiModel } = useIntentModels()
  const { featureFlags, aiModels } = useFlowBuilderSelector(
    ctx => ctx.state.organizationContents
  )
  const { currentAiModel, isReadOnly } = useFlowBuilderSelector(
    ctx => ctx.state
  )
  const [selectedModel, setSelectedModel] = useState<
    OrganizationAiModel | undefined
  >(currentAiModel)
  const [isChangeAiModelButtonDisabled, setChangeAiModelButtonDisabled] =
    useState(true)

  const changeAiModel = async (): Promise<void> => {
    if (selectedModel) {
      await loadAiModel(selectedModel)
      await analytics.trackEvent(TrackEventName.SET_AI_MODEL, {
        model: selectedModel.id,
      })
    }
  }

  if (!hasIntentModelsFeature(featureFlags) || !aiModels.length) {
    return <IntentModelsAddonsPopup />
  }

  return (
    <Popup
      title={PopupType.INTENT_MODELS}
      message='The Intent models contains all the intents you can use on this conversational app. It can be changed at any time but keep in mind this may affect every intent already set in the conversation. You will receive an error notification for each intent that needs to be configured again.'
      confirmButton={{
        text: 'Change Intent models',
        onClick: changeAiModel,
        disabled: isChangeAiModelButtonDisabled,
      }}
    >
      <HtSelectDropdown
        label='Select Intent models'
        data={aiModels}
        selectedKey={selectedModel?.id}
        readOnly={isReadOnly}
        onChange={value => {
          setChangeAiModelButtonDisabled(false)
          setSelectedModel(value)
        }}
        hideLabel={true}
        listProps={{ listWidth: '468px', maxListHeight: '250px' }}
      />
    </Popup>
  )
}
