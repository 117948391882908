import { memo, useState } from 'react'

import { PayloadFields } from '../../../../../domain/models/content-fields'
import { normalizeValue } from '../../../../../utils/string-utils'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { HtMenuDropdown, HtSelectDropdown, TextSmallExtra } from '../../../base'
import { HtEditableDropdownItem } from '../../../base/common/dropdown/editable-dropdown-item'
import { StyledSelectHeader } from '../../../base/common/dropdown/styles'
import { HtInputWithAction } from '../../../base/ht-input/ht-input-with-action'
import { InputAction } from '../../constants'

interface PayloadDropdownProps {
  readOnly?: boolean
  field: InputAction
  payloadId?: string
  onChange: (action: any) => void
}

export const PayloadDropdown = memo(
  (props: PayloadDropdownProps): JSX.Element => {
    const analytics = useAnalytics()
    const { addPayload, setPopupContent } = useFlowBuilderSelector(ctx => ctx)
    const [filterValue, setFilterValue] = useState('')
    const payloads = useFlowBuilderSelector(
      ctx => ctx.state.nonMessageContents.payloads
    )
    const [isOpen, setIsOpen] = useState(false)
    const [newPayloadError, setNewPayloadError] = useState<string | undefined>()

    const isNewPayloadValid = (newPayload: string): boolean => {
      if (
        payloads.some(
          payload => normalizeValue(payload.name) === normalizeValue(newPayload)
        )
      ) {
        setNewPayloadError('Payload already added')
        return false
      } else if (newPayload.startsWith('ba|')) {
        setNewPayloadError('Payloads can’t start by ba|')
        return false
      } else {
        setNewPayloadError(undefined)
        return true
      }
    }

    const onAddPayload = (newPayload: string) => {
      analytics.trackEvent(TrackEventName.BOTACTION_NEW_PAYLOAD, {
        number_of_payloads: payloads.length + 1,
        payload_name: newPayload,
      })
      const newPayloadFields = new PayloadFields(newPayload.trim())
      addPayload(newPayloadFields)
      onSelectPayload(newPayloadFields)
      setIsOpen(false)
    }

    const onRemovePayload = (payloadToRemove: PayloadFields) => {
      analytics.trackEvent(TrackEventName.BOTACTION_PAYLOAD_DELETE, {
        payload_name: payloadToRemove.name,
      })
      setPopupContent({ type: PopupType.DELETE_PAYLOAD, payloadToRemove })
      setIsOpen(false)
    }

    const onSelectPayload = (payload?: PayloadFields) => {
      if (!payload) return
      analytics.trackEvent(TrackEventName.BOTACTION_PAYLOAD_SELECT, {
        payload_name: payload.name,
      })
      const value = payload?.id
      props.onChange({
        type: props.field.actionType,
        fieldKey: props.field.key,
        value,
      })
    }

    const onOpenChange = () => {
      setIsOpen(!isOpen)
      setFilterValue('')
    }

    // react aria does not allow opening the select if there are no items, so if there are no items we display a menu
    const DropdownComponent =
      payloads.length === 0 ? HtMenuDropdown : HtSelectDropdown

    return (
      <DropdownComponent
        isOpen={isOpen}
        label={props.field.label!}
        placeholder={props.field.placeholder}
        helperText={props.field.helperText}
        data={payloads}
        selectedKey={props.payloadId}
        onChange={onSelectPayload}
        onOpenChange={onOpenChange}
        width={'100%'}
        filterValue={filterValue}
        readOnly={props.readOnly}
        header={
          <StyledSelectHeader>
            <HtInputWithAction
              inputProps={{ label: 'Add payload' }}
              buttonText='Add'
              errorMessage={newPayloadError}
              applyAction={onAddPayload}
              isValueValid={isNewPayloadValid}
              onValueChange={setFilterValue}
            />
            {payloads.length === 0 && (
              <TextSmallExtra>
                There are no payloads added yet. You need to define them first
                in the bot’s code and then add them here.
              </TextSmallExtra>
            )}
          </StyledSelectHeader>
        }
      >
        {payload => (
          <HtEditableDropdownItem
            key={payload.id}
            item={payload}
            onRemove={() => onRemovePayload(payload)}
          />
        )}
      </DropdownComponent>
    )
  }
)
