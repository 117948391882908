import { useEffect, useRef, useState } from 'react'

import { TrackEventName, useAnalytics } from '../../../../analytics'
import { MAX_FLOW_NAME_LENGTH } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { Flow, PopupType } from '../../../../types'
import { HtInput } from '../../../base'
import { Popup } from '../../popup'

interface EditFlowPopupProps {
  flowToEdit: Flow
}

export const EditFlowPopup = ({
  flowToEdit,
}: EditFlowPopupProps): JSX.Element => {
  const analytics = useAnalytics()
  const inputRef = useRef<HTMLInputElement>(null)
  const { state, setPopupContent, setFlows } = useFlowBuilderSelector(
    ctx => ctx
  )
  const [flowName, setFlowName] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => inputRef.current?.focus(), [])

  const renameFlow = async (): Promise<void> => {
    analytics.trackEvent(TrackEventName.FLOW_RENAME, {
      has_renamed: true,
      flow_name: flowName,
      previous_name: flowToEdit.name,
    })
    setPopupContent()
    const newFlows = state.flows.map(flow => {
      if (flow.id !== flowToEdit.id) return flow
      return { ...flow, name: flowName }
    })
    setFlows(newFlows)
  }

  const cancel = () => {
    analytics.trackEvent(TrackEventName.FLOW_RENAME, { has_renamed: false })
    setPopupContent()
  }

  const onNameChanges = (value: string) => {
    setFlowName(value)
    if (value.length > 30) {
      setError('Flow title is too long')
    } else if (state.flows.some(flow => flow.name === value)) {
      setError('Flow title already exists')
    } else {
      setError(undefined)
    }
  }

  return (
    <Popup
      title={PopupType.EDIT_FLOW}
      confirmButton={{
        text: 'Save',
        onClick: renameFlow,
        disabled: !flowName || !!error,
      }}
      cancel={cancel}
    >
      <HtInput
        inputRef={inputRef}
        label='Flow title'
        placeholder={flowToEdit.name}
        onChange={onNameChanges}
        value={flowName}
        counterMaxLength={MAX_FLOW_NAME_LENGTH}
        error={!!error}
        helperText={error}
      />
    </Popup>
  )
}
