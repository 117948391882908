import { HtBotActionNode } from '../../domain/models/cms/hubtype/bot-action'
import {
  HtCarouselElement,
  HtCarouselNode,
} from '../../domain/models/cms/hubtype/carousel'
import { HtButton, HtNodeLink } from '../../domain/models/cms/hubtype/common'
import { HtFallbackNode } from '../../domain/models/cms/hubtype/fallback'
import { HtFunctionNode } from '../../domain/models/cms/hubtype/function'
import { HtGoToFlowNode } from '../../domain/models/cms/hubtype/go-to-flow'
import { HtHandoffNode } from '../../domain/models/cms/hubtype/handoff'
import { HtImageNode } from '../../domain/models/cms/hubtype/image'
import { HtIntentNode } from '../../domain/models/cms/hubtype/intent'
import { HtKeywordNode } from '../../domain/models/cms/hubtype/keyword'
import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  HtNodeComponent,
  HtNodeWithContent,
} from '../../domain/models/cms/hubtype/nodes'
import { HtTextNode } from '../../domain/models/cms/hubtype/text'
import { HtVideoNode } from '../../domain/models/cms/hubtype/video'
import { HtWhatsappButtonListNode } from '../../domain/models/cms/hubtype/whatsapp'
import {
  BotActionFields,
  ButtonFields,
  CarouselFields,
  ChannelFields,
  CONDITIONAL_FUNCTIONS,
  ContentId,
  ContentType,
  CountryConditionFields,
  ElementFields,
  FallbackFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  IntentFields,
  KeywordFields,
  QueueStatusFields,
  TextFields,
  TopContentFields,
  VideoFields,
  WhatsappButtonListFields,
} from '../../domain/models/content-fields'
import { LocaleCode } from '../../domain/models/locales/code'
import {
  CustomConditionFields,
  VariableFormat,
} from '../../nodes/custom-conditional'
import { KnowledgeBaseFields } from '../../nodes/knowledge-base'
import { HtKnowledgeBaseNode } from '../../nodes/knowledge-base/ht-model'
import { SmartIntentFields } from '../../nodes/smart-intent'
import { HtSmartIntentNode } from '../../nodes/smart-intent/ht-model'
import { WhatsappCTAUrlButtonFields } from '../../nodes/whatsapp-cta-url-button'
import { HtWhatsappCTAUrlButtonNode } from '../../nodes/whatsapp-cta-url-button/ht-model'
import { getNewEdge } from '../../UI/components/edges/edge-utils'
import { FALLBACK_MESSAGE_1, FALLBACK_MESSAGE_2 } from '../../UI/constants'
import {
  NodeTypes,
  NonMessageContents,
  OrganizationContents,
} from '../../UI/types'
import { flatten } from '../../utils/array-utils'
import { HtWebviewComponent } from '../../webviews/ht-model'
import { HtWebviewImageNode } from '../../webviews/webview-image'
import { HtWebviewTextNode } from '../../webviews/webview-text'

export class NodeFactory {
  static readonly DEFAULT_POSITION = { x: 20, y: 20 }

  constructor(
    public content: (HtNodeComponent | HtWebviewComponent)[],
    public currentLocale: LocaleCode,
    public locales: LocaleCode[],
    public organizationContents: OrganizationContents,
    public nonMessageContents: NonMessageContents
  ) {}

  getNodes(): NodeTypes[] {
    const nodes: NodeTypes[] = []
    this.content.forEach(component => {
      const fields = this.readComponent(component)
      if (
        component.type !== HtContentType.URL &&
        component.type !== HtContentType.PAYLOAD &&
        fields
      ) {
        fields.setErrors()
        fields.getLocalesWithErrors(this.locales)
        const node = this.createNode(fields, component)
        nodes.push(node)
        this.resolveFollowUp(component, node)
      }
    })
    this.addFallbackNode(nodes)
    return nodes
  }

  private addFallbackNode(nodes: NodeTypes[]): void {
    const foundFallback = nodes.find(node => {
      return node.data.contentType() === ContentType.FALLBACK
    })
    if (!foundFallback) {
      nodes.push(this.createNode(new FallbackFields()))
    }
  }

  private readComponent(
    component: HtNodeComponent | HtWebviewComponent
  ): TopContentFields | undefined {
    switch (component.type) {
      case HtContentType.TEXT:
      case HtContentType.WEBVIEW_TEXT:
        return this.readText(component)
      case HtContentType.IMAGE:
      case HtContentType.WEBVIEW_IMAGE:
        return this.readImage(component)
      case HtContentType.VIDEO:
        return this.readVideo(component)
      case HtContentType.CAROUSEL:
        return this.readCarousel(component)
      case HtContentType.HANDOFF:
        return this.readHandoff(component)
      case HtContentType.KEYWORD:
        return this.readKeyword(component)
      case HtContentType.INTENT:
        return this.readIntent(component)
      case HtContentType.SMART_INTENT:
        return this.readSmartIntent(component)
      case HtContentType.FUNCTION:
        return this.readFunctionNode(component)
      case HtContentType.FALLBACK:
        return this.readFallbackNode(component)
      case HtContentType.GO_TO_FLOW:
        return this.readGoToFlow(component)
      case HtContentType.BOT_ACTION:
        return this.readBotAction(component)
      case HtContentType.WHATSAPP_BUTTON_LIST:
        return this.readWhatsappButtonListNode(component)
      case HtContentType.WHATSAPP_CTA_URL_BUTTON:
        return this.readWhatsappCTAUrlButtonNode(component)
      case HtContentType.KNOWLEDGE_BASE:
        return this.readKnowledgeBase(component)
      default:
        return undefined
    }
  }

  private readText(content: HtTextNode | HtWebviewTextNode): TextFields {
    const textFields = TextFields.fromHubtypeCMS(
      content,
      this.currentLocale,
      this.nonMessageContents
    )
    if (content.type === HtContentType.TEXT) {
      textFields.buttons = content.content.buttons.map(button =>
        this.readButton(button, textFields)
      )
    }
    return textFields
  }

  private readButton(
    content: HtButton,
    parent: TextFields | CarouselFields | WhatsappCTAUrlButtonFields
  ): ButtonFields {
    const buttonFields = ButtonFields.fromHubtypeCMS(
      content,
      this.currentLocale,
      this.nonMessageContents
    )
    this.resolveTarget(content.target, content.id, parent)
    return buttonFields
  }

  private readImage(content: HtImageNode | HtWebviewImageNode): ImageFields {
    const imageFields = ImageFields.fromHubtypeCMS(content, this.currentLocale)
    return imageFields
  }

  private readVideo(content: HtVideoNode): VideoFields {
    const videoFields = VideoFields.fromHubtypeCMS(content, this.currentLocale)
    return videoFields
  }

  private readGoToFlow(content: HtGoToFlowNode): GoToFlowFields {
    const goToFlowFields = GoToFlowFields.fromHubtypeCMS(content)
    return goToFlowFields
  }

  private readBotAction(content: HtBotActionNode): BotActionFields {
    const botActionFields = BotActionFields.fromHubtypeCMS(content)
    return botActionFields
  }

  private readCarousel(content: HtCarouselNode): CarouselFields {
    const carouselFields = CarouselFields.fromHubtypeCMS(content)
    carouselFields.elements = content.content.elements.map(element =>
      this.readElement(element, carouselFields)
    )
    return carouselFields
  }

  private readElement(
    content: HtCarouselElement,
    parent: CarouselFields
  ): ElementFields {
    const elementFields = ElementFields.fromHubtypeCMS(
      content,
      this.currentLocale,
      this.nonMessageContents
    )
    elementFields.buttons = [this.readButton(content.button, parent)]
    return elementFields
  }

  private readHandoff(content: HtHandoffNode): HandoffFields {
    const handoffFields = HandoffFields.fromHubtypeCMS(
      content,
      this.currentLocale,
      this.nonMessageContents,
      this.organizationContents
    )
    this.resolveTarget(content.target, content.id, handoffFields)
    return handoffFields
  }

  private readKeyword(content: HtKeywordNode): KeywordFields {
    const keywordFields = KeywordFields.fromHubtypeCMS(
      content,
      this.currentLocale
    )
    this.resolveTarget(content.target, content.id, keywordFields)
    return keywordFields
  }

  private readIntent(content: HtIntentNode): IntentFields {
    const intentFields = IntentFields.fromHubtypeCMS(
      content,
      this.currentLocale
    )
    this.resolveTarget(content.target, content.id, intentFields)
    return intentFields
  }

  private readSmartIntent(content: HtSmartIntentNode): SmartIntentFields {
    const smartIntentFields = SmartIntentFields.fromHubtypeCMS(content)
    this.resolveTarget(content.target, content.id, smartIntentFields)
    return smartIntentFields
  }

  private readFunctionNode(
    content: HtFunctionNode
  ):
    | QueueStatusFields
    | ChannelFields
    | CountryConditionFields
    | CustomConditionFields {
    switch (content.content.action) {
      case CONDITIONAL_FUNCTIONS.CHECK_QUEUE_STATUS:
        return this.readQueueStatusCondition(content)
      case CONDITIONAL_FUNCTIONS.CHECK_CHANNEL_TYPE:
        return this.readChannelCondition(content)
      case CONDITIONAL_FUNCTIONS.CHECK_COUNTRY:
        return this.readCountryCondition(content)
      case CONDITIONAL_FUNCTIONS.CHECK_BOT_VARIABLE:
        return this.readCustomCondition(content)
      default:
        throw new Error('Unknown function type')
    }
  }

  private readQueueStatusCondition(content: HtFunctionNode): QueueStatusFields {
    const queueStatusFields = QueueStatusFields.fromHubtypeCMS(
      content,
      this.currentLocale,
      this.organizationContents
    )
    this.resolveResultMapping(content, queueStatusFields)
    return queueStatusFields
  }

  private readChannelCondition(content: HtFunctionNode): ChannelFields {
    const channelFields = ChannelFields.fromHubtypeCMS(content)
    this.resolveResultMapping(content, channelFields)
    return channelFields
  }

  private readCountryCondition(
    content: HtFunctionNode
  ): CountryConditionFields {
    const countryFields = CountryConditionFields.fromHubtypeCMS(content)
    this.resolveResultMapping(content, countryFields)
    return countryFields
  }

  private readCustomCondition(content: HtFunctionNode): CustomConditionFields {
    const customConditionFields = CustomConditionFields.fromHubtypeCMS(content)
    customConditionFields.values.forEach((value, i) => {
      this.resolveConditionalTarget(
        content.content.result_mapping.find(
          m => m.result.toString() === value.name
        )?.target,
        content.id,
        value.id,
        customConditionFields
      )
    })
    const defaultTarget = content.content.result_mapping.find(
      m => m.result === 'default'
    )?.target
    if (
      defaultTarget &&
      customConditionFields.variableFormat !== VariableFormat.BOOLEAN
    )
      this.resolveConditionalTarget(
        defaultTarget,
        content.id,
        'default',
        customConditionFields
      )
    return customConditionFields
  }

  private readFallbackNode(component: HtFallbackNode): FallbackFields {
    const fallbackFields = FallbackFields.fromHubtypeCMS(component)
    this.resolveConditionalTarget(
      component.content.first_message,
      component.id,
      FALLBACK_MESSAGE_1,
      fallbackFields
    )
    this.resolveConditionalTarget(
      component.content.second_message,
      component.id,
      FALLBACK_MESSAGE_2,
      fallbackFields
    )

    return fallbackFields
  }

  private readWhatsappButtonListNode(
    content: HtWhatsappButtonListNode
  ): WhatsappButtonListFields {
    const whatsappButtonListFields = WhatsappButtonListFields.fromHubtypeCMS(
      content,
      this.currentLocale
    )
    const rows = flatten(content.content.sections.map(s => s.rows))
    rows.forEach(r => {
      this.resolveTarget(r.target, r.id, whatsappButtonListFields)
    })
    return whatsappButtonListFields
  }

  private readWhatsappCTAUrlButtonNode(
    content: HtWhatsappCTAUrlButtonNode
  ): WhatsappCTAUrlButtonFields {
    const whatsappButtonListFields = WhatsappCTAUrlButtonFields.fromHubtypeCMS(
      content,
      this.currentLocale,
      this.nonMessageContents
    )
    whatsappButtonListFields.button = this.readButton(
      content.content.button,
      whatsappButtonListFields
    )
    return whatsappButtonListFields
  }

  private readKnowledgeBase(content: HtKnowledgeBaseNode): KnowledgeBaseFields {
    const knowledgeBaseFields = KnowledgeBaseFields.fromHubtypeCMS(
      content,
      this.organizationContents
    )
    return knowledgeBaseFields
  }

  private resolveFollowUp = (
    component: HtNodeComponent | HtWebviewComponent,
    node: NodeTypes
  ): void => {
    if (!('follow_up' in component)) return
    if (!component.follow_up) return
    const targetContentId = this.getContentIdForHtNodes(component.follow_up)
    node.data.edges.push(
      getNewEdge(component.id, component.id, targetContentId)
    )
  }

  private resolveTarget = (
    target: HtNodeLink | undefined,
    sourceHandle: string,
    nodeData: TopContentFields
  ): void => {
    if (!target) return
    const targetContentId = this.getContentIdForHtNodes(target)
    const edge = getNewEdge(nodeData.id, sourceHandle, targetContentId)
    nodeData.edges.push(edge)
  }

  private resolveConditionalTarget = (
    target: HtNodeLink | undefined,
    id: string,
    suffix: string,
    nodeData: TopContentFields
  ): void => {
    if (!target) return
    const targetContentId = this.getContentIdForHtNodes(target)
    const edge = getNewEdge(id, `${id}-${suffix}`, targetContentId)
    nodeData.edges.push(edge)
  }

  private resolveResultMapping = (
    content: HtFunctionNode,
    nodeData: TopContentFields
  ) => {
    content.content.result_mapping.forEach(result => {
      this.resolveConditionalTarget(
        result.target,
        content.id,
        result.result.toString(),
        nodeData
      )
    })
  }

  private createNode(
    fields: TopContentFields,
    component?: HtNodeWithContent | HtWebviewComponent
  ): NodeTypes {
    // @ts-ignore
    const newNode: NodeTypes = {
      id: fields.id,
      type: fields.contentType(),
      position: component?.meta || NodeFactory.DEFAULT_POSITION,
      data: fields,
      selected: false,
    }
    if (fields.contentType() === ContentType.FALLBACK) {
      newNode.position = { x: 300, y: 0 }
      newNode.draggable = false
      newNode.deletable = false
    }
    return newNode
  }

  private getContentIdForHtNodes(targetContentId: ContentId): ContentId {
    if (targetContentId.type !== HtContentType.FUNCTION) {
      const foundNode = this.content.find(
        node => node.id === targetContentId.id
      )
      if (!foundNode) return targetContentId
      if (!('content' in foundNode)) return targetContentId
      if (!('action' in foundNode.content)) return targetContentId

      switch (foundNode.content.action) {
        case CONDITIONAL_FUNCTIONS.CHECK_QUEUE_STATUS:
          return { ...targetContentId, type: ContentType.QUEUE_STATUS }
        case CONDITIONAL_FUNCTIONS.CHECK_CHANNEL_TYPE:
          return { ...targetContentId, type: ContentType.CHANNEL }
        case CONDITIONAL_FUNCTIONS.CHECK_COUNTRY:
          return { ...targetContentId, type: ContentType.COUNTRY_CONDITION }
      }
    }
    return targetContentId
  }
}
