import { memo, useEffect, useRef, useState } from 'react'

import { normalizeValue, toSnakeCase } from '../../../../utils/string-utils'
import { TrackEventName, useAnalytics } from '../../../analytics'
import {
  ALPHANUMERIC_WITH_SYMBOLS_REGEX,
  INPUT_DEBOUNCE_DELAY,
} from '../../../constants'
import { useDebounce } from '../../../custom-hooks'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { HtCheckbox, HtInput, HtInputProps, HtModal, Icon } from '../../base'
import { CODE, ErrorText, InputAction, SMART_INTENT_TITLE } from '../constants'
import { TextFieldContainer } from '../editor-styles'

export const HIDE_CONTENT_ID_EDIT_WARNING = 'hide-content-id-edit-warning'

interface UniqueContentFieldProps extends HtInputProps {
  id: string
  field?: InputAction
  isAiGenerated?: boolean
  isOptional?: boolean
  onChange: (action: any) => void
}

export const UniqueContentField = memo(
  ({ id, field = CODE, ...props }: UniqueContentFieldProps): JSX.Element => {
    const analytics = useAnalytics()
    const nodes = useFlowBuilderSelector(ctx => ctx.state.nodes)
    const [value, setValue] = useState(props.value)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [canEdit, setCanEdit] = useState(props.value === '')
    const [hasError, setHasError] = useState(false)
    const [isHideModalChecked, setIsHideModalChecked] = useState(false)
    const [helperText, setHelperText] = useState<string | undefined>(
      field.helperText
    )
    const ref = useRef<HTMLInputElement>(null)
    const debouncedValue = useDebounce<string>(value, INPUT_DEBOUNCE_DELAY)

    useEffect(() => updateContent(), [debouncedValue])
    useEffect(() => updateState(true), [id])
    useEffect(() => updateState(!value), [props.value])

    const isSmartIntentTitle = field.key === SMART_INTENT_TITLE.key

    const updateState = (needsEditableUpdate: boolean) => {
      setValue(props.value)
      updateHelperText(props.value)
      if (needsEditableUpdate) {
        setCanEdit(props.value === '')
      }
    }

    const updateContent = (): void => {
      if (value === props.value) return
      updateHelperText(value)
      props.onChange({
        type: field.actionType,
        fieldKey: field.key,
        value,
      })
    }

    const updateHelperText = (newValue: string): void => {
      if (
        hasInvalidCharactersError(newValue) ||
        hasRepeatedFieldError(newValue)
      ) {
        setCanEdit(true)
        return
      }
      setHasError(false)
      setHelperText(field.helperText)
    }

    const hasInvalidCharactersError = (newValue: string): boolean => {
      if (isSmartIntentTitle) return false
      const isValid = ALPHANUMERIC_WITH_SYMBOLS_REGEX.test(newValue)
      if (isValid) return false
      analytics.trackEvent(TrackEventName.CONTENTID_ERROR, {
        type: 'invalid_characters',
      })
      setHasError(true)
      setHelperText(ErrorText.INVALID_CHARACTERS)
      return true
    }

    const hasRepeatedFieldError = (newValue: string): boolean => {
      if (!newValue || !isFieldRepeated(newValue)) return false

      analytics.trackEvent(
        isSmartIntentTitle
          ? TrackEventName.CONTENTID_ERROR
          : TrackEventName.SMART_INTENT_TITLE_ERROR,
        {
          type: 'already_exists',
        }
      )
      setHasError(true)
      setHelperText(field.errorText)
      return true
    }

    const isFieldRepeated = (newValue: string): boolean => {
      return nodes.some(
        node =>
          node.id !== id &&
          //@ts-ignore
          node.data[field.key] &&
          //@ts-ignore
          normalizeValue(node.data[field.key]) === normalizeValue(newValue)
      )
    }

    const onChange = (newValue: string): void => {
      const hideContentIdWarning = sessionStorage.getItem(
        HIDE_CONTENT_ID_EDIT_WARNING
      )
      if (!canEdit && !hideContentIdWarning) {
        setIsModalOpen(true)
        setIsHideModalChecked(false)
        return
      }
      if (isSmartIntentTitle) {
        newValue = toSnakeCase(newValue.toLowerCase())
      }
      setValue(newValue)
    }

    const onEdit = (): void => {
      if (isHideModalChecked) {
        sessionStorage.setItem(HIDE_CONTENT_ID_EDIT_WARNING, 'true')
      }
      closeModal(true)
      setCanEdit(true)
      ref.current?.focus()
    }

    const closeModal = (hasEdited?: boolean): void => {
      analytics.trackEvent(
        isSmartIntentTitle
          ? TrackEventName.SMART_INTENT_TITLE_EDIT
          : TrackEventName.CONTENTID_EDIT,
        {
          action: hasEdited ? 'edit_anyway' : 'cancel',
          was_ai_generated: props.isAiGenerated || false,
        }
      )
      setIsModalOpen(false)
    }

    return (
      <>
        <TextFieldContainer>
          <HtInput
            {...props}
            inputRef={ref}
            id={field.label}
            label={props.isOptional ? `${field.label} (Optional)` : field.label}
            placeholder={field.placeholder}
            value={value}
            helperText={helperText}
            error={hasError}
            multiline={true}
            onChange={onChange}
            badge={
              props.isAiGenerated
                ? { icon: Icon.WAND_MAGIC_SPARKLES, children: 'AI generated' }
                : undefined
            }
          />
        </TextFieldContainer>
        {isModalOpen && (
          <HtModal
            title={
              isSmartIntentTitle ? 'Edit smart intent title' : 'Edit content ID'
            }
            confirmButton={{
              text: 'Edit anyway',
              onClick: onEdit,
            }}
            cancel={closeModal}
            message={`Editing a ${
              isSmartIntentTitle ? 'smart intent title' : 'content ID'
            } may result in data duplicates or other misinterpretations in logs and reports.`}
          >
            <HtCheckbox
              label='Don’t show this message again.'
              checked={isHideModalChecked}
              onChange={evt => setIsHideModalChecked(evt.target.checked)}
            />
          </HtModal>
        )}
      </>
    )
  }
)
