export enum BotonicPackageNames {
  Core = '@botonic/core',
  React = '@botonic/react',
  PluginFlowBuilder = '@botonic/plugin-flow-builder',
  PluginKnowledgeBases = '@botonic/plugin-knowledge-bases',
  PluginHubtypeAnalytics = '@botonic/plugin-hubtype-analytics',
}
type BotonicPackages = Record<string, { version: string }>

export interface BotConfig {
  build_info: {
    botonic_cli_version: string
    node_version: string
    npm_version: string
  }
  packages: BotonicPackages
}
