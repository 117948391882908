import { useEffect } from 'react'

import { CountryInfo } from '../../../domain/models/content-fields'
import { LanguageInfo } from '../../../domain/models/locales/language'
import { PreviewUrlParams } from '../../types'
import { botonicUpdateUserExtraData } from '../utils/botonic-utils'
import { getParamsFromUrl } from '../utils/url-utils'

interface DynamicWebchatRendererProps {
  rendered: boolean
  selectedLanguage: LanguageInfo
  selectedCountry: CountryInfo
}

export const DynamicWebchatRenderer = ({
  rendered,
  selectedLanguage,
  selectedCountry,
}: DynamicWebchatRendererProps) => {
  useEffect(() => {
    if (rendered) {
      const [open] = getParamsFromUrl([PreviewUrlParams.WEBCHAT_OPEN_AUTO])
      if (open === 'true') {
        // @ts-ignore
        window.Botonic && window.Botonic.open()
      }
      if (selectedLanguage && selectedCountry) {
        const botonicUserExtraData: {
          language: string
          country?: string | undefined
        } = {
          language: selectedLanguage.id,
        }
        if (selectedCountry) {
          botonicUserExtraData.country = selectedCountry.id
        }
        botonicUpdateUserExtraData(botonicUserExtraData)
      }
    }
  }, [rendered])

  return <div id='webchat-container'></div>
}
