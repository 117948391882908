export const getNextVersion = (versions: string[]): string => {
  if (versions.length === 0) return '0.1'
  const latest = versions.reduce((highest, version) => {
    return isVersionHigher(version, highest) ? version : highest
  }, '0.0')
  return increaseVersion(latest)
}

const increaseVersion = (currentVersion: string): string => {
  const [major, minor] = currentVersion.split('.').map(Number)
  if (minor === 9) return `${major + 1}.0`
  return `${major}.${minor + 1}`
}

const isVersionHigher = (current: string, highest: string): boolean => {
  return current.localeCompare(highest, undefined, { numeric: true }) === 1
}
