/* eslint-disable @typescript-eslint/naming-convention */
export enum LocaleCode {
  AF_ZA = 'af-ZA',
  AR_SA = 'ar-SA',
  BG_BG = 'bg-BG',
  BN_IN = 'bn-IN',
  CA = 'ca',
  CA_ES = 'ca-ES',
  CS = 'cs',
  CS_CZ = 'cs-CZ',
  DA_DK = 'da-DK',
  DE = 'de',
  DE_AT = 'de-AT',
  DE_CH = 'de-CH',
  DE_DE = 'de-DE',
  EL = 'el',
  EL_GR = 'el-GR',
  EN = 'en',
  EN_AT = 'en-AT',
  EN_CA = 'en-CA',
  EN_CH = 'en-CH',
  EN_CZ = 'en-CZ',
  EN_DK = 'en-DK',
  EN_EE = 'en-EE',
  EN_FI = 'en-FI',
  EN_GB = 'en-GB',
  EN_IE = 'en-IE',
  EN_LT = 'en-LT',
  EN_LV = 'en-LV',
  EN_MT = 'en-MT',
  EN_NL = 'en-NL',
  EN_NO = 'en-NO',
  EN_RU = 'en-RU',
  EN_SE = 'en-SE',
  EN_SK = 'en-SK',
  EN_US = 'en-US',
  ES = 'es',
  ES_CO = 'es-CO',
  ES_ES = 'es-ES',
  ES_IC = 'es-IC',
  ES_MX = 'es-MX',
  ET_EE = 'et-EE',
  FI_FI = 'fi-FI',
  FR = 'fr',
  FR_CA = 'fr-CA',
  FR_CH = 'fr-CH',
  FR_FR = 'fr-FR',
  FR_LU = 'fr-LU',
  HI_IN = 'hi-IN',
  HR = 'hr',
  HR_HR = 'hr-HR',
  HU_HU = 'hu-HU',
  ID_ID = 'id-ID',
  IS_IS = 'is-IS',
  IT = 'it',
  IT_CH = 'it-CH',
  IT_IT = 'it-IT',
  JA_JP = 'ja-JP',
  KA_GE = 'ka-GE',
  KO_KR = 'ko-KR',
  LT_LT = 'lt-LT',
  MS_MY = 'ms-MY',
  NL = 'nl',
  NL_NL = 'nl-NL',
  NN_NO = 'nn-NO',
  PL = 'pl',
  PL_PL = 'pl-PL',
  PT = 'pt',
  PT_PT = 'pt-PT',
  RO = 'ro',
  RO_RO = 'ro-RO',
  RU = 'ru',
  RU_EE = 'ru-EE',
  RU_LT = 'ru-LT',
  RU_LV = 'ru-LV',
  RU_RU = 'ru-RU',
  SI_LK = 'si-LK',
  SK = 'sk',
  SK_SK = 'sk-SK',
  SL_SI = 'sl-SI',
  SR_CYRL_RS = 'sr-Cyrl-RS',
  SV_SE = 'sv-SE',
  TH_TH = 'th-TH',
  TR = 'tr',
  TR_TR = 'tr-TR',
  UK = 'uk',
  UK_UA = 'uk-UA',
  VI_VN = 'vi-VN',
  ZH_CN = 'zh-CN',
}
