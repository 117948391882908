import { APPLICATION_COPY_PASTE } from '../../../constants'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'

export interface CopyInterface {
  type: ActionType.COPY_ELEMENTS
  nodesToCopy: NodeTypes[]
  event: ClipboardEvent
}

export class CopyAction {
  static apply = (
    state: State,
    { nodesToCopy, event }: CopyInterface
  ): void => {
    this.copy(state, nodesToCopy, event)
  }

  static copy = (
    state: State,
    nodesToCopy: NodeTypes[],
    event: ClipboardEvent
  ) => {
    const viewportOnCopy = state.reactFlowInstance?.getViewport()
    const data = JSON.stringify({ nodesToCopy, viewportOnCopy })
    event.clipboardData?.setData(APPLICATION_COPY_PASTE, data)
    event.preventDefault()
  }
}
